import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap';
import Title from '../../components/UI/Title';
import './Product.css'
import HomeMMS from '../../components/MMS/HomeMMS'
import OpImg1 from "../../assets/images/our_product/smartcmptr_img.png"
import OpImg2 from "../../assets/images/our_product/solution_mobile_img.png"
import OpImg3 from "../../assets/images/our_product/customer_img1.png"
import OpImg4 from "../../assets/images/our_product/customer_img2.png"
import { RiDoubleQuotesL } from "react-icons/ri";
import { DiDotnet,DiVisualstudio,DiMsqlServer,DiJsBadge,DiHtml5,DiCss3,DiBootstrap } from "react-icons/di";
import { SiAdobexd,SiXamarin } from "react-icons/si";
import { CgFigma } from "react-icons/cg";
import { BsFillCalendarWeekFill,BsLaptopFill } from "react-icons/bs";

import { FaMobile,FaUsers } from "react-icons/fa";
import { Helmet } from 'react-helmet';




export default function Product() {
  return (
    <>
     <Helmet>
      <title>Our Product | Club Planner</title>
      <meta name="description" content="Simplify, automate & centralise with all-in-one software member management tool." />
    </Helmet>
    <section className='inner_page pb-5 our_pro'>
        <HomeMMS/>
        <Container>
          <Row className='my-5 gx-5 gy-3'>
            <Col md={12}><Title mainTitle="Project Peculiarity"/></Col>
            <Col md={6}>
              <p>As a member, select your club and log in. Use your smartphone to make reservations for your favourite
              activity.
              Manage and check your reservations. Lookup exercises and workouts to get busy. Adjust and personalize your
              profile and check up on the validity of your subscription.
              Use the built-in QR or Barcode to check-in to your (sports) club!</p>
            </Col>
            <Col md={6}>
              <p>Use the app as an employee or manager to create your own exercises and workouts you can link to your
              organization or to specific members.
              Check up on the current visits in your club no matter where you are. Manage your activities and planner.
              Search for specific members in your database.
              Link subscriptions to your customers and check them in using QR or Barcodes. MyClubApp removes any
              superfluous worry for members, employees, coaches, and managers in every club.
            </p>
            </Col>
          </Row>
          <Row className='my-5'>
            <Col md={6}>
              <div className="op_img px-5 sticky-top"><Image src={OpImg1} fluid alt=''/></div>
            </Col>
            <Col md={6} className="gra_bg">
              <div className="peculrty_second_col_inner">
                <div className="op_info">
                  <h6>Simplify, automate & centralise with all-in-one software member management tool.</h6>
                  <h5>Work <span>smarter</span>  not harder !</h5>
                  <p>Fitness industry is booming, and there is no two ways about it. If you own a fitness club, you need to
                    streamline your business operations by implementing the right solutions that work.
                    Moreover, data and analytics are becoming an integral part of business, maintaining data of customers and
                    keeping records is more and more important. Club planner is a perfect fit to address all your needs.</p>
                  <p>When you will have easy access to data, you can easily enhance the quality of your marketing campaigns;
                    attract new customers while retaining the existing ones.
                    Club planner enables you to connect with customers with the help of various tools including SMS, emails,
                    reservation platform and dedicated application.</p>
                </div>
              
                <div className="op_challenges mt-5">
                  <h4>The Challenges</h4>
                  <ul className="challenge_col">
                    <li className="ch_marker">Aligned feature such as tourchscreen, which runs smoothly over Hardware devices.</li>
                    <li className="ch_marker">Integration with access control over hardware and IoT.</li>
                    <li className="ch_marker">Integrated with coda-box for enabling invoices via pdf.</li>
                    <li className="ch_marker">Migration of club-planner on premises to microsoft Azure.</li>
                  </ul>
                </div>
                <div className="op_techs mt-5">
                  <h4>Tools and Technologies</h4>
                  <ul className='op_techs_list'>
                    <li><DiDotnet/><small>ASP.NET MVC</small></li>
                    <li><DiMsqlServer/><small>SQL Server</small></li>
                    <li><SiXamarin/><small>Xamarin</small></li>
                    <li><DiJsBadge/><small>JavaScript</small></li>
                    <li><DiHtml5/><small>HTML</small></li>
                    <li><DiCss3/><small>CSS</small></li>
                    <li><DiBootstrap/><small>Bootstrap</small></li>
                    <li><DiVisualstudio/><small>VS Code</small></li>
                    <li><SiAdobexd/><small>Adobe XD</small></li>
                    <li><CgFigma/><small>Figma</small></li>
                  </ul>
                </div>
                <div className="project_duration mt-5">
                  <h4>Project Duration</h4>
                  <ul className='op_dura'>
                    <li><span><b>25</b><BsFillCalendarWeekFill/><small>Weeks</small></span></li>
                    <li><span><b>20+</b><BsLaptopFill/><small>Webpages</small></span></li>
                    <li><span><b>40+</b><FaMobile/><small>App Screens</small></span></li>
                    <li><span><b>12</b><FaUsers/><small>Team Size</small></span></li>
                  </ul>
                </div>
                <div className="op_solution mt-5">
                  <h4>Project Solution</h4>
                  <p>Ultimately we've built a Mobile app solution where customers are able to see the planning and book
              reservations.
              They can also search for different types of exercises and get an overview of their finished workouts. In a
              one line,
              it is a Online platform where members can make reservations, while client and his employees can create
              exercises, workouts and manage his organization.</p>
              <p>This Platform is also a Online webshop where clients can register, buy subscriptions, multiple-access cards
                  and more.
                  Club Planner is linked to various tools to make managing client's business even easier. It support RFID,
                  magnetic barcode, fingerprint for access control,
                  combined with a home automation system from Loxone and surveillance cameras from Reolink to fully automate
                  club. It is also link with Technogym and Matrix (eGym) fitness equipment.
                </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="product_imgs">
          <img className="img-fluid" src={OpImg2} alt=""/>
        </div>
        <section className="product_customer_section">
          <Container>
            <Row className='py-5'>
              <Col md={12}><Title mainTitle="What for the Customers"/></Col>
              <Col md={6}>
                <ul>
                  <li>
                    <h5>Start</h5>
                    <p>A quick overview on your pending tasks, how many members and employees are currently online and your
                      members' activities in the last 7 days.</p>
                  </li>
                  <li>
                    <h5>Dashboard</h5>
                    <p>Easy-to-use and quick graphs to know how your club is evolving throughout a period of time. The
                      dashboard learns you more about your member retention, the financial health of your club and how many
                      members and employees are using the app.</p>
                  </li>
                  <li>
                    <h5>Calendars</h5>
                    <p>A calendar per activity type? One calendar with all activities? Different locations or courts per
                      calendar? Employee view that filters per coach? Only a limited amount of subscriptions allowed to see
                      this calendar? Colour codes per coach? Limitations in time for reservations? Easily repeat planning for
                      a year?</p>
                  </li>
                  <li>
                    <h5>Coach</h5>
                    <p>Easy-to-use and quick graphs to know how your club is evolving throughout a period of time. The
                      dashboard learns you more about your member retention, the financial health of your club and how many
                      members and employees are using the app.</p>
                  </li>
                </ul>
              </Col>
              <Col md={6}>
                <div className="opc_img  sticky-top"><Image src={OpImg3} alt="" fluid></Image></div>
              </Col>
            </Row>
            <Row className='py-5'>
              <Col md={12}><Title mainTitle="What for the Employee"/></Col>
              <Col md={6}>
                <ul>
                  <li>
                    <h5>Members</h5>
                    <p>Manage your members on both the online version, as well as via the Club-App.</p>
                  </li>
                  <li>
                    <h5>Community</h5>
                    <p>Your members can use this space to share their content, like posts and leave messages. You can use Club
                      Community to keep your members up to date on the most recent developments and news concerning your
                      business!</p>
                  </li>
                  <li>
                    <h5>Employees</h5>
                    <p>Manage your employee's accounts. You have an unlimited number of coaches and employees. Give an
                      authorisation group to each employee to make sure they can only access a limited amount of
                      information/functions in your software. You can assign tasks and even work with a badging system for
                      check-in and check-out.</p>
                  </li>
          </ul>
              </Col>
              <Col md={6}>
                <div className="opc_img  sticky-top"><Image src={OpImg4} alt="" fluid></Image></div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="client_feedback_section mt-5 pt-5">
          <Container>
            <Row>
              <Col>
              <Title mainTitle="Client Feedback"/>
              
              <div className='op_review'>
                <span><RiDoubleQuotesL/></span>
                <p>Team Alcyone are wonderful to work with. They were able to complete the job with perfection and with very
              little guidance. Communication throughout the project was excellent and the work was completed as agreed
              with good quality. I am receiving some great responses from my clients as they are actively using Web and
              Mobile both the platforms.</p>
              </div>
              </Col>
            </Row>
          </Container>
        </section>
    </section>
    </>
  )
}
