import React, { useEffect, useState  } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap';
import { FaArrowLeft } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import { stringToId } from './stringToId';
import { Helmet } from 'react-helmet';

export default function BlogDetails() {
  const baseUrl = 'https://web.alcyone.in/'
  const { slug } = useParams();
  const [data, setData] = useState([]);
  const [contentData, setContentData] = useState("");
  const [bgColor, setbgColor] = useState("#191919");
  const [textColor, settextColor] = useState("#f5f5f5");
  const [primaryColor, setprimaryColor] = useState("#f5f5f5");
  const [blogs, setBlogs] = useState([]);

  const myStyle = {
    background: bgColor,
    color: textColor,
  };

  useEffect(() => {
    try {
      fetch(`${baseUrl}api/blog/alcyone/${slug}`).then((res) => {
        res.json().then((response) => {
          setData(response.result);
          setprimaryColor(response.result.primary_color);
          if (response.result.background_color === "#f5f5f5") {
            settextColor("#191919");
            setbgColor("#f5f5f5");
          } else {
            settextColor("#f5f5f5");
            setbgColor("#191919");
          }
          setContentData(JSON.parse(response.result.content));
        })
      });
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }

    const fetchBlogs = async () => {
      try {
        fetch(`${baseUrl}api/alcyone/blogs`).then((response) => {
          response.json().then((res) => {
            setBlogs(res.result);
          })
        })
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };
    fetchBlogs();

  }, [slug])


  const currentBlogIndex =
    blogs.length > 0 ? blogs.findIndex((blog) => blog.slug === slug) : -1;

  const prevBlog = currentBlogIndex > 0 ? blogs[currentBlogIndex - 1] : null;

  const nextBlog =
    currentBlogIndex < blogs.length - 1 ? blogs[currentBlogIndex + 1] : null;

  return (
    <>
    <Helmet>
        <title>{data.meta_title}</title>
        <meta name="description" content={data.meta_description } />
        <meta name="keywords" content={data.meta_keyword } />
       
    </Helmet>
    <div>
      {data === null || data.length === 0 ? (
        <div className="emptyData">
          <div className="emptyBlogdata">
            <h5 className="loadingText">Thanks for your patience. Loading...</h5>
          </div>
        </div>
      ) : (
        <div>
          <div
            className="hero-img"
            style={{
              backgroundImage: `url('${baseUrl}/${data.banner_image}')`,
            }}
          ></div>
          <Container fluid style={myStyle}>
            <Row>
              {/* <Col md={4} className='pt-5'>
                  <ul className="sticky-top pt-5">
                  {Object.keys(contentData).map((blogKey) => (
                    <li key={blogKey} className="sec_gap">
                      {contentData[blogKey].map((item, index) => (
                        <div  key={index} className=' mb-3'>
                          {item.column.includes("heading2") || item.column.includes("heading3") || item.column.includes("heading4") || item.column.includes("heading") ? (
                            <a className="text-md" style={{ color: primaryColor }} href={`/#${stringToId(item.data)}`}> {item.data} </a>
                          ) : null}
                        </div>
                      ))}
                    </li>
                  ))}
                  </ul>
              </Col> */}
              <Col md={8} className='mx-auto'>
                <div className="section-wrapper">
                <div className="backButton">
                  <Button as={Link} to="/blogs" style={myStyle}>
                    <FaArrowLeft />
                  </Button>
                </div>

                <div className="emoji">
                  <img src={`${baseUrl}/${data.blog_icon}`} alt="" />
                </div>
                <div className="blog-title">
                  <h1>{data.title}</h1>
                  <p>{data.subtitle}</p>
                </div>

                <div className="cmn_sec">
                  {Object.keys(contentData).map((blogKey) => (
                    <div key={blogKey} className="sec_gap">
                      {contentData[blogKey].map((item, index) => (
                        <div key={index}>
                          {item.column.includes("heading2") ? (
                            <h2 style={{ color: primaryColor }} id={stringToId(item.data)}>{item.data}</h2>
                          ) : item.column.includes("heading3") ? (
                            <h3 style={{ color: primaryColor }} id={stringToId(item.data)}>{item.data}</h3>
                          ) : item.column.includes("heading4") ? (
                            <h4 style={{ color: primaryColor }} id={stringToId(item.data)}>{item.data}</h4>
                          ) : item.column.includes("heading") ? (
                            <h3
                              className="text-md"
                              style={{ color: primaryColor }} id={stringToId(item.data)}
                            >
                              {item.data ?? ""}
                            </h3>
                          ) : item.column.includes("description") ? (
                            <div
                              dangerouslySetInnerHTML={{ __html: item.data }}
                            />
                          ) : item.column.includes("image") &&
                            !item.column.includes("image_caption") ? (
                            <img
                              src={`${baseUrl}/${item.data}`}
                              alt={item.column}
                            />
                          ) : item.column.includes("image_caption") ? (
                            <p style={{ color: primaryColor }}>{item.data}</p>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>

                <div className="footer-blog">
                  <p>
                    <strong>Posted Date : </strong>
                    {data.date}
                  </p>
                </div>

                <div className="prev-next-buttons mb-5">
                  <Row className="">
                    <Col md={6} className="mt-3 prevBlog">
                      {prevBlog && (
                        <Link
                          to={`/blog/${prevBlog.slug}`}
                          className=""
                        >
                          <img
                            src={`${baseUrl}/${prevBlog.banner_image}`}
                            alt=""
                          />
                          <strong >
                            {prevBlog.meta_title}
                          </strong>
                        </Link>
                      )}
                    </Col>

                    <Col md={6} className="mt-3 nextBlog">
                      {nextBlog && (
                        <Link
                          to={`/blog/${nextBlog.slug}`}
                          className=""
                        >
                          <img
                            src={`${baseUrl}/${nextBlog.banner_image}`}
                            alt=""
                          />
                          <strong>
                          {nextBlog.meta_title} 
                          </strong>
                        </Link>
                      )}
                    </Col>
                  </Row>
                </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </div>
    </>
  )
}
