import { useFormik } from 'formik';
import React from 'react'
import { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';

import PageTopHead from '../../components/UI/PageTopHead';
import './Contact.css'
// import Title from '../../components/UI/Title';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
// import { contactForm } from '../../services/Api-service';

export default function Contact() {
    const [discuss, setDiscuss] = useState('');
    const [budget, setBudget] = useState('');
    const [isSuccessfullySubmitted, setIsSuccessfullySubmitted ] = React.useState(false);

    // toast.configure();

    const formik = useFormik({
        initialValues: {
          name: '',
          visitorCompany: '',
          idea:'',
          phone:'',
          email:''
        },
        onSubmit:async(values, {resetForm}) => {
        console.log('values', values);
        console.log(discuss);
        console.log(budget);
        let formData = new FormData();
        formData.append('name', values.name)
        formData.append('email', values.email)
        formData.append('description', values.idea)
        formData.append('mobile', values.phone)
        formData.append('discuss', discuss)
        formData.append('budget', budget)
        formData.append('visitorCompany', values.visitorCompany)
        formData.append('type', 'contact')
        axios.post('https://web.alcyone.in/api/contact', formData).then((res)=>{
            console.log(res);
            if(res.data.status === true){
                // toast.success(res.data.message, { autoClose: 2000 });
                setIsSuccessfullySubmitted(true);
                // window.location.reload();
                setTimeout(()=>{
                    setIsSuccessfullySubmitted(false);
                },3000)
                resetForm();
            }else{
                toast.error(res.data.message, { autoClose: 2000 });
            }
        });
        },
      });


    return (
        <>
        <Helmet>
            <title>Contact Us</title>
            <meta name="description" content="Let's Start Something Big Together" />
        </Helmet>
        <section className='inner_page pb-5'>
            <PageTopHead pageTitle="Contact Us" description="Let's Start Something Big Together" />
            <Container>
                <Row className=' my-5 mx-auto'>
                    <Col md={12}>
                        <form onSubmit={formik.handleSubmit}>
                        <div className="form_box">
                            <h2 className='mb-4'>Hello, Team Alcyone!</h2>
                            <div className="cf_fr mb-4">
                                <div className="cf_txt">My name is</div>
                                <input type="text" id="visitorName" className={`form-control`} placeholder="YOUR NAME" 
                                 name="name"
                                 onChange={formik.handleChange}
                                 value={formik.values.name}
                                 required
                                />
                                <div className="cf_txt">from</div>
                                <input type="text" id="visitorCompany" className="form-control" placeholder="WEBSITE OR COMPANY NAME" 
                                  name="visitorCompany"
                                  onChange={formik.handleChange}
                                  value={formik.values.visitorCompany}
                                />
                            </div>
                            <div className="cf_fr mb-4">
                                <div className="cf_txt">I'd love to discuss</div>
                                <div className="project_fields">
                                    <input type="radio" className="btn-check" id="webappl" 
                                      name="discuss"
                                      onChange={(e)=>setDiscuss(e.target.value)}
                                      value="Web Application"
                                    />
                                    <label htmlFor='webappl' className="btn">Web Application</label>
                                    <input type="radio" className="btn-check" id="mblapp"
                                        name="discuss"
                                        onChange={(e)=>setDiscuss(e.target.value)}
                                        value="Mobile App"
                                    />
                                    <label className="btn" htmlFor="mblapp">Mobile App</label>

                                    <input type="radio" className="btn-check" id="sftdev"
                                     name="discuss"
                                     onChange={(e)=>setDiscuss(e.target.value)}
                                     value="Software Development"
                                    />
                                    <label className="btn" htmlFor="sftdev">Software Development</label>

                                    <input type="radio" className="btn-check" id="uidesign" 
                                     name="discuss"
                                     onChange={(e)=>setDiscuss(e.target.value)}
                                     value="UI Design"
                                    />
                                    <label className="btn" htmlFor='uidesign'>UI Design</label>

                                    <input type="radio" className="btn-check" id="hr" 
                                    name="discuss"
                                    onChange={(e)=>setDiscuss(e.target.value)}
                                    value="Hire Resource"
                                    />
                                    <label className="btn" htmlFor="hr">Hire Resource</label>
                                    <input type="radio" className="btn-check" id="clservice" 
                                     name="discuss"
                                     onChange={(e)=>setDiscuss(e.target.value)}
                                     value="Cloud Services"
                                    />
                                    <label className="btn" htmlFor="clservice">Cloud Services</label>
                                </div>
                                <div className="cf_txt">project & design ideas.</div>
                            </div>
                            <div className="cf_fr mb-4">
                                <textarea className="form-control" placeholder="LET US KNOW MORE ABOUT YOUR PROJECT IDEA (OPTIONAL)"
                                 name="idea"
                                 onChange={formik.handleChange}
                                 value={formik.values.idea}
                                //  required
                                />
                            </div>

                            <div className="cf_fr mb-4">
                                <div className="cf_txt">This project has a budget of</div>
                                <div className="project_fields cf_budget">
                                    <input type="radio" className="btn-check" id="budget" 
                                      name="budget"
                                      onChange={(e)=>setBudget(e.target.value)}
                                      value="$10k"
                                    />
                                    <label className="btn" htmlFor="budget">$10k</label>
                                    <input type="radio" className="btn-check" id="10k-50k" 
                                      name="budget"
                                      onChange={(e)=>setBudget(e.target.value)}
                                      value="$10k - 50k"
                                    />
                                    <label className="btn" htmlFor="10k-50k">$10k - 50k</label>
                                    <input type="radio" className="btn-check" id="$50k - 100k" 
                                     name="budget"
                                     onChange={(e)=>setBudget(e.target.value)}
                                     value="$50k - 100k"
                                    />
                                    <label className="btn" htmlFor="$50k - 100k">$50k - 100k</label>
                                    <input type="radio" className="btn-check" id="$100k+" 
                                    name="budget"
                                    onChange={(e)=>setBudget(e.target.value)}
                                    value="$100k+"
                                    />
                                    <label className="btn" htmlFor="$100k+">$100k+</label>
                                </div>
                            </div>
                            <div className="cf_fr mb-4 cf_email_r">
                                <div className="cf_txt">Please connect me at</div>
                                <input type="text" id="phone" className="form-control cf_phone" placeholder="YOUR PHONE" 
                                    name="phone"
                                    onChange={formik.handleChange}
                                    value={formik.values.phone}
                                />
                                <div className="cf_txt">&</div>
                                <input type="email" id="email" className={`form-control`} placeholder="YOUR EMAIL"
                                  name="email"
                                  onChange={formik.handleChange}
                                  value={formik.values.email}
                                  required
                                />
                                {/* {formik.errors.email ? <div className='text-danger'>{formik.errors.email}</div> : null} */}
                                <div className="cf_txt">.</div>
                            </div>
                            <button type="submit" className="btn btn-primary">Submit</button>

                        {isSuccessfullySubmitted  && (
                                        <div className="text-white rounded mt-4 bg-success p-3">Superb! Alcyone team will connect with you soon. <b>A great discussion is on the horizon!</b></div>
                                    )}
                        </div>
                    </form>
                    </Col>
                </Row>
            </Container>
          <ToastContainer/>
        </section>
        </>
    )
}
