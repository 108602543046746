import React from 'react'
import {  Image, Container, Row, Col, Nav} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/alcyone_logo.svg'
import { FaFacebookF, FaLinkedinIn, FaInstagram  } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import './Footer.css'
import Address from './Address';
import India from '../../assets/images/icons/india_flag.svg'
import Belgium from '../../assets/images/icons/belgium_flag.svg'
import USA from '../../assets/images/icons/usa_flag.svg'

export default function Footer() {
  return (
    <footer className=''>
        

        <div className="main_footer py-5">
        {/* <Container className='sales_card py-sm-4 py-5 px-sm-5 px-4'>
            <Row>
                <Col md={7}>
                    <div className="sales_text h-100 d-flex align-items-start flex-column justify-content-center">
                        <h2>Let's Start Something Big Together</h2>
                        <h6>Help yourself to connect with our expertise</h6>
                    </div>
                </Col>
                <Col md={5}>
                    <div className="sales_mems">
                        <div className="sales_person">
                            <div className="sp_img"><Image src={Pramod} alt=''/></div>
                            <div className="sp_info">
                                <strong>Pramod Sharma</strong>
                                <a href="mailto:pramod.sharma@alcyone.in">pramod.sharma@alcyone.in</a>
                                <a href="tel:+91-731-4970290">91-731-4970290</a>
                            </div>
                        </div>                        
                        <div className="sales_person">
                            <div className="sp_img"><Image src={Tushar} alt=''/></div>
                            <div className="sp_info">
                                <strong>Tushar Patidar</strong>
                                <a href="mailto:tushar.patidar@alcyone.in">tushar.patidar@alcyone.in</a>
                                <a href="tel:+91-731-4970290">91-731-4970290</a>
                            </div>
                        </div>
                    </div>
                </Col> 
            </Row> 
        </Container> */}
        <Container className='pt-5 '>
            <Row>
                <Col lg={3} sm={6}>
                    <div className="foot_info">
                    <Image src={Logo} alt="Alcyone"/>
                    <span className='d-block my-3'>IT Consulting and Software Services Company</span>
                    </div>
                    <div className="social_links mb-sm-0 mb-3">
                        <a href="https://www.facebook.com/people/Alcyone-Technologies-Pvt-Ltd/61552001471256/" target="_blank" rel="noopener noreferrer"><FaFacebookF/></a>
                        <a href="https://www.linkedin.com/company/alcyone-technologies-india/" target="_blank" rel="noopener noreferrer"><FaLinkedinIn/></a>
                        <a href="https://www.instagram.com/tech_alcyone/" target="_blank" rel="noopener noreferrer"><FaInstagram/></a>
                        <a href="https://x.com/alcyone_tech" target="_blank" rel="noopener noreferrer"><FaXTwitter/></a>
                    </div>
                </Col>
                <Col lg={2} sm={6}>
                    <strong>Company</strong>
                    <Nav className="flex-column">
                        <Nav.Link as={Link}  eventkey="1" to="about">About us</Nav.Link>
                        <Nav.Link as={Link}  eventkey="2" to="ourproduct">Our Product</Nav.Link>
                        <Nav.Link as={Link}  eventkey="2" to="work">Our Work</Nav.Link>
                        <Nav.Link as={Link}  eventkey="4" to="career">Career <small>we're hiring!</small></Nav.Link>
                    </Nav>
                </Col>
                <Col lg={2} sm={6}>
                    <strong>Lead Services</strong>
                    <Nav className="flex-column">
                        <Nav.Link eventkey="7" as={Link} to="/AI-Services">AI Marvels</Nav.Link>
                        <Nav.Link eventkey="8" as={Link} to="/IoT-Services">IoT Connectivity</Nav.Link>
                        <Nav.Link eventkey="9" as={Link} to="/Cloud-Services">Cloud Solutions</Nav.Link>
                        <Nav.Link eventkey="10" as={Link} to="/Embedded">Hardware Solutions</Nav.Link>
                        <Nav.Link eventkey="111" as={Link} to="/Machine-Learning">Machine Learning</Nav.Link>
                        <Nav.Link  as={Link}  eventkey="26" to="services/BigData">Big Data Analytics</Nav.Link>
                    </Nav>
                </Col>
                <Col lg={5} sm={6}>
                    <strong>Services</strong>
                    <Row>
                        <Col md={6} sm={6}>
                            <Nav className="flex-column">
                                <Nav.Link  as={Link}  eventkey="25" to="services/PDM">Product Development and Maintenance</Nav.Link>
                                <Nav.Link  as={Link}  eventkey="27" to="services/Enterprises">Enterprises Solutions</Nav.Link>
                                <Nav.Link  as={Link}  eventkey="32" to="services/DevelopmentCenter">Dedicated Development Center</Nav.Link>
                            </Nav>
                        </Col>
                        <Col md={6} sm={6}>
                            <Nav className="flex-column">
                                <Nav.Link  as={Link}  eventkey="29" to="services/UIUX">UI/UX Design</Nav.Link>
                                <Nav.Link  as={Link}  eventkey="30" to="services/QA">Testing and QA</Nav.Link>
                                <Nav.Link  as={Link}  eventkey="28" to="services/MobileApp">Mobile App Development</Nav.Link>
                            </Nav>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className='mt-sm-4'>
                <Col sm={6} lg={3}>
                    <Address flag={India} 
                    city="Indore" 
                    country="India" 
                    location="812-813 Shekhar Central, Palasia Square, Indore, MP 452001" 
                    phone="+91-731-4970290" 
                    email="info@alcyone.in"
                    />
                </Col>
                <Col sm={6} lg={3}>
                    <Address flag={India} 
                    city="Pune" 
                    country="India" 
                    location="91 Springboard, Shastrinagar, Yerawada, Pune, Maharashtra 411006" 
                    phone="+917314970290" 
                    email="info@alcyone.in"
                    />
                </Col>
                <Col sm={6} lg={3}>
                    <Address flag={Belgium} 
                    city="" 
                    country="Belgium" 
                    location="Brugsesteenweg 176 - B-9990 Maldegem" 
                    phone="+32 50 70 55 56" 
                    email="be@alcyone.in"
                    />
                </Col>
                <Col sm={6} lg={3}>
                    <Address flag={USA} 
                    city="" 
                    country="USA" 
                    location="5 Bobcat Lane Littleton, CO 80127" 
                    phone="+1 (877) 211-2010" 
                    email="usa@alcyone.in"
                    />
                </Col>
            </Row>
        </Container>
        </div>
        
        <Container>
            <Row>
                <Col className='text-center py-4'>© Copyright 2022-24 Alcyone Technologies Pvt Ltd, All right reserved.</Col>
            </Row>
        </Container>
    </footer>
  )
}
