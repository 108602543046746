import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap'
import Title from '../../components/UI/Title'
import Technologies from '../../components/Techs/Technologies'
import './Services.css'
import ActionBox from '../../components/UI/ActionBox';
import redesignIcon from '../../assets/images/icons/redesign.svg'
import ideaIcon from '../../assets/images/icons/idea.svg'
import PdmGraphics from '../../components/UI/Graphics/PdmGraphics';
import BigGraphics from '../../components/UI/Graphics/BigDataGraphics';
import PageTopHead from '../../components/UI/PageTopHead';
import EnterGraphics from '../../components/UI/Graphics/EnterGraphics';
import MobileGraphics from '../../components/UI/Graphics/MobileGraphics';
import UiuxGraphics from '../../components/UI/Graphics/UiuxGraphics';
import QaGraphics from '../../components/UI/Graphics/QaGraphics';
import EmbeddedGraphics from '../../components/UI/Graphics/EmbeddedGraphics';
import DevelopmentCenterGraphics from '../../components/UI/Graphics/DevelopmentCenterGraphics';
import ResourceGraphics from '../../components/UI/Graphics/ResourceGraphics';
import MetaTag from './MetaTag';


export default function Services() {
  const [data, setData] = useState([]);

  const params = useParams();
 
  useEffect(() => {
    fetch("../../data/services.json").then((res) => {
      res.json().then((resp) => {
        setData(resp)
      })
    });
  },[])


  return (
    <>
    
    <section className='inner_page pb-5'>
          { data &&
          data
          .filter(ser => ser.id === params.id )
          .map(ser => 
          
          <div key={ser.id}>
            {ser.id==='PDM' ? <MetaTag title="Leading Web App Development Services Company| India & USA " metaTag="Looking for top-notch web development services? Alcyone is a trusted web development company in India and USA, offering cutting-edge solutions tailored to your business needs. Contact us today!"/> : ''}             
            {ser.id==='BigData' ? <MetaTag title="Big Data Analytics Services Company| Hire Big Data Engineer" metaTag="Power up your business with our Big Data analytics services. Hire a brilliant Big Data engineer & unlock data-driven success. Let's make your data work for you!"/> : ''}                   
            {ser.id==='Enterprises' ? <MetaTag title="Enterprise Solutions Services Company | Enterprise Software" metaTag="Supercharge your business with our Enterprise Solutions Services and advanced enterprise software. Boost efficiency, drive growth, and succeed. Contact us now!"/> : ''}                   
            {ser.id==='MobileApp' ? <MetaTag title="Top Mobile App Development Services company in India & USA " metaTag="Revolutionize your business with our top mobile app development services in India & USA. Let's create an exceptional app experience together! Get started now."/> : ''}                   
            {ser.id==='UIUX' ? <MetaTag title="Best UI/UX Design Company| Website Design Services| India & USA" metaTag="Looking for the best UI/UX design company? Our website design services in India & USA create exceptional digital experiences. Contact us today!"/> : ''}                   
            {ser.id==='QA' ? <MetaTag title="QA & Software Testing Services Company | India & USA" metaTag="Looking for top-notch software testing services? Our QA experts in India & the USA ensure thorough testing to deliver high-quality software solutions. Contact us today!"/> : ''}                 
            {ser.id==='DevelopmentCenter' ? <MetaTag title="Hire Offshore Developers | Dedicated Development Team & Center" metaTag="Looking to hire offshore developers for your projects? Our Dedicated Development Team & Center delivers top-quality solutions. Level up your business now! "/> : ''}                   
            {ser.id==='Resource' ? <MetaTag title="IT Resource & Staff Augmentation Services Company| India & USA" metaTag="Enhance your business with our IT resource and staff augmentation services. We specialize in India and USA markets, providing expert solutions for your success. "/> : ''} 
            <PageTopHead pageTitle={ser.info.name} description={ser.info.description}/>
            <div className=" mb-5">
              <Container>
                <Row>
                  <Col>
                  <div className="ser_graphics">
                    {ser.id==='PDM' ? <PdmGraphics/> : ''}                   
                    {ser.id==='BigData' ? <BigGraphics/> : ''}                   
                    {ser.id==='Enterprises' ? <EnterGraphics/> : ''}                   
                    {ser.id==='MobileApp' ? <MobileGraphics/> : ''}                   
                    {ser.id==='UIUX' ? <UiuxGraphics /> : ''}                   
                    {ser.id==='QA' ? <QaGraphics /> : ''}                   
                    {ser.id==='Embedded' ? <EmbeddedGraphics /> : ''}                   
                    {ser.id==='DevelopmentCenter' ? <DevelopmentCenterGraphics /> : ''}                   
                    {ser.id==='Resource' ? <ResourceGraphics /> : ''}                   
                  </div>
                  </Col>
                </Row>
                <Title mainTitle={ser.process.title} subTitle={ser.process.subtitle}/>
                <Row className="ap_three_boxes mt-5 ">
                  {
                    ser.process.processinfo.map((pInfo,i) =>(
                        <Col md={4} key={i}><div className="ap_box mb-3"><p>{pInfo.pi}</p></div></Col>
                    ))
                  }
                </Row>
              </Container>
            </div>
            <Container className='expertise_in'>
              <Row>
                <Col>
                  <Tabs
                    id="expertise"
                    className="mb-3"
                  >
                    {ser.expertise.map((exp,i)=>(
                      <Tab eventKey={exp.expName} title={exp.expName} key={i}>
                          <Row className='py-4'>
                            <Col lg={6} className="mb-3"><p className='px-4 mb-0'>{exp.expInfo.ei1}</p>
                            </Col>
                            <Col lg={6} className="mb-3"><p className='px-4 mb-0'>{exp.expInfo.ei2}</p>
                            </Col>
                          </Row>
                      </Tab>
                    ))}
                </Tabs>
                </Col>
              </Row>
            </Container>
          </div>
          
          
          ) 
        }
        <Container className='pt-5'>
          <Row>
              <Col lg={12}>
                <Title mainTitle="Technologies" subTitle="Our experts offers integrated services and our awesome product help you harness the power of technology. streamline and automate IT. enhance customer experiences, and drive exponential business growth."/>
              </Col>
          </Row>
          <Row>
            <Col>
              <Technologies/>
            </Col>
          </Row>
          <Row className='pt-5 g-3'>
          <Col>
            <ActionBox title="Reorganizing" subTitle="Existing website or app" btnTxt="Consult Now" icon={redesignIcon} redirect="/rebrand"/>
          </Col>
          <Col>
            <ActionBox title="New !dea" subTitle="Let's connect and discuss" btnTxt="Schedule a Call" icon={ideaIcon} redirect="/contact"/>
          </Col>
        </Row>
        </Container>
    </section>
    
    </>
  )
}
