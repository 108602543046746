import React, { useState ,useEffect} from 'react'
import { Container, Row, Col, Button} from 'react-bootstrap'
import PageTopHead from '../../components/UI/PageTopHead';
import { Image} from 'react-bootstrap'
import './Work.css'
import { Helmet } from 'react-helmet';

export default function Work() {
    const [workData , setWorkData] = useState([]);

    useEffect(()=>{
        fetch("https://web.alcyone.in/api/projects").then((res)=>{
        res.json().then((resp)=>{
            setWorkData(resp.result)
        })
    });
    },[])


    

  return (
    <>
    <Helmet>
      <title>Our Work</title>
      <meta name="description" content="The key to our success is doing perfect work for our clients." />
    </Helmet>
    <section className='inner_page pb-5'>
        <PageTopHead pageTitle="Our Work" description="The key to our success is doing perfect work for our clients."/>
        <Container>
            {workData.map((work) => (
                    <div className='work_card mt-5' key={work.id}>
                        <Row>
                                <Col md={6}>
                                    <div className="ow_info">
                                        <div>
                                            <div className='watermakk'>{work.project_type}</div>
                                            <div><small>{work.project_type}</small></div>
                                            <h2>{work.name}</h2>
                                            <ul className="ow_tech">
                                                {work.tags.split(',').map((tag, i) => {
                                                    return <li key={i}>{tag}</li>;
                                                })}
                                            </ul>
                                        </div>
                                        <Button onClick={()=> window.open(work.url, "_blank")}>Explore it!</Button>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="ow_img"><Image fluid src={`https://web.alcyone.in/${work.image}`} alt={work.name}/></div>
                                </Col>
                        
                        
                        
                        </Row>
                    </div>
                ))
            }
            
        </Container>
        
    </section>
    </>
  )
}
