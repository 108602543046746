import React from 'react'
import { Helmet } from 'react-helmet'
import { Col, Container, Image, Row } from 'react-bootstrap'
import PageTopHead from '../../components/UI/PageTopHead'
import FlipCard from '../../components/FlipCard/FlipCard'
import './ServiceLayout.css'
import OwlCarousel from 'react-owl-carousel'; 
import '../../../node_modules/owl.carousel/dist/assets/owl.carousel.css';
import '../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css';
import MlImg1 from '../../assets/images/services/ML/alcyone_ML_1.jpg'
import MlImg2 from '../../assets/images/services/ML/alcyone_ML_2.jpg'
import MlImg3 from '../../assets/images/services/ML/alcyone_ML_3.jpg'
import MlImg4 from '../../assets/images/services/ML/alcyone_ML_4.jpg'
import MlImg5 from '../../assets/images/services/ML/alcyone_ML_5.jpg'
import MlImg6 from '../../assets/images/services/ML/alcyone_ML_6.jpg'

import MlServices from "../../data/ml.json";
import FAQ from '../../components/faq/FAQ'
import faqs from "../../data/faqs.json";
import { Link } from 'react-router-dom'

export default function Machine() {
    
    const options = {
        
        responsive: {
            0: {
                items: 1,
                // stagePadding: 50,
            },
            450: {
                items: 1,
                stagePadding: 50,
            },
            600: {
                items: 2,
                stagePadding: 50,
            },
            1000: {
                items: 4,
            },
            1600: {
                items: 5,
    
            }
        },
    };
    const dataFaq = faqs.ml_faq;
  return (
    <>
    <Helmet>
    <title>Machine Learning Services| AWS, Azure, Google Cloud| India & USA</title>
    <meta name="description" content="Supercharge your business with our Machine Learning Services on AWS, Azure, and Google Cloud. Harness the power of AI from India to the USA. Contact us today! " />
  </Helmet>
    <div className='inner_page pb-5'>
        <PageTopHead pageTitle="Machine Learning Services" description="Utilize data-driven services to address complex business challenges through the power of machine learning."/>
        <Container>
            <Row>
                <Col>
                <div className="big_title my-5 py-5 scroll-reveal">
                    <p><span>Welcome to Alcyone, your trusted partner for exceptional Machine Learning Services and Solutions. We are committed to helping businesses all around the world harness the power of artificial intelligence and unlock the true potential of their data. <b>With expertise in AWS, Azure, and Google Cloud platforms, we offer comprehensive and scalable machine learning services tailored to meet your unique business needs.</b> </span></p>
                </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2 className='new_title text-center'>Business Benefits from Our Machine Learning Solutions </h2>
                </Col>
            </Row>
            <Row xs={1} md={2} lg={3} className='ser_cards_grid mt-4'>
                <Col>
                    <FlipCard title="Improved Decision-Making" desc="Make more informed decisions based on data-driven predictions and insights. " imageurl={MlImg1} />
                </Col>
                <Col>
                    <FlipCard title="Enhanced Efficiency and Automation" desc="Streamline processes, increase productivity, and reduce errors through automation. " imageurl={MlImg2} />
                </Col>
                <Col>
                    <FlipCard title="Personalized Customer Experiences" desc="Deliver tailored recommendations, personalized marketing, and targeted offers to enhance customer satisfaction and loyalty. " imageurl={MlImg3} />
                </Col>
                {/* <Col>
                    <FlipCard title="Fraud Detection and Risk Management" desc="Identify suspicious activities, prevent fraud, and mitigate risks to protect your business and customers. " imageurl={AIImg2} />
                </Col> */}
                <Col>
                    <FlipCard title="Predictive Maintenance and Asset Optimization" desc="Proactively predict equipment failures, optimize maintenance schedules, and improve operational efficiency." imageurl={MlImg4} />
                </Col>
                <Col>
                    <FlipCard title="Competitive Advantage" desc="Gain a competitive edge by leveraging advanced analytics to uncover insights, discover opportunities, and differentiate your business. " imageurl={MlImg5} />
                </Col>
                <Col>
                    <FlipCard title="Scalability and Flexibility" desc="Scale machine learning solutions to handle varying data volumes and complexities, ensuring they adapt to your business needs. " imageurl={MlImg6} />
                </Col>
                {/* <Col>
                    <FlipCard title="Continuous Improvement and Optimization" desc="Continuously refine and optimize machine learning models to improve accuracy, performance, and long-term results. " imageurl={AIImg2} />
                </Col> */}
            </Row>
            <Row>
                <Col md={12}>
                    <div className="big_title mt-5 pt-5 mb-5">
                        <h2 className='new_title text-center'>Our Comprehensive Machine Learning Services</h2>
                        <p>We are dedicated to delivering solutions that unlock the power of machine learning and drive transformative results for your business.</p>
                    </div>
                </Col>
            </Row>
            <Row xs={1} md={2} lg={2}>
                <Col>
                    <div className="white_card">
                        <h3>Data Preparation and Exploration</h3>
                        <p>We help you collect, clean, and prepare your data for machine learning tasks. Our experts apply advanced techniques to explore and understand your data, ensuring its suitability for developing accurate machine-learning models. </p>
                    </div>
                </Col>
                <Col>
                    <div className="white_card">
                        <h3>Machine Learning Model Development</h3>
                        <p>Using state-of-the-art algorithms and techniques, we develop high-performing machine learning models tailored to your specific needs. Our models are trained on large datasets, enabling them to detect patterns, make predictions, and provide actionable insights. </p>
                    </div>
                </Col>
                <Col>
                    <div className="white_card">
                        <h3>Model Evaluation and Optimization</h3>
                        <p>We rigorously evaluate the performance of your machine-learning models and optimize them for maximum accuracy and efficiency. Through iterative testing and refinement, we ensure that your models deliver reliable and actionable results.</p>
                    </div>
                </Col>
                <Col>
                    <div className="white_card">
                        <h3>Integration and Deployment</h3>
                        <p>Our team seamlessly integrates your machine-learning models into your existing systems or applications. We ensure smooth deployment and provide ongoing support to ensure that your models are functioning optimally in a real-world production environment.</p>
                    </div>
                </Col>
                <Col>
                    <div className="white_card">
                        <h3>Continuous Monitoring and Maintenance</h3>
                        <p>We offer continuous monitoring and maintenance services to ensure the long-term effectiveness of your machine-learning solution. Our experts keep a close eye on the performance of your models and make necessary adjustments to maintain accuracy and efficiency. </p>
                    </div>
                </Col>
                <Col>
                    <div className="white_card">
                        <h3>Automated Scaling and Resource Management</h3>
                        <p>We implement automated processes to dynamically scale your machine learning infrastructure based on demand. Our solutions efficiently manage resources to optimize performance and minimize costs, ensuring seamless operation even during peak usage periods. </p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div className="big_title mt-5 pt-5 mb-5">
                        <h2 className='new_title text-center'>Alcyone’s Approach to Machine Learning </h2>
                        <p>Our approach ensures tailored machine learning projects that deliver value and foster ongoing collaboration. </p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <OwlCarousel id='serCaro' className='owl-theme es_item_list industries_carousel' margin={8} nav dots={false}  {...options}>
                        {MlServices.toolbox.map((item, key) => (
                            <div className="item" key={key}>
                                <div className="fea_ser_home_card">
                                    <Image src={`${item.url}`} alt={item.title} />
                                    <div className='fea_ser_home_card_details'>
                                        <div className="fsh_title">
                                            <h3>{item.title}</h3>
                                        </div>
                                        <p>{item.description}</p>
                                    </div>
                                    
                                </div>
                            </div>
                        ))}
                        
                    </OwlCarousel>
                </Col>
            </Row>
            </Container>
            <div className="percentage_sec bg_img01 py-5 mt-5">
            <Container>
                <Row  className='my-5 py-5'>
                    <Col xs={12} md={6} lg={5}>
                    
                    <div className="text-white info_block">
                        <h2>Empower Your Business with Advanced Data Solutions Through Machine Learning.</h2>
                        <Link className='btn btn-primary' to="/contact">Connect with ML Experts!</Link>
                    </div>
                    </Col>
                    <Col xs={12} md={6} lg={7}>
                    
                    <div className="text-white">
                        <ul>
                            <li><b>35</b><small>Improvement in Predictive Accuracy.</small></li>
                            <li><b>40</b><small>Reduction in Data Storage Costs.</small></li>
                            <li><b>55</b><small>Automate tasks and optimize resource allocation.</small></li>
                            <li><b>70</b><small>Enhanced Customer Experience.</small></li>
                            <li><b>95</b><small>Ensure advanced security and flexible solutions.</small></li>
                        </ul>
                    </div>
                    </Col>
                </Row>
            </Container>
        </div>
            <Container>
            <Row xs={1} md={2} lg={2} className='my-5 py-5 big_text_sticky'>
                <Col>
                
                <div className="big_title scroll-reveal sticky-top pt-5">
                    <h2 className='new_title mt-5 mx-auto'><span>Unlock the Power of <q>Machine Learning</q> with Alcyone</span> </h2>
                </div>
                </Col>
                <Col>
                
                <div className="big_title scroll-reveal">
                    <p><span>Experience the transformative power of machine learning and gain a competitive edge in today's data-driven world. Trust Alcyone to deliver innovative and impactful machine learning services that drive growth, optimize processes, and enhance decision-making for your business.</span></p>
                    <p><span>Contact us today to discuss your specific requirements and take the first step toward unlocking the full potential of your data.</span> </p>
                </div>
                </Col>
            </Row>
        </Container>
        <FAQ faqData={dataFaq}/>
    </div>
    </>
  )
}
