import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import PageTopHead from '../../components/UI/PageTopHead'
import FlipCard from '../../components/FlipCard/FlipCard'
import './ServiceLayout.css'
import OwlCarousel from 'react-owl-carousel'; 
import '../../../node_modules/owl.carousel/dist/assets/owl.carousel.css';
import '../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css';
import AIImg1 from '../../assets/images/services/ai/alcyone_ai_1.jpg'
import AIImg2 from '../../assets/images/services/ai/alcyone_ai_2.jpg'
import AIImg3 from '../../assets/images/services/ai/alcyone_ai_3.jpg'
import ToolboxServices from "../../data/ai_industries.json";
import FAQ from '../../components/faq/FAQ'
import faqs from "../../data/faqs.json";
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

export default function AiMarvels() {
    const options = {
        
        responsive: {
            0: {
                items: 1,
                // stagePadding: 50,
            },
            450: {
                items: 1,
                stagePadding: 50,
            },
            600: {
                items: 2,
                stagePadding: 50,
            },
            1000: {
                items: 4,
            },
            1600: {
                items: 5,
    
            }
        },
    };
    const dataFaq = faqs.ai_faq;
  return (
    <>
    <Helmet>
    <title>Artificial Intelligence (AI) Services & Solutions Company</title>
    <meta name="description" content="Ready to harness the boundless potential of AI for your business? Explore our tailored services and solutions and transform your future. Let's talk Today! " />
  </Helmet>
    <div className='inner_page pb-5'>
        <PageTopHead pageTitle="AI Services & Solutions" description="Elevate your business operations and stay ahead of the curve by implementing cutting-edge AI solutions."/>
        <Container>
            <Row>
                <Col>
                <div className="big_title my-5 py-5 scroll-reveal">
                    <p><span>Welcome to our AI Services & Solutions Company, where the future of business begins. In a world driven by data and automation, staying competitive means embracing <b>Artificial Intelligence (AI)</b>. We are your dedicated partner in harnessing the power of AI to transform your business, streamline operations, and drive innovation. Join us on a journey to discover how our AI services can propel your business to new heights.</span></p>
                </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2 className='new_title text-center'>The Power of AI Services</h2>
                </Col>
            </Row>
            <Row xs={1} md={2} lg={3} className='ser_cards_grid mt-4'>
                <Col>
                    <FlipCard title="AI Revolution" desc="AI is more than a buzzword; it's a game-changer. As an AI Services & Solutions Company, we see AI as a strategic asset, not just a tool. Our mission is to provide tailored AI services that align with your goals." imageurl={AIImg1} />
                </Col>
                <Col>
                    <FlipCard title="Custom Solutions" desc="Every business is unique, and we understand that. Our AI experts craft personalized solutions to enhance customer engagement, optimize operations, and enable data-driven decisions." imageurl={AIImg2} />
                </Col>
                <Col>
                    <FlipCard title="Leading Innovation" desc="We stay ahead in the ever-evolving AI field. From machine learning to deep learning, we leverage cutting-edge tools to deliver effective, future-proof AI solutions." imageurl={AIImg3} />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div className="big_title mt-5 pt-5 mb-5">
                        <h2 className='new_title text-center'>Our Comprehensive AI Services & Solutions</h2>
                        <p>Our AI Solutions and Capabilities encompass a wide range of services and technologies designed to harness the power of Artificial Intelligence for your business. </p>
                    </div>
                </Col>
            </Row>
            <Row xs={1} md={2} lg={2}>
                <Col>
                    <div className="white_card">
                        <h3>AI Consulting and Strategy Development</h3>
                        <p>We offer expert guidance to define your AI roadmap, identify use cases, and create a clear strategy that aligns with your business goals. </p>
                    </div>
                </Col>
                <Col>
                    <div className="white_card">
                        <h3>Custom AI Development</h3>
                        <p>Our team of skilled AI developers can create tailored AI solutions, including chatbots, recommendation engines, natural language processing applications, computer vision systems, and more.</p>
                    </div>
                </Col>
                <Col>
                    <div className="white_card">
                        <h3>Machine Learning Models</h3>
                        <p>We specialize in developing and deploying machine learning models for predictive analytics, forecasting, and data-driven decision-making.</p>
                    </div>
                </Col>
                <Col>
                    <div className="white_card">
                        <h3>Data Analytics and Insights</h3>
                        <p>Our data analytics services help you unlock the value of your data, providing actionable insights to inform business strategies. </p>
                    </div>
                </Col>
                <Col>
                    <div className="white_card">
                        <h3>AI Integration</h3>
                        <p>We seamlessly integrate AI solutions into your existing systems, ensuring a smooth transition and minimal disruption to your workflow. </p>
                    </div>
                </Col>
                <Col>
                    <div className="white_card">
                        <h3>Chatbots and Virtual Assistants</h3>
                        <p>We design and implement chatbots and virtual assistants to enhance customer support, streamline inquiries, and improve user experiences.</p>
                    </div>
                </Col>
                <Col>
                    <div className="white_card">
                        <h3>Computer Vision</h3>
                        <p>We develop computer vision solutions for image and video analysis, enabling applications such as facial recognition, object detection, and more. </p>
                    </div>
                </Col>
                <Col>
                    <div className="white_card">
                        <h3>Natural Language Processing (NLP)</h3>
                        <p>We leverage NLP techniques to extract valuable insights from unstructured text data, automate content generation, and enhance communication. </p>
                    </div>
                </Col>
                <Col>
                    <div className="white_card">
                        <h3>Predictive Analytics</h3>
                        <p>We build predictive models to anticipate trends, customer behavior, and market dynamics, empowering you to make proactive decisions.</p>
                    </div>
                </Col>
                <Col>
                    <div className="white_card">
                        <h3>AI-Driven Automation</h3>
                        <p>We automate repetitive tasks, improving operational efficiency and freeing up your team's time for more strategic endeavours.</p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div className="big_title mt-5 pt-5 mb-5">
                        <h2 className='new_title text-center'>Industries that AI as a Service Can Empower</h2>
                        <p>AI's versatility applies to various sectors, optimizing operations, cutting costs, and enhancing products and services. </p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <OwlCarousel id='serCaro' className='owl-theme es_item_list industries_carousel' margin={8} nav dots={false}  {...options}>
                        {ToolboxServices.toolbox.map((item, key) => (
                            <div className="item" key={key}>
                                <div className="fea_ser_home_card">
                                    <Image src={`${item.url}`} alt={item.title} />
                                    <div className='fea_ser_home_card_details'>
                                        <div className="fsh_title">
                                            <h3>{item.title}</h3>
                                        </div>
                                        <p><b>{item.subtitle1}:</b> {item.description1}</p>
                                        <p><b>{item.subtitle2}:</b> {item.description2}</p>
                                    </div>
                                    
                                </div>
                            </div>
                        ))}
                        
                    </OwlCarousel>
                </Col>
            </Row>
            </Container>
            <div className="percentage_sec bg_img01 py-5 mt-5">
            <Container>
                <Row  className='my-5 py-5'>
                    <Col xs={12} md={6} lg={5}>
                    
                    <div className="text-white info_block">
                        <h2>Effortlessly unlock the power of AI and propel your business to new heights!</h2>
                        <Link className='btn btn-primary' to="/contact">Connect with AI Experts!</Link>
                    </div>
                    </Col>
                    <Col xs={12} md={6} lg={7}>
                    
                    <div className="text-white">
                        <ul>
                            <li><b>42</b><small>Revenue growth with smart insights.</small></li>
                            <li><b>57</b><small>Costs reduced by streamlining operations. </small></li>
                            <li><b>90</b><small>Customer satisfaction with personalized experiences.</small></li>
                            <li><b>100</b><small>Security enhanced with data protection.</small></li>
                        </ul>
                    </div>
                    </Col>
                </Row>
            </Container>
        </div>
            <Container>
            <Row xs={1} md={2} lg={2} className='my-5 py-5 big_text_sticky'>
                <Col>
                
                <div className="big_title scroll-reveal sticky-top pt-5">
                    <h2 className='new_title mt-5 mx-auto'><span>Our Commitment to Ethical <q>AI</q></span> </h2>
                </div>
                </Col>
                <Col>
                
                <div className="big_title scroll-reveal">
                    <p><span>We understand the importance of ethical AI development. Our AI services prioritize transparency, fairness, and accountability. We adhere to industry best practices to ensure that our AI solutions do not perpetuate biases or discriminate against any group.</span></p>
                    <p><span>The future of business is connected to AI, and our AI Services Company is here to guide you. Whether you're a startup wanting to innovate or a big company aiming for efficiency, we have the skills and technology to help you use AI to its fullest.</span></p>
                    <p><span>Reach out to us today, and let's start making your future smarter and more competitive with AI. <em><b>Join us in embracing a limitless future of innovation.</b></em></span> </p>
                </div>
                </Col>
            </Row>
        </Container>
        <FAQ faqData={dataFaq}/>
    </div>
    </>
  )
}
