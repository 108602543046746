import React, { useEffect, useState } from 'react'
import PageTopHeadPlain from '../../components/UI/PageTopHeadPlain'
import { Col, Container, Row } from 'react-bootstrap'
import BlogCard from './BlogCard'
import './Blog.css'

export default function Blog() {
    const [blogData , setBlogData] = useState([]);
    useEffect(()=>{
        fetch("https://web.alcyone.in/api/alcyone/blogs").then((res)=>{
        res.json().then((resp)=>{
            setBlogData(resp.result)
        })
    });
    },[])
  return (
    <section className='inner_page pb-5'>
        <PageTopHeadPlain pageTitle="Blogs" description="Future tech, simplified. Dive deep into the next generation of innovation with insightful articles and expert breakdowns."/>
        <Container>
            <Row sm={1} md={2} lg={2} className='blog_list'>
                {blogData.map((blogItem) => (
                    <Col key={blogItem.id}><BlogCard data={blogItem}/></Col>
                ))
                }
            </Row>
        </Container>
    </section>
  )
}
