import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import PageTopHead from '../../components/UI/PageTopHead'
import FlipCard from '../../components/FlipCard/FlipCard'
import './ServiceLayout.css'
import OwlCarousel from 'react-owl-carousel'; 
import '../../../node_modules/owl.carousel/dist/assets/owl.carousel.css';
import '../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css';
import AIImg1 from '../../assets/images/services/ai/alcyone_ai_1.jpg'
import AIImg3 from '../../assets/images/services/ai/alcyone_ai_3.jpg'
import AIImg4 from '../../assets/images/services/ai/alcyone_ai_4.jpg'
import AIImg5 from '../../assets/images/services/ai/alcyone_ai_5.jpg'
import AIImg6 from '../../assets/images/services/ai/alcyone_ai_6.jpg'
import AIImg7 from '../../assets/images/services/ai/alcyone_ai_7.jpg'
import ToolboxServices from "../../data/gen_ai_ml.json";
import AILogo from "../../data/technologies_ai.json";
import FAQ from '../../components/faq/FAQ'
import faqs from "../../data/faqs.json";
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

export default function GenAIML() {
    const options = {
        
        responsive: {
            0: {
                items: 1,
                // stagePadding: 50,
            },
            450: {
                items: 1,
                stagePadding: 50,
            },
            600: {
                items: 2,
                stagePadding: 50,
            },
            1000: {
                items: 4,
            },
            1600: {
                items: 4,
    
            }
        },
    };
    const dataFaq = faqs.genaiml_faq;
  return (
    <>
    <Helmet>
    <title>Best Generative AI and ML Development Services and Solutions Company</title>
    <meta name="description" content="Transform your business with top-tier Generative AI and ML solutions. Explore the possibilities of our advanced development services to propel your growth! " />
  </Helmet>
    <div className='inner_page pb-5'>
        <PageTopHead pageTitle="Generative AI/ML Services" description="Elevate your business operations and stay ahead of the curve by implementing cutting-edge AI solutions."/>
        <Container>
            <Row>
                <Col>
                <div className="big_title my-5 py-5 scroll-reveal">
                    <p>Welcome to Alcyone Technologies, <b>the best Generative AI development services company</b>. We specialize in creating custom AI and ML solutions that turn your data into actionable insights, streamline processes, and deliver exceptional customer experiences. Discover how our innovative technology can elevate your business.</p>
                </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="big_title pt-5 mb-5">
                    <h2 className='new_title text-center'>Our Generative AI and ML Expertise</h2>
                    <p>Alcyone Technology boasts a team of highly skilled professionals with extensive experience in various Generative AI and Machine Learning domains, including:</p>
                    </div>
                </Col>
            </Row>
            <Row xs={1} md={2} lg={3} className='ser_cards_grid mt-4'>
                <Col>
                    <FlipCard title="Generative Models" desc="Leverage cutting-edge Generative Adversarial Networks (GANs) for tasks like content creation, image generation, and data augmentation." imageurl={AIImg1} />
                </Col>
                <Col>
                    <FlipCard title="Natural Language Processing (NLP)" desc="Build intelligent chatbots, sentiment analysis tools, and personalized content generation models using advanced NLP techniques." imageurl={AIImg4} />
                </Col>
                <Col>
                    <FlipCard title="Deep Learning" desc="Develop and implement deep learning architectures (e.g., Convolutional Neural Networks) for computer vision tasks, image classification, and other advanced applications." imageurl={AIImg5} />
                </Col>
                <Col>
                    <FlipCard title="Computer Vision" desc="Utilize computer vision algorithms for object recognition, image segmentation, and anomaly detection in visual data." imageurl={AIImg3} />
                </Col>
                <Col>
                    <FlipCard title="Data Visualization" desc="Present complex data insights in an interactive and user-friendly manner using advanced data visualization tools and techniques." imageurl={AIImg6} />
                </Col>
                <Col>
                    <FlipCard title="MLOps Integration" desc="Ensure smooth deployment, monitoring, and maintenance of your AI and ML solutions through robust MLOps practices." imageurl={AIImg7} />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div className="big_title mt-5 pt-5 mb-5">
                        <h2 className='new_title text-center'>Our Generative AI Development Services for Your Business</h2>
                        <p>Our generative AI development services open the door to limitless opportunities for AI-powered creativity and innovation.</p>
                    </div>
                </Col>
            </Row>
            <Row xs={1} md={2} lg={2}>
                <Col>
                    <div className="white_card">
                        <h3>Generative AI Consulting</h3>
                        <p>Our experts become an extension of your team, identifying the perfect AI application to address your specific challenges and goals.</p>
                    </div>
                </Col>
                <Col>
                    <div className="white_card">
                        <h3>End-to-End Development</h3>
                        <p>We handle everything from data preparation to model training and deployment, ensuring a smooth and successful AI journey.</p>
                    </div>
                </Col>
                <Col>
                    <div className="white_card">
                        <h3>Seamless AI Integration</h3>
                        <p>We'll flawlessly integrate your Generative AI solution with your existing systems, maximizing efficiency and minimizing disruption.</p>
                    </div>
                </Col>
                <Col>
                    <div className="white_card">
                        <h3>Data, Decoded</h3>
                        <p>Our data analysis expertise unlocks hidden insights and empowers you to make data-driven decisions fueled by AI.</p>
                    </div>
                </Col>
                <Col>
                    <div className="white_card">
                        <h3>Model Replication Made Easy</h3>
                        <p>Need to replicate a complex Generative AI model? We'll handle it, saving you time and resources.</p>
                    </div>
                </Col>
                <Col>
                    <div className="white_card">
                        <h3>Unwavering Generative AI Support</h3>
                        <p>We don't disappear after deployment. We offer ongoing support to ensure your AI solution continues to deliver optimal results.</p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div className="big_title mt-5 pt-5 mb-5">
                        <h2 className='new_title text-center'>Our Proven Process for Developing Generative AI Solutions</h2>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <OwlCarousel id='serCaro' className='owl-theme es_item_list industries_carousel' margin={8} nav dots={false}  {...options}>
                        {ToolboxServices.toolbox.map((item, key) => (
                            <div className="item" key={key}>
                                <div className="fea_ser_home_card">
                                    <Image src={`${item.url}`} alt={item.title} />
                                    <div className='fea_ser_home_card_details'>
                                        <div className="fsh_title">
                                            <h3>{item.title}</h3>
                                        </div>
                                        <p>{item.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                        
                    </OwlCarousel>
                </Col>
            </Row>
            </Container>
            <div className="percentage_sec bg_img01 py-5 mt-5">
            <Container>
                <Row  className='my-5 py-5'>
                    <Col xs={12} md={6} lg={5}>
                    
                    <div className="text-white info_block">
                        <h2>Effortlessly unlock the power of AI and propel your business to new heights!</h2>
                        <Link className='btn btn-primary' to="/contact">Connect with AI Experts!</Link>
                    </div>
                    </Col>
                    <Col xs={12} md={6} lg={7}>
                    
                    <div className="text-white">
                        <ul>
                            <li><b>42</b><small>Revenue growth with smart insights.</small></li>
                            <li><b>57</b><small>Costs reduced by streamlining operations. </small></li>
                            <li><b>90</b><small>Customer satisfaction with personalized experiences.</small></li>
                            <li><b>100</b><small>Security enhanced with data protection.</small></li>
                        </ul>
                    </div>
                    </Col>
                </Row>
            </Container>
        </div>
        
            <Container>
                <Row>
                    <Col>
                    <div className="big_title mt-5 pt-5 mb-5">
                        <h2 className='new_title text-center'>Our Generative AI Models Expertise</h2>
                    </div>
                    <div className='technologies mt-5 ai_techs'>
                        <div className="tech_logo_box">
                            
                            {AILogo.techs.map((image, i) => (
                                <div className="tech_item" key={i}>
                                <Image src={`${image.url}`} alt={image.description} key={i} />
                                <span>{image.description}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                    </Col>
                </Row>
            <Row xs={1} md={2} lg={2} className='my-5 py-5 big_text_sticky'>
                <Col>
                
                <div className="big_title scroll-reveal sticky-top pt-5">
                    <h2 className='new_title mt-5 mx-auto'><span>Conclusion</span> </h2>
                </div>
                </Col>
                <Col>
                
                <div className="big_title scroll-reveal">
                    <p><span>At Alcyone Technologies, we believe in the power of Generative AI and ML Solutions to revolutionize businesses.</span> </p> 
                    <p><span>Our comprehensive suite of services is designed to help you harness this power, driving efficiency, innovation, and growth. </span> </p>
                    <p><span>Partner with us and stay ahead in the ever-evolving digital landscape.</span> </p>
                </div>
                </Col>
            </Row>
        </Container>
        <FAQ faqData={dataFaq}/>
    </div>
    </>
  )
}
