import React from 'react'
import './FlipCard.css'

export default function FlipCard(props) {
  return (
    <div className="box-item">
        <div className="flip-box">
            <div className="flip-box-front text-center" style={{ backgroundImage: `url(${props.imageurl})` }}>
            
                <div className="inner">
                    <h2 className="flip-box-header">{props.title}</h2>
                </div>
            </div>
            <div className="flip-box-back text-center" >
                <div className="inner">
                    <p>{props.desc}</p>
                </div>
            </div>
        </div>
	</div>
  )
}
