import React from 'react'
import {  Container, Row, Col} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './ExpandedServices.css'
import OwlCarousel from 'react-owl-carousel'; 
import '../../../node_modules/owl.carousel/dist/assets/owl.carousel.css';
import '../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css';
import SmartIt from '../../assets/images/smart_it.png'
import CyberSecure from '../../assets/images/cybersecurity.png'
import CustomSoftware from '../../assets/images/custom_software.png'
import Infrastructure from '../../assets/images/infrastructure.png'
import DataAnaylic from '../../assets/images/data_analytics.png'
import DigitalTrans from '../../assets/images/digital_transformation.png'
import AIBar from '../../assets/images/blue_robot_8.png'

export default function ExpandedServices() {
    const options = {
        
        responsive: {
            0: {
                items: 1,
                // stagePadding: 50,
            },
            450: {
                items: 1,
                stagePadding: 50,
            },
            600: {
                items: 2,
                stagePadding: 50,
            },
            1000: {
                items: 3,
            },
            1600: {
                items: 3,
    
            }
        },
    };
  return (
    <div className='expnd_services py-5'>
        <Container>
            <Row>
                <Col lg={7} className='d-flex align-items-center justify-content-start'>
                    <div>
                        <h2 className='new_title mb-5'>Our Expanded IT Services Portfolio</h2>
                    </div>
                </Col>
            </Row>
            <Row className='expnd_services_list'>
                <Col>
                    <OwlCarousel id='serCaro' className='owl-theme es_item_list' autoplay margin={10} nav dots={false}  {...options}>
                        <div className="item">
                            <div className='es_box'>
                                <div className="es_box_title">
                                    <img src={SmartIt} alt="" />
                                    <h3>Smart IT Planning</h3>
                                </div>
                                <span className="es_box_foot">We create IT strategies that match your business goals, making technology a growth booster.</span>
                            </div>
                        </div>
                        <div className="item">
                            <div className='es_box'>
                                <div className="es_box_title">
                                    <img src={Infrastructure} alt="" />
                                    <h3>Infrastructure Solutions</h3>
                                </div>
                                <span className="es_box_foot">Our experts design and set up tough and flexible IT systems, including cloud migration and secure networks.</span>
                            </div>
                        </div>
                        <div className="item">
                            <div className='es_box'>
                                <div className="es_box_title">
                                    <img src={CustomSoftware} alt="" />
                                    <h3>Custom Software</h3>
                                </div>
                                <span className="es_box_foot">Our talented team builds unique software that makes tasks easier and improves user experiences.</span>
                            </div>
                        </div>
                        <div className="item">
                            <div className='es_box'>
                                <div className="es_box_title">
                                    <img src={CyberSecure} alt="" />
                                    <h3>Cybersecurity</h3>
                                </div>
                                <span className="es_box_foot">We protect your digital stuff with strong cybersecurity, so your business stays safe from potential threats.</span>
                            </div>
                        </div>
                        <div className="item">
                            <div className='es_box'>
                                <div className="es_box_title">
                                    <img src={DataAnaylic} alt="" />
                                    <h3>Data Analytics</h3>
                                </div>
                                <span className="es_box_foot">We help you understand your data with analytics, so you can make smarter choices.</span>
                            </div>
                        </div>
                        <div className="item">
                            <div className='es_box'>
                                <div className="es_box_title">
                                    <img src={DigitalTrans} alt="" />
                                    <h3>Digital Transformation</h3>
                                </div>
                                <span className="es_box_foot">We guide you through the digital world, so you can stay ahead in a changing market.</span>
                            </div>
                        </div>
                    </OwlCarousel>
                </Col>
            </Row>
            {/* <Row xs={1} md={2} lg={3} className='expnd_services_list'>
                <Col>
                    <div className='es_box'>
                        <div className="es_box_title">
                            <img src={SmartIt} alt="" />
                            <h3>Smart IT Planning</h3>
                        </div>
                        <span className="es_box_foot">We create IT strategies that match your business goals, making technology a growth booster.</span>
                    </div>
                </Col>
                <Col>
                    <div className='es_box'>
                        <div className="es_box_title">
                            <img src={Infrastructure} alt="" />
                            <h3>Infrastructure Solutions</h3>
                        </div>
                        <span className="es_box_foot">Our experts design and set up tough and flexible IT systems, including cloud migration and secure networks.</span>
                    </div>
                </Col>
                <Col>
                    <div className='es_box'>
                        <div className="es_box_title">
                            <img src={CustomSoftware} alt="" />
                            <h3>Custom Software</h3>
                        </div>
                        <span className="es_box_foot">Our talented team builds unique software that makes tasks easier and improves user experiences.</span>
                    </div>
                </Col>
                <Col>
                    <div className='es_box'>
                        <div className="es_box_title">
                            <img src={CyberSecure} alt="" />
                            <h3>Cybersecurity</h3>
                        </div>
                        <span className="es_box_foot">We protect your digital stuff with strong cybersecurity, so your business stays safe from potential threats.</span>
                    </div>
                </Col>
                <Col>
                    <div className='es_box'>
                        <div className="es_box_title">
                            <img src={DataAnaylic} alt="" />
                            <h3>Data Analytics</h3>
                        </div>
                        <span className="es_box_foot">We help you understand your data with analytics, so you can make smarter choices.</span>
                    </div>
                </Col>
                <Col>
                    <div className='es_box'>
                        <div className="es_box_title">
                            <img src={DigitalTrans} alt="" />
                            <h3>Digital Transformation</h3>
                        </div>
                        <span className="es_box_foot">We guide you through the digital world, so you can stay ahead in a changing market.</span>
                    </div>
                </Col>
            </Row> */}
            <Row>
                <Col>
                    <div className="es_foot">
                        
                    <div className="esf_info">
                            <h4>Step into Intelligent Progress and Transform Your Business with <b>Our Expert AI Solutions</b></h4>
                            <Link to="/contact" className='rounded_btn '>Connect Now</Link>
                        </div>
                        <div className="esf_graphics">
                            <div className="esf_img"><img className='img-fluid' src={AIBar} alt="" /></div>
                        </div>
                        
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}
