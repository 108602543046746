export const galeryData = [
    {
      "id": 1,
      "title": "Celebration 2021",
      "image": "uploads/gallery/1654519341-45077398.png",
      "slug": "1",
      "status": 1,
      "created_at": "2022-05-30T09:59:53.000000Z",
      "updated_at": "2022-06-10T13:50:25.000000Z"
    },
    {
      "id": 2,
      "title": "Celebration 2021",
      "image": "uploads/gallery/1654519358-77673420.png",
      "slug": "2",
      "status": 1,
      "created_at": "2022-05-30T09:59:53.000000Z",
      "updated_at": "2022-06-07T15:23:23.000000Z"
    },
    {
      "id": 3,
      "title": "Celebration 2021",
      "image": "uploads/gallery/1654519372-44311965.png",
      "slug": "3",
      "status": 1,
      "created_at": "2022-05-30T09:59:53.000000Z",
      "updated_at": "2022-06-07T15:23:22.000000Z"
    },
    {
      "id": 4,
      "title": "Alcyone",
      "image": "uploads/gallery/1654519429-58239816.png",
      "slug": "4",
      "status": 1,
      "created_at": "2022-05-30T09:59:53.000000Z",
      "updated_at": "2022-06-07T15:23:22.000000Z"
    },
    {
      "id": 5,
      "title": "Christmas  Celebration",
      "image": "uploads/gallery/1654519766-61740214.png",
      "slug": "5",
      "status": 1,
      "created_at": "2022-05-30T09:59:53.000000Z",
      "updated_at": "2022-06-07T15:23:21.000000Z"
    },
    {
      "id": 6,
      "title": "Holi Celebration",
      "image": "uploads/gallery/1654519786-96250613.png",
      "slug": "6",
      "status": 1,
      "created_at": "2022-05-30T09:59:53.000000Z",
      "updated_at": "2022-06-07T15:23:21.000000Z"
    },
    {
      "id": 7,
      "title": "Team Alcyone",
      "image": "uploads/gallery/1654519811-22390865.png",
      "slug": "7",
      "status": 1,
      "created_at": "2022-05-30T09:59:53.000000Z",
      "updated_at": "2022-06-07T15:23:19.000000Z"
    },
    {
      "id": 14,
      "title": "Birthday Celebration",
      "image": "uploads/gallery/1654523107-68408552.jpg",
      "slug": "629e04e36fd3f",
      "status": 1,
      "created_at": "2022-06-06T13:45:07.000000Z",
      "updated_at": "2022-06-07T15:23:17.000000Z"
    }
  ]

 export const feedData = [
    {
      "id": 1,
      "name": "Lakhan Bairawat",
      "designation": "Software Engineer",
      "image": "uploads/images/828_1654588965.PNG",
      "description": "Alcyone is a fast-growing company and a wonderful place to work! The company's founder shows genuine concern for every person, encourages creativity, and sets a good example. I have never worked for a company with a better culture than Alcyone, where everyone is trustworthy and passionate about their work. The managers are always eager to assist, and if necessary, they will spend some of their free time with you.",
      "slug": "lakhan-bairawat-62d9174ed0ceb",
      "status": 1,
      "created_at": "2022-05-30T09:17:59.000000Z",
      "updated_at": "2022-07-21T09:07:26.000000Z"
    },
    {
      "id": 2,
      "name": "Dhiraj Umale",
      "designation": "Lead Software Engineer",
      "image": "uploads/images/26_1654588609.PNG",
      "description": "I have had a wonderful working relationship with the company for the past 3 years. It's a great workplace where employees not only receive fair pay for their efforts but also have many opportunities to advance their careers and learn new skills.",
      "slug": "dhiraj-umale-62d91789c98c2",
      "status": 1,
      "created_at": "2022-05-30T09:17:59.000000Z",
      "updated_at": "2022-07-21T09:08:25.000000Z"
    },
    {
      "id": 3,
      "name": "Ravi Kumar Mali",
      "designation": "Lead Software Engineer",
      "image": "uploads/images/788_1654595854.jpeg",
      "description": "My experience at Alcyone for the last 3 years has been amazing. This company has made a lot of progress in these years. The work culture here is employee friendly. Every employee supports each other, shares knowledge, and picks up new skills every day.",
      "slug": "ravi-kumar-mali-62d917b9913e5",
      "status": 1,
      "created_at": "2022-05-30T09:17:59.000000Z",
      "updated_at": "2022-07-21T09:09:13.000000Z"
    },
    {
      "id": 4,
      "name": "Ravi Bagwan",
      "designation": "Software Engineer",
      "image": "uploads/images/149_1654606719.png",
      "description": "With true dedication and effort, I made it possible for the collaborative growth of me and the company. The joint interest and creativity of team members had come to an extent that we are growing day by day in linear growth. I found this company the best place to work to date.",
      "slug": "ravi-bagwan-62d917de0b7d2",
      "status": 1,
      "created_at": "2022-05-30T09:17:59.000000Z",
      "updated_at": "2022-07-21T09:09:50.000000Z"
    }
  ]