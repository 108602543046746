import React from 'react'
import { Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function BlogCard({data}) {
  return (
    <div className='blog_card'>
        <div className="blog_img">
            <Image fluid src={`https://web.alcyone.in/${data.banner_image}`} alt={data.name}/>
        </div>
        <div className="blog_info">
          <div className="blog_date">{data.date}</div>
          <Link to={`/blog/${data.slug}`} className='stretched-link'><h3 className="blog_title">{data.title}</h3></Link>
        </div>
    </div>
  )
}
