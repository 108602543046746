import React from 'react'
import { Helmet } from 'react-helmet'

export default function MetaTag(props) {
  return (
    <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.metaTag} />
    </Helmet>
  )
}
