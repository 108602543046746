import React from 'react'
import { Container, Row, Col, Image} from 'react-bootstrap'
import { FiArrowUpRight } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import './HomeMMS.css'
import HomeMmsImage from '../../assets/images/productbanner_img.png'

export default function HomeMMS() {

   
  return (
    <section className='mms_sec py-5'>
        <Container>
            <Row>
                <Col md={7}>
                    <div className="mms_text">
                    <div className="title">
                        <h2>Member Management <span>Software</span></h2>
                        <p>An application for the ultimate all-in-one solution. Simplify your life as a<b> member, employee, coach</b> or <b>manager </b>of an organization.</p>
                    </div>
                    <Link to="/ourproduct" className='btn btn-outline-light'>Case Study  <FiArrowUpRight/></Link>
                    </div>
                </Col>
                <Col md={5}>
                    <div className="d-flex align-items-center h-100 mms_img"><Image src={HomeMmsImage} alt="" fluid/></div>
                </Col>
            </Row>
        </Container>
    </section>
  )
}
