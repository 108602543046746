import React, { useEffect, useState } from 'react'
import './Team.css'
import { Image } from 'react-bootstrap'

export default function Team() {
  const [team, setTeam] = useState([]);
  useEffect(() => {
    fetch("../../data/team.json").then((res) => {
      res.json().then((resp) => {
        setTeam(resp.team)
      })
    });
  },[])
  return (
    <div className='teams_grid'>
    {team.map(member => (
      <div className="team_card" key={member.id}>
        <div className="tc_img"><Image src={`${member.url}`} alt={member.name}/></div>
        <div className="tc_info">
          <h5>{member.name}</h5>
          <h6>{member.post}</h6>
        </div>
      </div>
    ))}
      
    </div>
  )
}
