import React from 'react'
import { Image} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import styles from './UI.module.css'

export default function ActionBox(props) {
  return (
    <div className={styles.action_box}>
        <div>
            <div className={styles.icon_div}><Image src={props.icon}></Image></div>
            <h2>{props.title}</h2>
            <div>{props.subTitle}</div>
        </div>
        <Link to={props.redirect} className={`btn btn-primary ${styles.ylw_btn}`}>{props.btnTxt}</Link>
    </div>
  )
}
