import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';

import './FAQ.css'

export default function FAQ(props) {
    
  return (
    <div className="faq_section mt-5 pt-5 mb-5">
        <Container>
            <Row>
                <Col md={12}>
                    <div className="big_title mb-5">
                        <h2 className='new_title text-center'>FAQ</h2>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="faq_wrap">
                        <Accordion defaultActiveKey="0" flush>
                            {Object.values(props.faqData).map((q,i) => (
                                <Accordion.Item eventKey={i} className='mb-2' key={i}>
                                    <Accordion.Header>{q.que}</Accordion.Header>
                                    <Accordion.Body>{q.ans}</Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}
