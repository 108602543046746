import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import PageTopHead from '../../components/UI/PageTopHead';
import './Career.css'
import { useFormik } from 'formik';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Apply() {
    const [openings, setOpenings] = useState([]);
    const [isSuccessfullySubmitted, setIsSuccessfullySubmitted ] = React.useState(false);
    const params = useParams();
    // const validate = (values) => {
    //     const errors = {};
    //     if (!values.name) {
    //         errors.name = "Name is required*";
    //     } else if (values.name.length < 3) {
    //         errors.name = "Name must be more than 2 characters";
    //     } else if (!/^[a-zA-Z ]*$/.test(values.name)) {
    //         errors.name = "Name must be in alphabet letters"
    //     }

    //     if (!values.email) {
    //         errors.email = "Email is required*";
    //     } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    //         errors.email = "Invalid email address";
    //     }

    //     if (!values.mobile) {
    //         errors.mobile = "Phone is required*";
    //     } else if (!/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/g.test(values.mobile)) {
    //         errors.mobile = "Invalid phone number";
    //     }

    //     if (!values.cv) {
    //         errors.cv = "Required only in pdf*";
    //     }

    //     return errors;
    // };
    const onSubmit = async (values, { resetForm }) => {
        console.log(values);
        let formData = new FormData();
        if (values.cv) {
            formData.append('candidateCv', values.cv);
        }
        formData.append('candidateName', values.name)
        formData.append('candidateEmail', values.email)
        formData.append('candidateMobile', values.mobile)
        formData.append('link', values.profile);
        formData.append('careerId', params.id);
        axios.post('https://web.alcyone.in/api/applyJob', formData, {
            headers: {
                'Accept': 'application/form-data',
                'Content-Type': "multipart/form-data",
            }
        }).then((response) => {
            console.log(response);
            if(response?.data?.status === true){
                setIsSuccessfullySubmitted(true);
                // window.location.reload();
                setTimeout(()=>{
                    setIsSuccessfullySubmitted(false);
                },3000)
                resetForm();
            }else{
                toast.error(response?.data?.message, { autoClose: 2000 });
            }
        })
    }
    const formik = useFormik({
        initialValues: {
            job: '',
            name: '',
            email: '',
            mobile: '',
            profile: '',
            cv: '',
        },
        // validate,
        onSubmit,
    });
    useEffect(() => {
        fetch("https://web.alcyone.in/api/careers").then((res) => {
            res.json().then((resp) => {
                setOpenings(resp.result)
            })
        });
    }, [])
    // useEffect(() => {
    //     window.scrollTo(0, 0)
    // }, [])
    return (
        <section className='inner_page pb-5'>
            {openings && openings
                .filter(job => job.id === params.id)
                .map(job => (
                    <>
                        <PageTopHead pageTitle={`We're Hiring`} description={`${job.title} ___ ${job.location}`} />
                        <Container>
                            <Row>
                                <Col md={7} className="mx-auto mt-4">


                                    <div className='job_box mb-4' key={job.id}>
                                        <h2>{job.title}</h2>
                                        <div dangerouslySetInnerHTML={{ __html: job.description }} />
                                        <div className='oj_desc mb-5' dangerouslySetInnerHTML={{ __html: job.job_description }} />
                                    </div>

                                </Col>
                            </Row>
                            <Row>
                                <Col md={7} className="mx-auto mt-4 mb-5">
                                    <form className='apply_form mb-5 bg-light' onSubmit={formik.handleSubmit}>
                                        <h3>Apply for this Job</h3>
                                        <Row>
                                            <Col md={6}>
                                                <input type="text"
                                                    name="job"
                                                    onChange={formik.handleChange}
                                                    value={job.title}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <input type="text" placeholder="Your Name"
                                                    name="name"
                                                    maxLength='80'
                                                    onChange={formik.handleChange}
                                                    value={formik.values.name}
                                                    onBlur={formik.handleBlur}
                                                    required
                                                />
                                                {/* {formik.touched.name && formik.errors.name ?
                                                    <small role="alert" className='mt-1 text-danger'>{formik.errors.name}
                                                    </small> : null} */}
                                                {/* {errors.firstName?.type === 'required' && <small role="alert" className='mt-1 text-danger'>Please enter your good name!</small>} */}
                                            </Col>
                                            <Col md={6}>
                                                <input type="email" placeholder="Email Address"
                                                    name="email"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.email}
                                                    required
                                                />
                                                {/* {formik.touched.email && formik.errors.email ?
                                                    <small role="alert" className='mt-1 text-danger'>{formik.errors.email}
                                                    </small> : null} */}
                                                {/* {errors.emailAddress?.type === 'required' && <small role="alert" className='mt-1 text-danger'>Please enter valid email ID</small>} */}
                                            </Col>
                                            <Col md={6}>
                                                <input type="text" placeholder="Mobile Number"
                                                    name="mobile"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.mobile}
                                                    required
                                                    maxLength={10}
                                                />
                                                 {/* {formik.touched.mobile && formik.errors.mobile ?
                                                    <small role="alert" className='mt-1 text-danger'>{formik.errors.mobile}
                                                    </small> : null} */}
                                                {/* {errors.mobileNumber?.type === 'required' && <small role="alert" className='mt-1 text-danger'>Please enter your Contact Number.</small>} */}
                                            </Col>
                                            <Col md={6}>
                                                <input type="text" placeholder="Website or LinkedIn Profile"
                                                    name="profile"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.profile}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <input type="file"
                                                    name="cv"
                                                    id = 'cv'
                                                    onChange={(event) => {
                                                        formik.setFieldValue("cv", event.target.files[0]);
                                                    }}
                                                    accept='.pdf'
                                                    required
                                                //   value={formik.values.cv}
                                                />
                                                <small role="alert" className='mt-1'>Upload your CV(PDF only).</small>
                                                   {/* {formik.touched.cv && formik.errors.cv ?
                                                    <small role="alert" className='mt-1 text-danger'>{formik.errors.cv}
                                                    </small> : null} */}
                                                {/* {errors.file?.type === 'required' && <small role="alert" className='mt-1 text-danger'>Please upload PDF file only.</small>} */}
                                            </Col>
                                            <Col md={12}>
                                                <button type="submit" className='mt-4 btn btn-primary d-flex'>Submit</button>
                                            </Col>
                                        </Row>
                                        {isSuccessfullySubmitted  && (
                                        <div className="text-white rounded mt-4 bg-success p-3">Great! Our HR team will connect with you soon. <b>Be ready for your next dream job!</b></div>
                                    )}
                                    </form>
                                    {/* <form className='apply_form mb-5 bg-light' onSubmit={handleSubmit(onSubmit)}>
                                    <h3>Apply for this Job</h3>
                                    <Row>
                                        <Col md={6}>
                                            <input type="text" defaultValue={job.title} {...register} />
                                        </Col>
                                        <Col md={6}>
                                            <input type="text" placeholder="Your Name" {...register("firstName", {required: true, maxLength: 80})} aria-invalid={errors.firstName ? "true" : "false"}/>
                                    {errors.firstName?.type === 'required' && <small role="alert" className='mt-1 text-danger'>Please enter your good name!</small>}
                                        </Col>
                                        <Col md={6}>
                                            <input type="text" required placeholder="Email Address" {...register("emailAddress", {required: true})} aria-invalid={errors.emailAddress ? "true" : "false"} />
                                    {errors.emailAddress?.type === 'required' && <small role="alert" className='mt-1 text-danger'>Please enter valid email ID</small>}
                                        </Col>
                                        <Col md={6}>
                                            <input type="text" placeholder="Mobile Number" {...register("mobileNumber", {required: true})}aria-invalid={errors.mobileNumber ? "true" : "false"} />
                                    {errors.mobileNumber?.type === 'required' && <small role="alert" className='mt-1 text-danger'>Please enter your Contact Number.</small>}
                                        </Col>
                                        <Col md={6}>
                                            <input type="text" placeholder="Website or LinkedIn Profile" {...register} />
                                        </Col>
                                        <Col md={6}>
                                            <input type="file" name="image" {...register("file", {required: true})}aria-invalid={errors.file ? "true" : "false"} />
                                    {errors.file?.type === 'required' && <small role="alert" className='mt-1 text-danger'>Please upload PDF file only.</small>}
                                        </Col>
                                        <Col md={12}>
                                            <button type="submit" className='mt-4 btn btn-primary d-flex'>Submit</button>
                                        </Col>
                                    </Row>
                                    {isSuccessfullySubmitted  && (
                                        <div className="text-white rounded mt-4 bg-success p-3">Great! Our HR team will connect with you soon. <b>Be ready for your next dream job!</b></div>
                                    )}
                                </form> */}
                                </Col>
                            </Row>
                            <ToastContainer/>
                        </Container>
                    </>
                ))
            }
        </section>
    )
}
