import React from 'react';
import {  Container, Row, Col, Image} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel'; 
import '../../../node_modules/owl.carousel/dist/assets/owl.carousel.css';
import '../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css';
import ToolboxServices from "../../data/toolbox_services.json";
import './FeaServices.css'
// import AIImg from '../../assets/images/AI_grapics.png'
// import IotImg from '../../assets/images/iot.png'
// import CloudImg from '../../assets/images/cloud_solution.png'
// import HardwareImg from '../../assets/images/hardware.png'
// import MachineImg from '../../assets/images/machine.png'

export default function FeaServices() {
    const options = {
        
        stoponhover : "true",
        responsive: {
            0: {
                items: 1,
                // stagePadding: 50,
            },
            600: {
                items: 2,
            },
            900: {
                items: 3,
            },
            1400: {
                items: 3,
            }
        },
    };
  return (
    <>
    <Row>
        <Col lg={6}>
            <div className='mb-4'>
                <h2 className='new_title'>Our Digital Engineering Toolbox</h2>
                <p className='fs-5'>Navigate the Digital Landscape Seamlessly with Our Exceptional Digital Engineering Support!</p>
            </div>
        </Col>
    </Row>
    <OwlCarousel id='serCaro' className='owl-theme es_item_list' margin={10} autoplay nav dots={false}  {...options}>
        {ToolboxServices.toolbox.map((item, key) => (
            <div className="item" key={key}>
                <Link to={item.url}>
                <div className="fea_ser_home_card">
                    <Image src={`${item.imgurl}`} alt={item.title} />
                    <div className='fea_ser_home_card_details'>
                        <div className="fsh_title">
                            <h3>{item.title}</h3>
                            <span>{item.subtitle}</span>
                        </div>
                        <p>{item.description}</p>
                    </div>
                </div>
                </Link>
            </div>
        ))}
        
    </OwlCarousel>
            {/* <Row xs={1} md={2} lg={3} className='toolbox mb-5 pb-5'>
                <Col className='d-flex align-items-center justify-content-center'>
                    <div>
                    <h2 className='mb-5 new_title '>Our Digital Engineering Toolbox</h2>
                    <p className='fs-5'>Navigate the Digital Landscape Seamlessly with Our Exceptional Digital Engineering Support!</p>
                    </div>
                </Col>
                <Col>
                    <div className="fea_ser_home_card">
                        <img src={AIImg} alt="" />
                        <div className="fsh_title">
                            <h3>AI Marvels</h3>
                            <span>Insights Transformed!</span>
                        </div>
                        <p>Dive into the AI world, where data turns into super-smart insights. Our AI solutions use cool tech like machine learning and predictive analytics to automate stuff, unlock insights, and make decisions a breeze. </p>
                        
                    </div>
                </Col>
                <Col>
                    <div className="fea_ser_home_card">
                        <img src={IotImg} alt="" />
                        <div className="fsh_title">
                            <h3>IoT Connectivity</h3>
                            <span>Smarter Networks</span>
                        </div>
                        <p>IoT is like the heartbeat of the future! Our IoT services create super-smart networks, so your devices can chat seamlessly. Real-time data jazzes up efficiency and helps you make spot-on decisions.</p>
                        
                    </div>
                </Col>
                <Col>
                    <div className="fea_ser_home_card">
                    <img src={CloudImg} alt="" />
                        <div className="fsh_title">
                            <h3>Cloud Solutions</h3>
                            <span>Boost Your Business!</span>
                        </div>
                        <p>Ready to take your business to new heights? Our Cloud Solutions are like the superhero cape your business needs. We offer scalable infrastructure, safe data storage, and smart applications, making innovation a piece of cake. </p>
                        
                    </div>
                </Col>
                <Col>
                    <div className="fea_ser_home_card">
                    <img src={HardwareImg} alt="" />
                        <div className="fsh_title">
                            <h3>Hardware Solutions</h3>
                            <span>Your Digital Rock</span>
                        </div>
                        <p>Let's talk about building a strong digital foundation! Our Hardware Solutions are like the rock stars of the tech world. From cool custom designs to fine-tuning existing setups, we make sure your digital game is rock-solid. </p>
                        
                    </div>
                </Col>
                <Col>
                    <div className="fea_ser_home_card">
                    <img src={MachineImg} alt="" />
                        <div className="fsh_title">
                            <h3>Machine Learning</h3>
                            <span>Smart Systems</span>
                        </div>
                        <p>We're not just tech geeks; we're wizards of Machine Learning! Our solutions whip up smart systems that learn and adapt. Imagine more automation, personalized experiences, and super-efficient operations.</p>
                        
                    </div>
                </Col>
            </Row> */}
    </>
  )
}
