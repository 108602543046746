import React, { useEffect } from 'react'
import { Container} from 'react-bootstrap'
import styles from "./UI.module.css"
import AiVideo from '../../assets/images/page_title_video/ai_page_top.mp4'

export default function PageTopHead(props) {

  useEffect(() => {
    const pageTitle = document.querySelector(".ptt")
    const pageSubTitle = document.querySelector(".ptst")
    window.addEventListener("scroll", ()=>{
        let offsetY = window.scrollY
        pageTitle.style.transform= `translateY(-${offsetY * 0.1}px)`
        pageSubTitle.style.transform= `translateY(-${offsetY * 0.2}px)`
    })
  }, []); 
  return (
    <div className={styles.ip_page_title}>
      <video  autoPlay muted className={styles.video_page_top} loop>
        <source src={AiVideo} type="video/mp4"/>
      Your browser does not support the video tag.
      </video> 
      <Container className={ `${styles.container} py-4 `}>
          <h1 className='ptt'>{props.pageTitle}</h1>
          <p className='ptst'>{props.description}</p>
      </Container>
    </div>
  )
}
