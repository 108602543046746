import React from 'react'
import {  Image } from 'react-bootstrap';
import { FiMapPin, FiPhoneCall, FiMail } from "react-icons/fi";

export default function Address(props) {

  

  return (
    <div className='address mt-sm-4 mt-4'>
        <div className="country mb-3">
            <Image src={props.flag} alt={props.country}/>
            <span className='ms-2'>{props.city} <b>{props.country}</b></span>
        </div>
        <div className="location">
        <FiMapPin/> <span>{props.location}</span>
        </div>
        
        <div className="phone mt-3"><FiPhoneCall/> <a href={`tel:${props.phone}`}>{props.phone}</a></div>
        <div className="email mt-3"><FiMail/> <a href={`mailto:${props.email}`}>{props.email}</a></div>
    </div>
  )
}
