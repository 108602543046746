import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Image, Button} from 'react-bootstrap'
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';
import PageTopHead from '../../components/UI/PageTopHead';
import Title from '../../components/UI/Title';
import './Career.css'
import { feedData, galeryData } from '../../data/career';
import { Helmet } from 'react-helmet';

export default function Career() {
    const [gallery, setGallery] = useState(galeryData);
    const [memFeed, setMemFeed] = useState(feedData);
    const [openings, setOpenings]= useState([]);

  useEffect(()=>{
    fetch("https://web.alcyone.in/api/careers").then((res)=>{
      res.json().then((resp)=>{
        setOpenings(resp.result)
      })
    });
  },[])

    const options = {
        responsive: {
            0: {
                items: 1,
            },
            450: {
                items: 2,
            },
            600: {
                items: 2,
            },
            700: {
                items: 3,
            },
            1200: {
                items: 4,
    
            }
        },
    };
    const members = {
        responsive: {
            0: {
                items: 1,
            },
            700: {
                items: 2,
            },
            1200: {
                items: 2,
    
            }
        },
    };

useEffect(()=>{
    fetch('https://web.alcyone.in/api/gallery').then((res)=>{
        res.json().then((resp) => {
            setGallery(resp.result)
            localStorage.setItem('gallery', resp.result);
        })
    });
},[]);
useEffect(()=>{
    fetch('https://web.alcyone.in/api/member-feedback').then((res)=>{
        res.json().then((resp) => {
            setMemFeed(resp.result)
            localStorage.setItem('feed', resp.result);
        })
    });
},[]);

    
  return (
    <>
    <Helmet>
      <title>Build Your Career With Us</title>
      <meta name="description" content="Join Our Team and Lead Innovations" />
    </Helmet>
    <section className='inner_page pb-5'>
        <PageTopHead pageTitle="Build Your Career With Us" description="Join Our Team and Lead Innovations"/>
        <Container>
            <Row>
                <Col>
                    <OwlCarousel id='galleryCaro' className='owl-theme gallery mt-5' loop margin={10} dots={false} items={4} autoplay {...options}>
                        {gallery.map((img)=>(
                            <div className='item' key={img.id}>
                                <div className="gallery_img">
                                    <div>{img.title}</div>
                                    <Image src={`https://web.alcyone.in/${img.image}`}/>
                                </div>
                            </div>
                        ))}
                    </OwlCarousel>
                </Col>
            </Row>
            <Row className='mt-5'>
                <Col lg={12}>
                <Title mainTitle="Meet the Alcyone Constellation" />
                </Col>
                <Col lg={12}>
                    <OwlCarousel id='empCaro' className='owl-theme gallery' loop margin={10} dots={false} items={4} autoplay {...members}>
                        {memFeed.map((member)=>(
                            <div className='item' key={member.id}>
                                <div className="member_feed p-3">
                                    <div className="mem_words">
                                        {member.description}
                                    </div>
                                    <div className='mem_info mt-3'>
                                        <Image src={`https://web.alcyone.in/${member.image}`}/> <div className='ms-4'>{member.name} <small>{member.designation}</small></div>
                                    </div>
                                    
                                </div>
                            </div>
                        ))}
                    </OwlCarousel>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Title mainTitle="Jobs" subTitle="Current Openings"/>
                </Col>
                <Col lg={12} className="w-75 mx-auto career_box_w">
                    {openings.map(job => (
                        <div className='job_box mb-4 border-top pt-3 d-flex align-items-center gap-sm-5 gap-2 justify-content-between' key={job.id}>
                            <div>
                            <h2>{job.title}</h2>              
                            <div dangerouslySetInnerHTML={{ __html: job.description }} />
                            </div>
                            <Link to={`/apply/${job.id}`}><Button>Apply</Button></Link>
                        </div>
                    ))}
                </Col>
            </Row>
        </Container>
    </section>
    </>
  )
}
