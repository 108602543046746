import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import PageTopHead from '../../components/UI/PageTopHead'
import FlipCard from '../../components/FlipCard/FlipCard'
import './ServiceLayout.css'
import OwlCarousel from 'react-owl-carousel'; 
import '../../../node_modules/owl.carousel/dist/assets/owl.carousel.css';
import '../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css';
import ESDImg1 from '../../assets/images/services/esd/alcyone_embedded_img1.jpg'
import ESDImg2 from '../../assets/images/services/esd/alcyone_embedded_img2.jpg'
import ESDImg3 from '../../assets/images/services/esd/alcyone_embedded_img3.jpg'
import ESDImg4 from '../../assets/images/services/esd/alcyone_embedded_img4.jpg'
import ESDImg5 from '../../assets/images/services/esd/alcyone_embedded_img5.jpg'
import ESDImg6 from '../../assets/images/services/esd/alcyone_embedded_img6.jpg'
import HardwareServices from "../../data/hardware.json";
import FAQ from '../../components/faq/FAQ'
import faqs from "../../data/faqs.json";
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

export default function Hardware() {
    const options = {
        
        responsive: {
            0: {
                items: 1,
                // stagePadding: 50,
            },
            450: {
                items: 1,
                stagePadding: 50,
            },
            600: {
                items: 2,
                stagePadding: 50,
            },
            1000: {
                items: 4,
            },
            1600: {
                items: 4,
    
            }
        },
    };
    const dataFaq = faqs.hard_faq;
  return (
    <>
    <Helmet>
    <title>Embedded Software Development Services Company| India & USA</title>
    <meta name="description" content=" Innovation is in our DNA. We continuously push the boundaries of embedded software development, embracing the latest advancements." />
  </Helmet>
    <div className='inner_page pb-5'>
        <PageTopHead pageTitle="Embedded Software Development" description="From servers to storage, networking, and library equipment, we've got your IT hardware needs covered."/>
        <Container>
            <Row>
                <Col>
                <div className="big_title my-5 py-5 scroll-reveal">
                    <p><span>Welcome to Alcyone Technologies, your gateway to cutting-edge embedded software solutions. As a dynamic software development company with a global presence, we specialize in crafting innovative and efficient embedded software solutions that drive technological advancements across various industries. </span></p>
                </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2 className='new_title text-center'>Our Expertise in Embedded Software Development </h2>
                    <p className='fs-3 mx-auto text-center'>With a team of seasoned professionals and a passion for technology, we have established ourselves as a leading player in the field of embedded software development. Our core competencies encompass a wide array of services, including: </p>
                </Col>
            </Row>
            <Row xs={1} md={2} lg={3} className='ser_cards_grid mt-4'>
                <Col>
                    <FlipCard title="Embedded Systems Design" desc="Our experts are proficient in designing embedded systems tailored to your specific needs, ensuring optimal performance and functionality.  " imageurl={ESDImg1} />
                </Col>
                <Col>
                    <FlipCard title="Firmware Development" desc="We excel in firmware development for various platforms, enabling seamless communication and control of hardware devices. " imageurl={ESDImg2} />
                </Col>
                <Col>
                    <FlipCard title="RTOS (Real-Time Operating System) Integration" desc="We provide real-time operating system integration services to ensure efficient and responsive embedded systems. " imageurl={ESDImg3} />
                </Col>
                <Col>
                    <FlipCard title="Device Driver Development" desc="Our skilled developers create device drivers that optimize hardware interaction and enhance system stability.  " imageurl={ESDImg4} />
                </Col>
                <Col>
                    <FlipCard title="IoT (Internet of Things) Solutions" desc="We specialize in developing IoT solutions that connect embedded systems to the digital world, unlocking the potential of the Internet of Things.  " imageurl={ESDImg5} />
                </Col>
                <Col>
                    <FlipCard title="Custom Software Development" desc="Our tailored software solutions cater to diverse industries, from healthcare and automotive to industrial automation and consumer electronics.  " imageurl={ESDImg6} />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div className="big_title mt-5 pt-5 mb-5">
                        <h2 className='new_title text-center'>Our Hardware Platforms Proficiency</h2>
                        <p>At Alcyone Technologies, we have a rich and diverse experience in working with a wide range of hardware platforms, making us a go-to partner for embedded software development. Our competency extends to both microcontrollers and single-board computers, allowing us to cater to a broad spectrum of industry needs. Here's a glimpse of our hardware expertise:</p>
                    </div>
                </Col>
            </Row>
            <Row xs={1} md={2} lg={2}>
                <Col>
                    <div className="white_card">
                        <h3>Microcontrollers</h3>
                        <p>We are well-versed in developing embedded software for various microcontroller families, including but not limited to:</p>
                        <ul>
                            <li className='mb-2'>
                                <b className='d-block'>ARM Cortex-M Series</b><span>Our team excels in harnessing the power and versatility of ARM Cortex-M microcontrollers, known for their exceptional performance and energy efficiency. </span>
                            </li>
                            <li className='mb-2'><b className='d-block'>PIC Microcontrollers</b><span>We have extensive experience in programming and optimizing Microchip's PIC microcontrollers, ensuring reliable and efficient operation. </span></li>
                            <li className='mb-2'><b className='d-block'>AVR Microcontrollers</b><span>Our expertise also includes Atmel's AVR microcontrollers, popular for their ease of use and versatility in numerous applications.</span></li>
                            <li className='mb-2'><b className='d-block'>STMicroelectronics STM32 Series</b><span>We specialize in STM32 microcontrollers, offering robust solutions for real-time embedded systems. </span></li>
                        </ul>
                    </div>
                </Col>
                <Col>
                    <div className="white_card">
                        <h3>Single-Board Computers (SBCs)</h3>
                        <p>Our capabilities extend to working with single-board computers, enhancing their functionality, and enabling seamless integration into various applications. Some of the SBCs we have worked with include: </p>
                        <ul>
                            <li className='mb-2'><b className='d-block'>Raspberry Pi</b><span> We harness the power of Raspberry Pi for versatile applications, from IoT solutions to custom digital interfaces.</span></li>
                            <li className='mb-2'><b className='d-block'>Arduino</b><span>Our team is proficient in programming Arduino boards for rapid prototyping and developing innovative electronic projects.</span></li>
                            <li className='mb-2'><b className='d-block'>BeagleBone</b><span>We leverage BeagleBone for its flexibility and extensive I/O capabilities, making it ideal for various industrial applications.</span></li>
                            <li className='mb-2'><b className='d-block'>NVIDIA Jetson</b><span>We work with NVIDIA Jetson boards for AI-powered embedded systems, enabling advanced image processing and machine learning tasks.</span></li>
                        </ul>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div className="big_title mt-5 pt-5 mb-5">
                        <h2 className='new_title text-center'>What Sets Us Apart form the competition?</h2>
                        <p>When you choose our company for embedded software development, you're choosing a partner dedicated to excellence, innovation, and delivering tailored solutions that meet your unique needs and exceed your expectations. </p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <OwlCarousel id='serCaro' className='owl-theme es_item_list industries_carousel' margin={8} nav dots={false}  {...options}>
                        {HardwareServices.toolbox.map((item, key) => (
                            <div className="item" key={key}>
                                <div className="fea_ser_home_card">
                                    <Image src={`${item.url}`} alt={item.title} />
                                    <div className='fea_ser_home_card_details'>
                                        <div className="fsh_title">
                                            <h3>{item.title}</h3>
                                        </div>
                                        <p>{item.description}</p>
                                    </div>
                                    
                                </div>
                            </div>
                        ))}
                        
                    </OwlCarousel>
                </Col>
            </Row>
            </Container>
            <div className="percentage_sec bg_img01 py-5 mt-5">
            <Container>
                <Row  className='my-5 py-5'>
                    <Col xs={12} md={6} lg={5}>
                    
                    <div className="text-white info_block">
                        <h2>Drive Innovation and Efficiency with Top-of-the-Line Hardware Solutions. </h2>
                        <Link className='btn btn-primary' to="/contact">Connect with Hardware Experts!</Link>
                    </div>
                    </Col>
                    <Col xs={12} md={6} lg={7}>
                    
                    <div className="text-white">
                        <ul>
                            <li><b>25</b><small>Reduction in Development Time.</small></li>
                            <li><b>30</b><small>Reduction in Downtime.</small></li>
                            <li><b>50</b><small>Speed up data handling. </small></li>
                            <li><b>70</b><small>Increase in System Integration </small></li>
                            <li><b>85</b><small>Improvement in Product Quality</small></li>
                            <li><b>99.9</b><small>Ensure consistent performance.	</small></li>
                        </ul>
                    </div>
                    </Col>
                </Row>
            </Container>
        </div>
            <Container>
            <Row xs={1} md={2} lg={2} className='my-5 py-5 big_text_sticky'>
                <Col>
                
                <div className="big_title scroll-reveal sticky-top pt-5">
                    <h2 className='new_title mt-5 mx-auto'><span>Let's Transform Your Ideas into Reality! </span> </h2>
                </div>
                </Col>
                <Col>
                
                <div className="big_title scroll-reveal">
                    <p><span>Whether you're a startup with big dreams or an established business looking to improve your products, <b>Alcyone Technologies</b> is here to help you bring your tech ideas to life. Reach out to us now to discuss your embedded software development needs. Together, we'll harness the power of technology to transform your vision into reality. </span> </p>
                </div>
                </Col>
            </Row>
        </Container>
        <FAQ faqData={dataFaq}/>
    </div>
    </>
  )
}
