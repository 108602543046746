import axios from 'axios';
import { useFormik } from 'formik';
import React from 'react'
import { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';

import PageTopHead from '../../components/UI/PageTopHead';
import './Contact.css'
import { Helmet } from 'react-helmet';

export default function Contact() {
  const [project, setProject] = useState('');
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted ] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      visitorCompany: '',
      phone:'',
      email:''
    },
    // validate,
    onSubmit:(values, {resetForm}) => {
    console.log('values', values);
    console.log(project);
    let formData = new FormData();
    formData.append('name', values.name)
    formData.append('email', values.email)
    formData.append('mobile', values.phone)
    formData.append('discuss', project)
    formData.append('visitorCompany', values.visitorCompany)
    formData.append('type', 'consultation')
    axios.post('https://web.alcyone.in/api/contact', formData).then((res)=>{
      console.log(res);
      if(res.data.status === true){
          resetForm();
          // toast.success(res.data.message, { autoClose: 2000 });
          setIsSuccessfullySubmitted(true);
          // window.location.reload();
          setTimeout(()=>{
              setIsSuccessfullySubmitted(false);
          },3000)
      }else{
          toast.error(res.data.message, { autoClose: 2000 });
      }
  });
    },
  });
  
  return (
    <>
    <Helmet>
        <title>Reorganizing</title>
        <meta name="description" content="Let's Start Something Big Together" />
    </Helmet>
    <section className='inner_page pb-5'>
        <PageTopHead pageTitle="Reorganizing" description="Let's Start Something Big Together"/>
        <Container>
          <Row className=' my-5 mx-auto'>
            <Col md={12}>
              <form onSubmit={formik.handleSubmit}>
                <div className="form_box">
                    <h2 className='mb-4'>Hello, Team Alcyone!</h2>
                    <div className="cf_fr mb-4">
                        <div className="cf_txt">My name is</div>
                        <input type="text" id="visitorName" className="form-control" placeholder="YOUR NAME"
                          name="name"
                          onChange={formik.handleChange}
                          value={formik.values.name}
                          required
                        />
                        <div className="cf_txt">from</div>
                        <input type="text" id="visitorCompany" className="form-control" placeholder="WEBSITE OR COMPANY NAME"
                        name="visitorCompany"
                        onChange={formik.handleChange}
                        value={formik.values.visitorCompany}
                        />
                    </div>
                    <div className="cf_fr mb-4">
                        <div className="cf_txt">I'd like to</div>
                        <div className="project_fields">
                            <input type="radio" className="btn-check" id='redesign'
                             name="project"
                             onChange={(e)=>setProject(e.target.value)}
                             value="redesign"
                            />
                            <label className="btn" htmlFor="redesign">redesign</label>
                            <input type="radio" className="btn-check" id="redevelop"
                             name="project"
                             onChange={(e)=>setProject(e.target.value)}
                             value="redevelop"
                            />
                            <label className="btn" htmlFor="redevelop">redevelop</label>
                        </div>
                        <div className="cf_txt">our existing website or mobile app.</div>
                    </div>
                    <div className="cf_fr mb-4 cf_email_r">
                        <div className="cf_txt">Contact me back at</div>
                        <input type="text" id="phone" className="form-control cf_phone" placeholder="YOUR PHONE"
                            name="phone"
                            onChange={formik.handleChange}
                            value={formik.values.phone}
                        />
                        <div className="cf_txt">&</div>
                        <input type="email" id="email" className="form-control" placeholder="YOUR EMAIL"
                          name="email"
                          onChange={formik.handleChange}
                          value={formik.values.email}
                          required
                        />
                        <div className="cf_txt">.</div>
                    </div>
                    <button type="submit" className="btn btn-primary">Submit</button> 
                    {isSuccessfullySubmitted  && ( 
                                        <div className="text-white rounded mt-4 bg-success p-3">Superb! Alcyone team will connect with you soon. <b>A great discussion is on the horizon!</b></div>
                                    )}   
                </div>
                </form>
            </Col>
          </Row>
          <ToastContainer/>
        </Container>
        
    </section>
    </>
  )
}
