import React from 'react'
import { Container} from 'react-bootstrap'


export default function PageTopHeadPlain(props) {

  return (
    <div className='plain_top'>
        <div className='ip_page_title'>
        <Container className='container py-4'>
            <h1 className='ptt'>{props.pageTitle}</h1>
            <p className='ptst'>{props.description}</p>
        </Container>
      </div>
    </div>
  )
}
