import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap';
import Directors from '../components/Team/Directors';
import Team from '../components/Team/Team';
import PageTopHead from '../components/UI/PageTopHead';
import Title from '../components/UI/Title';
import DirectorsImg from '../assets/images/team/directors.png';
import TeamImg from '../assets/images/team/team_img.png';
import { IoTelescope,IoRocket,IoMedal } from "react-icons/io5";
import { Helmet } from 'react-helmet';

export default function About() {
  
  
  return (
    <>
    <Helmet>
      <title>About Us</title>
      <meta name="description" content="Alcyone Technologies is a global IT software firm that excels at designing and developing software solutions through an optimal mix of people, techniques, and resources." />
    </Helmet>
    <section className='inner_page pb-5'>
        <PageTopHead pageTitle="About Us" description="Alcyone Technologies is a global IT software firm that excels at designing and developing software solutions through an optimal mix of people, techniques, and resources."/>
        <Container>
          <Row className='w-75 my-5 mx-auto pt-5 about_aly'>
            <Col md={4} className="text-center">
              <Image src={DirectorsImg} className="mb-4 mx-auto sticky-top" fluid alt="" loading='lazy'/>
            </Col>
            <Col md={8}>
              <div className="our_story px-4">
                <p>In 2014, we started as three Tech Giant who wanted to help the businesses achieve their goals. We specialize in the world's best software technologies where you will get experienced engineers, designers, and advisors for solving your business challenges.</p>
                <p><strong>Here, we strongly believe that bringing innovations in the software industry is driving evolution for all businesses and start-ups.</strong></p>
                <p><strong>Till now, we have delivered over 1000+ projects to more than 150 clients worldwide, and we are overseeing the growing process.</strong></p>
                <p>As we are changing rapidly, we thrive to expand into more businesses, more capability, and more geographies, boosting our long-term growth and market position.</p>
              </div>
            </Col>
          </Row>
          <Row className='py-5'>
            <Col>
              <div className="mis_vis">
                  <div className="mv_box">
                    <div className="mv_icon"><IoTelescope/></div>
                    <div className="mv_destails">
                      <h4>Vision</h4>
                      <p>Alcyone is a result of the vision to create a company where creativity and innovation flourish.</p>
                    </div>
                  </div>
                  <div className="mv_box">
                    <div className="mv_icon"><IoRocket/></div>
                    <div className="mv_destails">
                      <h4>Mission</h4>
                      <p>Implementing the right strategies that help businesses to stay ahead of the competitive curve.</p>
                    </div>
                  </div>
                  <div className="mv_box">
                    <div className="mv_icon"><IoMedal/></div>
                    <div className="mv_destails">
                      <h4>Objective</h4>
                      <p>To provide our clients with extra-ordinary services and solutions to enhance customer experience.</p>
                    </div>
                  </div>
              </div>
            </Col>
          </Row>
          <Row className='py-5'>
            <Col md={6}>
              <div className="ab_box mt-3">
                <h5>Unparalleled Services</h5>
                <p>Our services are unique and insight-driven which helps our businesses to achieve digital transformation and cater to customer needs effectively. You will get to know more about our business-focused approach when you will work with us.</p>
              </div>
            </Col>
            <Col md={6}>
              <div className="ab_box mt-3">
                <h5>One-of-a Kind Product</h5>
                <p>For fitness and beauty centers, we offer a unique product club planner that streamlines your business activities, reduces manual efforts and paperwork, and adds charm.</p>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="team_grp_sec">
          <Container>
            <Row>
              <Col>
                <div className="team_grp_img text-center"><Image fluid src={TeamImg}  alt=''/></div>
              </Col>
            </Row>
          </Container>
        </div> 
        
        <Container>
          
          <Row className='py-5'>
              <Col md={12}><Title mainTitle="Meet Our Leadership"/></Col>
              <Col md={12}><Directors/></Col>
          </Row>
          <Row className='py-5'>
              <Col md={12}><Title mainTitle="People Behind Alcyone"/></Col>
              <Col md={12}><Team/></Col>
          </Row>
          <Row className='py-5 we_ensure'>
            <Col md={4}>
              <div className="ab_box mt-3">
                <h5>Better Collaboration</h5>
                <p>By connecting with our valuable Clients, we ensure smooth and hassle-free collaboration. Our experts connect with you in no time to understand your project and your specific business needs.</p>
              </div>
            </Col>
            <Col md={4}>
              <div className="ab_box mt-3">
                <h5>Process Automation</h5>
                <p>Our solution automates most of your business processes that can ultimately bring the best of productivity and preciseness to your business. Discuss the same with our professionals.</p>
              </div>
            </Col>
            <Col md={4}>
              <div className="ab_box mt-3">
                <h5>Accelerate Solution Delivery</h5>
                <p>We are proficient and specialize in delivering the best solutions, without delays. Our team understands how important it is to meet deadlines and keep our clients moving ahead.</p>
              </div>
            </Col>
          </Row>
        </Container>
    </section>
    </>
  )
}
