import React, { useEffect, useState } from 'react'
import {Row, Col, Image } from 'react-bootstrap';
import './Team.css';
import { FiPhoneCall, FiMail } from "react-icons/fi";

export default function Directors() {
  const [founder, setFounder] = useState([]);
  useEffect(() => {
    fetch("../../data/directors.json").then((res) => {
      res.json().then((resp) => {
        setFounder(resp.founders)
      })
    });
  },[])
  
  return (
    <Row className="direc_grp">
      {founder && founder.map(user => (
        <Col md={4} key={user.id}>
          
              
                <>
                <div className="director_box">
                    <div className='d_info'><h4>{user.name}</h4><small>{user.designation}</small></div>
                    <div className="d_img"><Image fluid src={user.url}/></div>
                    <div className='d_contact'>
                      <a href={`tel:+${user.phone}`}><FiPhoneCall/></a>
                      <a href={`mailto:${user.email}`}><FiMail/></a>
                    </div>
                </div>
                </>
              
            
        </Col>
        ))}
    </Row>
  )
}
