import React from 'react'
import { Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function CaseStudyCard({data}) {
  return (
    <div className='casestudy_card'>
      <div className="casestudy_info">
          <Link to={`/case_study/${data.slug}`} className='stretched-link'><h3 className="casestudy_title">{data.meta_title}</h3></Link>
        </div>
            <Image className='csc_bg' fluid src={`https://web.alcyone.in/${data.banner_section.banner_bg_image}`} alt={data}/>
        <div className="casestudy_img">
            <Image fluid src={`https://web.alcyone.in/${data.banner_section.banner_image}`} alt={data}/>
        </div>
        
    </div>
  )
}
