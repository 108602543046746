import React from 'react'
import {Image} from 'react-bootstrap'
import techLogo from "../../data/technologies.json";
import './Technologies.css'


export default function Technologies() {

  return (
    <div className='technologies'>
        <div className="tech_logo_box">
            
            {techLogo.techs.map((image, i) => (
                <div className="tech_item" key={i}>
                <Image src={`${image.url}`} alt={image.description} key={i} />
                </div>
            ))}
        </div>
    </div>
  )
}
