import React, { useEffect, useState } from 'react';
import './CaseStudies.css';
import './CaseStudiesMedia.css';
import { Col, Container, Image, Row } from 'react-bootstrap';
import CaseStudyCard from './CaseStudyCard';
import PageTopHeadPlain from '../../components/UI/PageTopHeadPlain';
import { Helmet } from 'react-helmet';

const CaseStudies = () => {
    const [Data, setData] = useState([]);
    const BaseUrl = 'https://web.alcyone.in/'

    useEffect(() => {
        fetch(`${BaseUrl}api/case-studies`)
            .then(res => res.json())
            .then(resp => {
                // console.log(parseResult[0].banner_section.title);
                setData(resp.result);
                // console.log(Data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    return (
        <>
        <Helmet>
        <title>Case Studies</title>
        <meta name="description" content="Future tech, simplified. Dive deep into the next generation of innovation with insightful articles and expert breakdowns." />
        </Helmet>
        <section className='inner_page cs_section'>
            <PageTopHeadPlain pageTitle="Case Studies" description="Future tech, simplified. Dive deep into the next generation of innovation with insightful articles and expert breakdowns."/>

            <Container>
                <Row sm={1} md={2} lg={2} className=''>
                    {Data.map((item) => (
                        <Col key={item.id}><CaseStudyCard data={item} /></Col>
                    ))
                    }
                </Row>
            </Container>
        </section>
        </>
    )
}

export default CaseStudies;
