import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import PageTopHead from '../../components/UI/PageTopHead'
import FlipCard from '../../components/FlipCard/FlipCard'
import './ServiceLayout.css'
import OwlCarousel from 'react-owl-carousel'; 
import '../../../node_modules/owl.carousel/dist/assets/owl.carousel.css';
import '../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css';
import IoTImg1 from '../../assets/images/services/iot/alcyone_IPD.jpg'
import IoTImg2 from '../../assets/images/services/iot/alcyone_IDM.jpg'
import IoTImg3 from '../../assets/images/services/iot/alcyone_ICS.jpg'
import IoTImg4 from '../../assets/images/services/iot/alcyone_IIS.jpg'
import IoTImg5 from '../../assets/images/services/iot/alcyone_IDA.jpg'
import IoTImg6 from '../../assets/images/services/iot/alcyone_IS.jpg'
import WhyIot from "../../data/why_iot.json";
import FAQ from '../../components/faq/FAQ'
import faqs from "../../data/faqs.json";
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

export default function IoT() {
    const options = {
        
        responsive: {
            0: {
                items: 1,
                // stagePadding: 50,
            },
            450: {
                items: 1,
                stagePadding: 50,
            },
            600: {
                items: 2,
                stagePadding: 50,
            },
            1000: {
                items: 4,
            },
            1600: {
                items: 5,
    
            }
        },
    };
    const dataFaq = faqs.iot_faq;
  return (
    <>
    <Helmet>
    <title>Internet of Things (IoT) Solutions Services Company| India & USA</title>
    <meta name="description" content="Transform your business with our innovative IoT solutions & services. Seamlessly connect devices, drive efficiency, & unlock new possibilities. Contact us now!" />
  </Helmet>
    <div className='inner_page pb-5'>
        <PageTopHead pageTitle="IoT Services & Solutions" description="Empower your business with cutting-edge IoT solutions and services for seamless connectivity, innovation, and success in the digital era. "/>
        <Container>
            <Row>
                <Col>
                <div className="big_title my-5 py-5 scroll-reveal">
                    <p><span>Alcyone leads the way in delivering advanced Internet of Things (IoT) solutions services to businesses worldwide. With our exceptional IoT development, integration, and consulting expertise, we empower organizations in India, the USA, and beyond to harness the potential of connected devices. Experience unparalleled innovation, efficiency, and growth with our transformative IoT solutions.</span></p>
                </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2 className='new_title text-center'>The Power of IoT Services</h2>
                </Col>
            </Row>
            <Row xs={1} md={2} lg={3} className='ser_cards_grid mt-4'>
                <Col>
                    <FlipCard title="IoT Platform Development" desc="Our experts specialize in developing robust and scalable IoT platforms tailored to your business needs. Whether it's a cloud-based solution, edge computing, or a hybrid approach, we ensure seamless connectivity and efficient data management." imageurl={IoTImg1} />
                </Col>
                <Col>
                    <FlipCard title="IoT Device Management" desc="Efficiently manage your fleet of IoT devices with our comprehensive device management solutions. We offer remote monitoring, firmware updates, configuration management, and troubleshooting services to ensure optimal performance and uptime. " imageurl={IoTImg2} />
                </Col>
                <Col>
                    <FlipCard title="IoT Connectivity Solutions" desc="We provide end-to-end connectivity solutions to enable seamless communication between devices, sensors, and applications. Whether it's cellular, Wi-Fi, Bluetooth, or other wireless technologies, we ensure reliable and secure connectivity for your IoT ecosystem. " imageurl={IoTImg3} />
                </Col>
                <Col>
                    <FlipCard title="IoT Integration Services" desc="Integrate your IoT infrastructure with existing systems, databases, and third-party applications to enhance operational efficiency and drive business insights. Our integration services ensure seamless data flow and interoperability across platforms. " imageurl={IoTImg4} />
                </Col>
                <Col>
                    <FlipCard title="IoT Data Analytics" desc="Unlock the power of data generated by your IoT devices with our advanced analytics solutions. We help you derive valuable insights, identify patterns, and make data-driven decisions to optimize operations, improve efficiency, and drive innovation. " imageurl={IoTImg5} />
                </Col>
                <Col>
                    <FlipCard title="IoT Security" desc="Safeguard your IoT ecosystem from potential threats with our robust security solutions. We implement multi-layered security measures, including authentication protocols, encryption, access controls, and vulnerability assessments, to protect your devices, data, and infrastructure. " imageurl={IoTImg6} />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div className="big_title mt-5 pt-5 mb-5">
                        <h2 className='new_title text-center'>All-in-one Solutions for IoT Challenges </h2>
                        <p>We empower businesses to overcome IoT challenges and maximize device connectivity with our expert solutions. </p>
                    </div>
                </Col>
            </Row>
            <Row xs={1} md={2} lg={2}>
                <Col>
                    <div className="white_card">
                        <h3>Security Solutions</h3>
                        <p>We offer robust security measures to protect IoT devices, networks, and data from cyber threats. Our solutions encompass encryption, authentication, access control, and vulnerability management.</p>
                    </div>
                </Col>
                <Col>
                    <div className="white_card">
                        <h3>Interoperability Solutions</h3>
                        <p>We develop middleware and APIs that enable seamless integration and communication between diverse IoT devices, protocols, and platforms. Our solutions ensure compatibility and interoperability across the IoT ecosystem. </p>
                    </div>
                </Col>
                <Col>
                    <div className="white_card">
                        <h3>Scalable Architecture</h3>
                        <p>Our team designs scalable and flexible architectures to accommodate the growing number of IoT devices and the massive influx of data. We leverage cloud computing and edge computing technologies to optimize data processing and storage. </p>
                    </div>
                </Col>
                <Col>
                    <div className="white_card">
                        <h3>Data Management and Analytics</h3>
                        <p>We provide comprehensive data management solutions, including data storage, processing, and analytics. Our expertise in advanced analytics techniques such as machine learning and artificial intelligence helps derive actionable insights from IoT data.</p>
                    </div>
                </Col>
                <Col>
                    <div className="white_card">
                        <h3>Connectivity Solutions</h3>
                        <p>We optimize connectivity for reliable and efficient communication between IoT devices. Our solutions encompass network infrastructure design, protocol selection, and optimization for low-latency and high-bandwidth requirements.</p>
                    </div>
                </Col>
                <Col>
                    <div className="white_card">
                        <h3>Compliance and Regulatory Assistance</h3>
                        <p>We assist businesses in navigating the complex landscape of IoT regulations and standards. Our solutions ensure compliance with industry-specific regulations related to data privacy, security, and ethical practices.</p>
                    </div>
                </Col>
                <Col>
                    <div className="white_card">
                        <h3>Talent and Skill Development</h3>
                        <p>We offer training and consultancy services to equip organizations with the necessary skills and knowledge to manage IoT challenges effectively. Our programs cover areas such as IoT technology, cybersecurity, data analytics, and network management. </p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div className="big_title mt-5 pt-5 mb-5">
                        <h2 className='new_title text-center'>Why Alcyone for IoT Solutions?</h2>
                        <p>We are a reliable partner for IoT solutions that help businesses harness the full potential of IoT and propel them forward in the digital era. </p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <OwlCarousel id='serCaro' className='owl-theme es_item_list industries_carousel' margin={8} nav dots={false}  {...options}>
                        {WhyIot.toolbox.map((item, key) => (
                            <div className="item" key={key}>
                                <div className="fea_ser_home_card">
                                    <Image src={`${item.url}`} alt={item.title} />
                                    <div className='fea_ser_home_card_details'>
                                        <div className="fsh_title">
                                            <h3>{item.title}</h3>
                                        </div>
                                        <p>{item.description}</p>
                                    </div>
                                    
                                </div>
                            </div>
                        ))}
                        
                    </OwlCarousel>
                </Col>
            </Row>
            
        </Container>
        <div className="percentage_sec bg_img01 py-5 mt-5">
            <Container>
                <Row  className='my-5 py-5'>
                    <Col xs={12} md={6} lg={5}>
                    
                    <div className="text-white info_block">
                        <h2>Seamlessly Connect Your Devices with Enhanced Security Through IoT Solutions. </h2>
                        <Link className='btn btn-primary' to="/contact">Talk to our IoT experts</Link>
                    </div>
                    </Col>
                    <Col xs={12} md={6} lg={7}>
                    
                    <div className="text-white">
                        <ul>
                            <li><b>35</b><small>Increase in Device Performance.</small></li>
                            <li><b>40</b><small>Reduction in Connectivity Costs.</small></li>
                            <li><b>65</b><small>Improved Customer Experience</small></li>
                            <li><b>70</b><small>Productivity surge for IT professionals.</small></li>
                            <li><b>99.9</b><small>Ensure seamless operation and scalability. </small></li>
                            <li><b>100</b><small>Guaranteed data integrity and security</small></li>
                        </ul>
                    </div>
                    </Col>
                </Row>
            </Container>
        </div>
        <Container>
            <Row xs={1} md={2} lg={2} className='my-5 py-5 big_text_sticky'>
                <Col>
                
                <div className="big_title scroll-reveal sticky-top pt-5">
                    <h2 className='new_title mt-5 mx-auto'><span>Unlock IoT Excellence with the Leading Solutions Provider</span> </h2>
                </div>
                </Col>
                <Col>
                
                <div className="big_title scroll-reveal">
                    <p><span>Are you ready to revolutionize your business with IoT Solutions? Embark on a journey of connectivity, efficiency, and innovation with Alcyone Technologies as your IoT Service Provider. </span> </p>
                    <p><span>Reach out to us today to begin your venture into digital transformation. Step into a future-proof, innovative, and secure IoT ecosystem. </span> </p>
                </div>
                </Col>
            </Row>
        </Container>
        {/* <Container className='expertise_in'>
            <Row>
                <Col>
                    <div className="big_title sticky-top mb-5">
                        <h2 className='new_title text-center'>How IoT Works?</h2>
                    </div>
                </Col>
            </Row>
              <Row>
                <Col>
                    <Tabs
                        defaultActiveKey="devices"
                        id="hiwIot"
                        className="mb-3"
                        >
                        <Tab eventKey="devices" title="Devices">
                            <Row xs={1} md={2} lg={2}>
                                <Col>
                                    <p className="p-5 fs-4">
                                    IoT devices are the physical objects that are connected to the internet. They can be anything from smartphones and laptops to wearables and industrial machines.
                                    </p>
                                </Col>
                                <Col>
                                    <ul className="list-group p-5 fs-6 list-group-flush">
                                        <li className="list-group-item">IoT Nodes</li>
                                        <li className="list-group-item">Edge Devices</li>
                                        <li className="list-group-item">IoT Endpoints</li>
                                    </ul>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="sensors" title="Sensors">
                            <Row xs={1} md={2} lg={2}>
                                <Col>
                                    <p className="p-5 fs-4">IoT devices collect data from their surroundings using sensors. This data can be anything from temperature and humidity readings to location data and heart rate measurements.
                                    </p>
                                </Col>
                                <Col>
                                    <ul className="list-group p-5 fs-6 list-group-flush">
                                    <li className="list-group-item">Transducers</li>
                                <li className="list-group-item">Environmental Detectors</li>
                                <li className="list-group-item">Data Capturing Modules</li>
                                <li className="list-group-item"><b></b></li>
                                    </ul>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="connectivity" title="Connectivity">
                            <Row xs={1} md={2} lg={2}>
                                <Col>
                                    <p className="p-5 fs-4">IoT devices need to be connected to the internet to transmit data. This can be done using Wi-Fi, cellular networks, or other wireless technologies.
                                    </p>
                                </Col>
                                <Col>
                                    <ul className="list-group p-5 fs-6 list-group-flush">
                                    <li className="list-group-item">Network Integration </li>
                                <li className="list-group-item">Communication Infrastructure </li>
                                <li className="list-group-item">Wireless Connectivity </li>
                                    </ul>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="cloud" title="Cloud">
                            <Row xs={1} md={2} lg={2}>
                                <Col>
                                    <p className="p-5 fs-4">The data collected by IoT devices are typically stored in the cloud. This allows it to be analyzed and processed more easily.
                                    </p>
                                </Col>
                                <Col>
                                    <ul className="list-group p-5 fs-6 list-group-flush">
                                    <li className="list-group-item">IoT Platform </li>
                                    <li className="list-group-item">Cloud Services </li>
                                    <li className="list-group-item">Data Processing and Storage Infrastructure</li>
                                    </ul>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="software" title="Software">
                            <Row xs={1} md={2} lg={2}>
                                <Col>
                                    <p className="p-5 fs-4">IoT software is used to collect, store, and analyze the data collected by IoT devices. This data can then be used to make decisions or take action.
                                    </p>
                                </Col>
                                <Col>
                                    <ul className="list-group p-5 fs-6 list-group-flush">
                                <li className="list-group-item">IoT Applications </li>
                                <li className="list-group-item">Firmware </li>
                                <li className="list-group-item">Embedded Systems</li>
                                    </ul>
                                </Col>
                            </Row>
                        </Tab>
                    </Tabs>
                </Col>
              </Row>
            </Container> */}
        <FAQ faqData={dataFaq}/>
    </div>
    </>
  )
}
