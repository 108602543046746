import React from 'react'
import { Container, Nav, Navbar, NavDropdown,Image, Row, Col } from 'react-bootstrap'
import { Link } from "react-router-dom";
import styles from './Header.module.css'
import Logo from '../../assets/images/alcyone_logo.svg'
import { BsFillChatFill } from "react-icons/bs";


export default function Header() {
  

  return (
    <header className={styles.main}>
        <Navbar expand="lg" fixed="top" className='bg-white py-3' collapseOnSelect  >
            <Container>
                <Link to="/"><Image fluid src={Logo} alt="" /></Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className={`ms-auto ${styles.alc_nav}`}>
                    {/* <NavDropdown title="Services" id="basic-nav-dropdown" className="mega_dropq" >
                      <Container className='px-3 py-4'>
                        <Row className='g-5'>
                          <Col>
                            <NavDropdown.Item eventkey="11" as={Link} to="/services/PDM">Product Development and Maintenance</NavDropdown.Item>
                            <NavDropdown.Item eventkey="12" as={Link} to="/services/BigData">Big Data Analytics</NavDropdown.Item>
                            <NavDropdown.Item eventkey="13" as={Link} to="/services/Enterprises">Enterprises Solutions</NavDropdown.Item>
                            <NavDropdown.Item eventkey="17" as={Link} to="/services/Embedded">Embedded Software Development</NavDropdown.Item>
                            <NavDropdown.Item eventkey="18" as={Link} to="/services/DevelopmentCenter">Dedicated Development Center</NavDropdown.Item>
                            <NavDropdown.Item eventkey="19" as={Link} to="/services/Resource">Resource Augmentation</NavDropdown.Item>
                            <NavDropdown.Item eventkey="21" as={Link} to="/services/MobileApp">Mobile App Development</NavDropdown.Item>
                            <NavDropdown.Item eventkey="22" as={Link} to="/services/UIUX">UI/UX Design</NavDropdown.Item>
                            <NavDropdown.Item eventkey="23" as={Link} to="/services/QA">Testing and QA</NavDropdown.Item>
                          </Col>
                        </Row>
                      </Container>
                    </NavDropdown> */}
                   <NavDropdown title="Services" id="basic-nav-dropdown" className="mega_drop" >
                      <Container className='px-3 py-4'>
                        <Row className='g-5'>
                          <Col>
                            <strong className=' ps-3 mb-2 w-100 d-block pb-2'>Leading Services</strong>
                            <NavDropdown.Item eventkey="7" as={Link} to="/AI-Services">AI Marvels</NavDropdown.Item>
                            <NavDropdown.Item eventkey="8" as={Link} to="/IoT-Services">IoT Connectivity</NavDropdown.Item>
                            <NavDropdown.Item eventkey="9" as={Link} to="/Cloud-Services">Cloud Solutions</NavDropdown.Item>
                            <NavDropdown.Item eventkey="10" as={Link} to="/Embedded">Hardware Solutions</NavDropdown.Item>
                            <NavDropdown.Item eventkey="111" as={Link} to="/Machine-Learning">Machine Learning</NavDropdown.Item>
                          </Col>
                          <Col>
                            <strong className=' ps-3 mb-2 w-100 d-block pb-2'>Core Assistance</strong>
                            <NavDropdown.Item eventkey="11" as={Link} to="/services/PDM">Product Development and Maintenance</NavDropdown.Item>
                            <NavDropdown.Item eventkey="12" as={Link} to="/services/BigData">Big Data Analytics</NavDropdown.Item>
                            <NavDropdown.Item eventkey="13" as={Link} to="/services/Enterprises">Enterprises Solutions</NavDropdown.Item>
                            {/* <NavDropdown.Item eventkey="17" as={Link} to="/services/Embedded">Embedded Software Development</NavDropdown.Item> */}
                            <NavDropdown.Item eventkey="18" as={Link} to="/services/DevelopmentCenter">Dedicated Development Center</NavDropdown.Item>
                            <NavDropdown.Item eventkey="19" as={Link} to="/services/Resource">Resource Augmentation</NavDropdown.Item>
                          </Col>
                          <Col>
                            <strong className=' ps-3 mb-2 w-100 d-block pb-2'>Additional Offerings</strong>
                            {/* <NavDropdown.Item eventkey="20" as={Link} to="/services/MobileApp">Web App Development</NavDropdown.Item> */}
                            <NavDropdown.Item eventkey="21" as={Link} to="/services/MobileApp">Mobile App Development</NavDropdown.Item>
                            <NavDropdown.Item eventkey="22" as={Link} to="/services/UIUX">UI/UX Design</NavDropdown.Item>
                            <NavDropdown.Item eventkey="23" as={Link} to="/services/QA">Testing and QA</NavDropdown.Item>
                            {/* <NavDropdown.Item eventkey="24" as={Link} to="/services/QA">3D Modeling / Animation</NavDropdown.Item>
                            <NavDropdown.Item eventkey="24" as={Link} to="/services/QA">Digital Marketing</NavDropdown.Item> */}
                          </Col>
                        </Row>
                      </Container>
                    </NavDropdown>
                    {/* <Nav.Link as={Link}  eventkey="2" to="about">About</Nav.Link> */}
                    <NavDropdown title="About" id="basic-nav-dropdown" >
                      <Container className='px-3'>
                        <Row className='g-5'>
                          <Col>
                            <NavDropdown.Item eventkey="25" as={Link} to="/about">Company</NavDropdown.Item>
                            <NavDropdown.Item eventkey="5" as={Link} to="/career">Career</NavDropdown.Item>
                          </Col>
                        </Row>
                      </Container>
                    </NavDropdown>
                    <Nav.Link  as={Link}  eventkey="3" to="ourproduct">Our Product</Nav.Link>
                    <NavDropdown title="Work" id="basic-nav-dropdown" >
                      <Container className='px-3'>
                        <Row className='g-5'>
                          <Col>
                            {/* <NavDropdown.Item eventkey="25" as={Link} to="/case_studies">Case Studies</NavDropdown.Item> */}
                            <NavDropdown.Item eventkey="4" as={Link} to="/work">Workfolio</NavDropdown.Item>
                          </Col>
                        </Row>
                      </Container>
                    </NavDropdown>
                    <Nav.Link as={Link}  eventkey="24" to="blogs">Blogs</Nav.Link> 
                    {/* <Nav.Link  as={Link} eventkey="5"  to="career">Career</Nav.Link>*/}
                    <Link as={Link}  eventkey="6" to="contact" className='btn btn-primary'>Let's Talk <BsFillChatFill/></Link>
                </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </header>
  )
}
