import Layout from "./pages/Layout";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import NotFound from "./pages/NotFound";
import './assets/css/main.css'
import './custom.scss'
import Services from "./pages/services/Services";
import Work from "./pages/work/Work";
import Product from "./pages/product/Product";
import Facebook from "./pages/social/Facebook";
import LinkedIn from "./pages/social/LinkedIn";
import ScrollToTop from "./components/ScrollToTop";
import Career from "./pages/career/Career";
import Apply from "./pages/career/Apply";
import Contact from "./pages/contact/Contact";
import Rebrand from "./pages/contact/Rebrand";
import AiMarvels from "./pages/LeadingServices/AiMarvels";
import IoT from "./pages/LeadingServices/IoT";
import CloudServices from "./pages/LeadingServices/CloudServices";
import Machine from "./pages/LeadingServices/Machine";
import Hardware from "./pages/LeadingServices/Hardware";
import Blog from "./pages/blogs/Blog";
import BlogDetails from "./pages/blogs/BlogDetails";
import CaseStudies from "./pages/caseStudies/CaseStudies";
import CaseStudyDetails from "./pages/caseStudies/CaseStudyDetails";
import GenAIML from "./pages/LeadingServices/GenAIML";



function App() {


  return (
    <div className="App">
      <Layout>
      
          <ScrollToTop>
            <Routes>
              <Route path="*" element={<NotFound />} />
              <Route path="/" element={<Home />} />
              <Route path="services/:id" element={<Services />} />
              <Route path="about" element={<About />} />
              <Route path="AI-Services" element={<AiMarvels />} />
              <Route path="Generative-AI-ML" element={<GenAIML />} />
              <Route path="IoT-Services" element={<IoT />} />
              <Route path="Cloud-Services" element={<CloudServices />} />
              <Route path="Embedded" element={<Hardware />} />
              <Route path="Machine-Learning" element={<Machine />} />
              <Route path="ourproduct" element={<Product />} />
              <Route path="work" element={<Work />} />
              <Route path="career" element={<Career />} />
              <Route path="contact" element={<Contact />} />
              <Route path="rebrand" element={<Rebrand />} />
              <Route path="apply/:id" element={<Apply />} />
              <Route path="facebook" element={<Facebook />} />
              <Route path="linkedin" element={<LinkedIn />} />
              <Route path="blogs/" element={<Blog />} />
              <Route path="blog/:slug" element={<BlogDetails />} />
              <Route path="case_studies" element={<CaseStudies />} />
              <Route path="case_study/:slug" element={<CaseStudyDetails />} />
            </Routes>
          </ScrollToTop>
      </Layout>
    </div>
  );
}

export default App;
