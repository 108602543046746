import React from 'react'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'

export default function Layout(props) {
  return (
    <>
    <Header/>
      <main className='pb-5'>{props.children}</main>
       <Footer/> 
    </>
  )
}
