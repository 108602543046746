import React from 'react'
import './Graphics.css'

export default function EnterGraphics() {
  return (
    <>
   <svg id="enterprises_graphic" viewBox="0 0 600 540" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.1">
    <path d="M599.999 364.975L300.966 540L0 364.975L299.034 190.166L599.999 364.975Z" fill="url(#paint0_linear_2189_2490)"/>
    </g>
    <path d="M599.999 285.024V317.004L300.966 492.029V459.833L599.999 285.024Z" fill="#A1BAD2"/>
    <path d="M300.966 459.833V492.029L0 317.004V285.024L300.966 459.833Z" fill="#D0DCE8"/>
    <path d="M599.999 285.025L300.966 459.833L0 285.025L299.034 110L599.999 285.025Z" fill="url(#paint1_linear_2189_2490)"/>
    <g opacity="0.8">
    <path id="entrpsheet" d="M135.812 152.815L136.4 284.765L206.049 244.504L205.755 112.554L135.812 152.815Z" fill="#709DDA"/>
    <path d="M226.327 144.002L226.621 191.316L336.824 127.545V80.2305L226.327 144.002Z" fill="#709DDA"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M122.295 305.339L346.816 175.446L346.523 15.8711L122.001 145.471L122.295 305.339ZM205.756 244.801L136.107 285.062L135.813 153.111L205.462 112.85L205.756 244.801ZM336.825 127.838L226.327 191.609L226.033 144.001L336.531 80.2301L336.825 127.838ZM336.825 67.8872L226.327 131.658V101.095L336.531 37.3241L336.825 67.8872ZM337.118 168.981L226.621 232.752V203.07L337.118 139.299V168.981Z" fill="#C8DDED"/>
    <path d="M226.622 203.07V232.752L337.119 168.98L336.825 139.299L226.622 203.07Z" fill="#709DDA"/>
    <path d="M226.327 100.802V131.366L336.824 67.5944L336.53 37.0312L226.327 100.802Z" fill="#709DDA"/>
    <path d="M122.001 129.6V145.469L346.522 15.8693V0L122.001 129.6Z" fill="#709DDA"/>
    </g>
    <path id="entrpdot1" d="M129.055 136.946C129.055 139.591 131.112 140.766 133.463 139.297C135.814 137.827 137.577 134.595 137.577 131.95C137.577 129.305 135.52 128.423 133.463 129.599C131.112 131.068 129.055 134.301 129.055 136.946Z" fill="#FF66A8"/>
    <path id="entrpdot2" d="M139.635 130.776C139.635 133.42 141.692 134.596 144.043 133.127C146.394 131.951 148.157 128.718 148.157 126.073C148.157 123.135 146.394 122.253 144.043 123.429C141.692 124.898 139.635 128.131 139.635 130.776Z" fill="#4267C5"/>
    <path id="entrpdot1" d="M150.507 124.604C150.507 127.249 152.27 128.425 154.621 126.955C156.972 125.78 158.735 122.547 158.735 119.902C158.735 117.257 156.972 116.082 154.621 117.257C152.27 118.727 150.507 121.959 150.507 124.604Z" fill="#FFB420"/>
    <path id="entrpcolor2" d="M325.068 27.3292V29.9741L338.88 22.0394V19.3945L325.068 27.3292Z" fill="#4267C5"/>
    <path id="entrpcolor2" d="M325.068 22.6271V24.9781L338.88 17.0434V14.6924L325.068 22.6271Z" fill="#4267C5"/>
    <path id="entrpcolor2" d="M325.068 17.6319V20.2768L338.88 12.3421V9.69727L325.068 17.6319Z" fill="#4267C5"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M122.001 152.816L346.816 22.9224V0L122.001 129.893V152.816ZM144.042 133.126C141.691 134.302 139.633 133.42 139.633 130.775C139.633 128.13 141.691 124.898 143.748 123.428C146.099 121.959 148.156 123.134 148.156 125.779C148.156 128.424 146.099 131.657 144.042 133.126ZM133.168 139.297C131.111 140.473 129.054 139.591 129.054 136.653C129.054 134.008 130.817 130.775 133.168 129.6C135.519 128.13 137.576 129.306 137.576 131.951C137.576 134.595 135.519 137.828 133.168 139.297ZM154.621 126.955C152.27 128.424 150.213 127.249 150.213 124.604C150.213 121.959 152.27 118.726 154.621 117.257C156.678 116.081 158.735 116.963 158.735 119.608C158.735 122.253 156.972 125.485 154.621 126.955ZM338.882 12.0489L325.069 19.9836V17.6326L338.882 9.69793V12.0489ZM338.882 21.7469L325.069 29.6815V27.0366L338.882 19.102V21.7469ZM338.882 17.0448L325.069 24.9795V22.3346L338.588 14.3999L338.882 17.0448Z" fill="#85A9FF"/>
    <g opacity="0.8">
    <path id="entrpsheet" d="M214.866 178.972L215.16 285.355L271.29 253.029L270.996 146.646L214.866 178.972Z" fill="#709DDA"/>
    <path d="M287.747 171.622L288.041 210.12L377.086 158.691L376.792 120.193L287.747 171.622Z" fill="#709DDA"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M203.995 301.81L385.022 197.19L384.729 68.4717L203.407 173.092L203.995 301.81ZM271.586 253.026L215.456 285.353L215.162 178.969L271.292 146.349L271.586 253.026ZM377.088 158.986L288.337 210.12L288.043 171.916L377.088 120.488V158.986ZM377.088 110.496L288.043 161.924L287.749 137.239L376.794 85.8104L377.088 110.496ZM377.382 192.194L288.337 243.622V219.818L377.382 168.39V192.194Z" fill="#C8DDED"/>
    <path d="M288.041 219.526V243.33L377.086 191.902V168.098L288.041 219.526Z" fill="#709DDA"/>
    <path id="entrpdownmove" d="M287.749 136.945V161.631L376.794 110.202V85.5166L287.749 136.945Z" fill="#709DDA"/>
    <path d="M203.406 160.456V173.093L384.728 68.4726V55.8359L203.406 160.456Z" fill="#709DDA"/>
    </g>
    <path id="entrpdot2" d="M209.284 166.038C209.284 168.389 211.047 169.271 212.81 168.095C214.574 166.92 216.043 164.275 216.043 162.218C216.043 160.161 214.574 159.279 212.81 160.161C211.047 161.336 209.284 163.981 209.284 166.038Z" fill="#FF66A8"/>
    <path id="entrpdot1" d="M217.804 161.338C217.804 163.395 219.568 164.276 221.331 163.101C223.094 161.925 224.857 159.574 224.857 157.223C224.857 155.166 223.094 154.285 221.331 155.46C219.568 156.342 217.804 158.987 217.804 161.338Z" fill="#4267C5"/>
    <path id="entrpdot2" d="M226.624 156.34C226.624 158.397 228.093 159.278 229.856 158.103C231.913 157.221 233.383 154.576 233.383 152.225C233.383 150.168 231.913 149.287 229.856 150.462C228.093 151.344 226.624 153.989 226.624 156.34Z" fill="#FFB420"/>
    <path id="entrpcolor3" d="M367.389 77.8764V79.9335L378.557 73.4683V71.4111L367.389 77.8764Z" fill="#4267C5"/>
    <path id="entrpcolor3" d="M367.389 73.7632V75.8203L378.557 69.6489V67.5918L367.389 73.7632Z" fill="#4267C5"/>
    <path id="entrpcolor3" d="M367.389 69.9409V71.998L378.557 65.5327V63.4756L367.389 69.9409Z" fill="#4267C5"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M203.701 178.97L385.022 74.3502V55.8359L203.701 160.456V178.97ZM221.333 163.101C219.57 164.276 217.807 163.395 217.807 161.044C217.807 158.987 219.276 156.342 221.333 155.166C223.096 154.285 224.566 155.166 224.566 157.223C224.566 159.28 223.096 161.925 221.333 163.101ZM212.811 168.097C210.754 168.978 209.284 168.097 209.284 166.04C209.284 163.982 210.754 161.338 212.811 160.162C214.574 159.28 216.043 159.868 216.043 162.219C216.043 164.276 214.574 166.921 212.811 168.097ZM229.856 158.105C228.092 159.28 226.623 158.399 226.623 156.048C226.329 153.991 228.092 151.346 229.856 150.464C231.619 149.289 233.382 150.17 233.382 152.227C233.382 154.285 231.619 156.929 229.856 158.105ZM378.557 65.5339L367.39 71.9991V69.942L378.557 63.4767V65.5339ZM378.557 73.4685L367.39 79.6399V77.5828L378.557 71.4114V73.4685ZM378.557 69.3543L367.39 75.8195V73.7624L378.557 67.2971V69.3543Z" fill="#7392DC"/>
    <path opacity="0.1" d="M295.093 352.636C273.052 365.273 273.052 386.138 295.386 399.069C317.427 411.706 353.574 411.706 375.615 399.069C397.655 386.138 397.362 365.273 375.321 352.636C352.986 339.706 317.133 339.706 295.093 352.636Z" fill="black"/>
    <path d="M340.349 364.391C340.055 364.391 323.598 363.803 327.418 369.387C329.769 373.207 333.002 373.795 335.353 373.795C337.704 373.795 342.406 372.619 343.288 373.207C344.463 373.795 350.047 377.615 352.986 373.795C353.867 373.207 354.161 371.15 353.867 369.681C353.28 368.505 342.112 364.097 340.349 364.391Z" fill="#5BB0FF"/>
    <path d="M327.419 369.387C329.77 373.207 333.003 373.795 335.354 373.795C337.705 373.795 342.407 372.62 343.289 373.207C344.464 373.795 350.048 377.615 352.986 373.795C353.574 373.501 353.868 372.62 353.868 371.738C351.811 374.971 346.227 374.089 344.758 373.207C342.995 372.032 340.644 371.444 338.587 371.738C336.236 372.326 331.827 371.738 329.476 369.681C328.007 368.211 328.007 367.036 328.301 365.86C326.831 366.742 326.244 367.918 327.419 369.387Z" fill="#3D93FF"/>
    <path d="M316.84 374.971C305.379 373.207 293.036 375.265 297.445 381.142C300.383 384.963 304.498 385.844 307.73 385.844C310.963 385.844 316.547 384.375 318.016 384.962C319.191 385.55 326.244 389.371 330.359 385.55C331.24 384.669 332.416 381.436 331.828 379.967C331.24 378.497 319.191 375.265 316.84 374.971Z" fill="#5BB0FF"/>
    <path d="M331.24 251.838C331.24 251.838 330.064 271.527 328.007 292.686C325.95 313.845 336.823 350.58 341.231 367.919C342.995 374.972 354.162 372.621 354.162 369.976C354.456 361.16 354.75 364.98 352.986 341.47C350.929 315.021 348.284 317.372 346.521 309.437C345.052 302.678 351.811 282.695 351.811 282.695C360.921 259.184 352.105 241.846 352.105 241.846C345.346 248.017 331.24 251.838 331.24 251.838Z" fill="#223A7A"/>
    <path d="M309.493 257.127C309.493 257.127 305.084 280.931 303.027 302.09C300.97 323.543 314.488 361.747 317.133 379.379C318.015 384.669 332.121 384.375 332.415 380.555C333.59 370.857 332.709 367.037 330.946 351.755C328.301 327.657 325.656 328.539 324.186 320.604C322.717 312.963 333.003 290.335 333.003 290.335C346.815 270.351 341.819 251.837 341.819 251.837C332.415 256.833 309.493 257.127 309.493 257.127Z" fill="#2C4481"/>
    <path d="M297.443 381.143C300.381 384.964 304.496 385.845 307.728 385.845C310.961 385.845 316.545 384.376 318.014 384.964C319.189 385.552 326.242 389.372 330.357 385.552C330.651 384.964 331.532 383.788 331.532 382.907C328.887 386.139 321.54 385.845 319.777 384.67C317.426 383.494 314.487 382.907 311.549 383.494C308.904 384.082 303.026 383.494 300.087 381.437C298.324 379.968 298.03 377.323 298.324 376.441C296.561 377.323 296.267 379.38 297.443 381.143Z" fill="#3D93FF"/>
    <path d="M303.029 302.384C300.972 323.543 314.49 361.747 317.135 379.379C317.429 381.142 319.192 382.318 321.249 382.906C318.016 365.861 309.2 323.543 308.025 317.665C306.555 310.318 305.968 278.579 314.196 276.228C322.718 273.877 333.886 259.478 333.886 259.478C333.886 259.478 332.123 257.714 330.065 255.363C320.367 257.127 309.494 257.127 309.494 257.127C309.494 257.127 305.086 280.931 303.029 302.09V302.384Z" fill="#223A7A"/>
    <path id="entrpwomanhand" d="M318.602 131.936C318.602 131.936 316.839 121.651 310.961 120.475C305.084 119.006 303.908 119.887 305.671 122.238C307.141 124.589 311.549 131.055 310.373 130.761C308.904 130.761 306.847 128.998 306.553 130.173C306.259 131.349 313.606 136.051 313.606 136.051H319.777L318.602 131.936Z" fill="#FFAA83"/>
    <path id="entrpwomanhand" d="M330.066 161.323C325.951 155.446 320.662 134.287 320.662 134.287C316.547 132.523 311.258 136.05 311.258 136.05C311.258 136.05 315.372 160.442 318.898 167.495C321.543 173.078 333.592 186.597 333.592 186.597C333.592 186.597 346.523 203.348 353.576 195.707C360.335 188.066 352.694 181.895 352.694 181.895C351.813 180.425 334.768 167.495 330.066 161.323Z" fill="#ECB00E"/>
    <path d="M308.022 253.6C305.377 265.649 328.006 263.592 333.002 262.417C362.096 256.539 355.63 247.723 351.222 236.85C346.814 225.976 350.341 222.743 350.928 218.923C351.516 214.221 356.512 200.115 356.218 189.535C355.924 179.25 344.463 178.956 325.655 185.715C306.553 192.474 309.198 203.641 309.198 203.641C311.255 216.278 311.549 238.319 308.022 253.6Z" fill="#FEBA02"/>
    <path d="M326.828 173.371C326.828 173.371 328.885 181.306 325.065 186.008C323.008 188.653 334.469 190.416 341.228 186.89C341.228 186.89 338.583 174.547 343.579 169.551L326.828 173.371Z" fill="#FFAA83"/>
    <path d="M326.241 184.539C336.233 186.302 337.702 178.074 337.702 178.074L336.82 179.249L335.351 176.898H335.057C332.119 178.368 327.416 178.956 327.416 178.956C327.123 182.188 326.241 184.539 326.241 184.539Z" fill="#F79F7C"/>
    <path id="entrpwomenhair" d="M325.653 143.396C325.653 143.396 318.894 144.866 317.425 155.152C315.955 164.556 315.661 175.723 320.364 179.543C325.066 183.951 340.053 179.837 345.931 167.788C351.514 155.739 342.992 138.107 325.653 143.396Z" fill="#FFAA83"/>
    <path d="M315.369 153.683C315.075 155.152 316.545 155.446 319.483 156.621C323.891 158.385 329.181 158.679 329.769 161.617C330.357 164.556 326.243 173.666 335.94 176.899C343.287 179.25 336.528 191.005 343.581 180.719C345.932 177.487 352.691 162.499 347.108 150.45C347.108 150.45 342.993 141.634 331.826 141.634C331.826 141.634 318.896 141.928 315.369 153.683Z" fill="#3A2528"/>
    <path d="M334.47 164.851C333.001 163.382 330.356 163.675 328.299 165.733C326.242 167.79 325.36 170.729 326.829 172.198C328.299 173.667 330.944 173.373 333.001 171.316C335.058 169.259 335.646 166.32 334.47 164.851Z" fill="#FFAA83"/>
    <path id="entrpwomenhair" d="M338.88 180.719C338.88 180.719 337.705 190.123 336.236 197.176C335.06 203.347 337.411 215.69 342.113 221.861C342.113 221.861 351.223 202.172 344.758 180.719H338.88Z" fill="#3A2528"/>
    <path d="M341.818 178.076C339.467 178.076 337.704 178.664 337.704 179.839C337.704 180.721 339.467 181.309 341.818 181.309C343.875 181.309 345.639 180.721 345.639 179.546C345.639 178.664 343.875 178.076 341.818 178.076Z" fill="#AECFFF"/>
    <path id="entrpwomanhand" d="M262.476 186.302L265.415 180.425C265.415 180.425 264.533 172.196 263.358 171.609C262.476 171.315 262.77 174.253 262.182 175.429C261.889 176.604 258.068 169.845 256.893 167.2C255.423 164.556 254.248 165.143 252.778 171.021C251.309 176.898 259.538 183.364 259.538 183.364L262.476 186.302Z" fill="#FFAA83"/>
    <path id="entrpwomanhand" d="M290.1 191.299C283.34 189.829 266.002 177.486 266.002 177.486C261.887 179.25 259.83 185.421 259.83 185.421C259.83 185.421 278.051 200.703 285.398 203.935C290.981 205.992 308.614 207.756 308.614 207.756C308.614 207.756 329.185 211.576 329.773 200.703C330.361 190.711 320.369 191.299 320.369 191.299C318.9 190.711 297.74 193.062 290.1 191.299Z" fill="#ECB00E"/>
    <g opacity="0.1">
    <path d="M485.653 293.128C497.33 300.026 497.33 310.638 485.653 317.535C473.976 324.433 454.869 324.433 443.722 317.535C432.046 310.638 432.046 300.026 443.722 293.128C455.399 286.231 473.976 286.231 485.653 293.128Z" fill="black"/>
    </g>
    <path d="M471.323 297.903C478.754 302.148 478.223 308.515 471.323 312.76C464.423 317.004 452.746 317.004 445.316 312.76C438.416 308.515 438.416 302.148 445.846 297.903C452.746 293.659 464.423 293.659 471.323 297.903Z" fill="#24126A"/>
    <path d="M432.577 258.109H484.592L476.631 306.393H440.539L432.577 258.109Z" fill="#24126A"/>
    <path d="M477.16 248.559C487.245 254.395 487.245 263.946 476.63 270.313C466.545 276.149 450.091 276.149 440.007 270.313C429.922 263.946 429.922 254.395 440.007 248.559C450.091 242.723 467.076 242.723 477.16 248.559Z" fill="#E3F1FE"/>
    <path d="M473.978 250.682C482.47 255.457 482.47 263.416 473.978 268.191C465.486 272.966 451.686 272.966 443.193 268.191C434.701 263.416 434.701 255.457 443.193 250.682C451.686 245.376 465.486 245.376 473.978 250.682Z" fill="#C3DFF4"/>
    <path d="M473.977 268.19C465.485 272.965 451.685 272.965 443.192 268.19C440.716 266.775 438.946 265.183 437.885 263.415C438.416 260.762 440.539 258.109 443.723 256.517C452.215 251.742 464.954 251.742 472.915 256.517C476.631 258.109 478.223 260.762 478.754 263.415C478.046 265.183 476.454 266.775 473.977 268.19Z" fill="#343434"/>
    <path id="entrp_leafsvg" d="M456.991 267.131C444.252 266.6 461.237 249.621 467.075 236.357C472.914 223.623 480.875 219.378 491.49 217.255C501.575 215.664 486.714 221.5 486.714 240.071C486.714 247.499 473.444 241.662 473.444 251.213C473.975 261.294 469.198 262.886 465.483 265.539C461.768 268.192 456.991 267.131 456.991 267.131Z" fill="#1DAD93"/>
    <path id="entrp_leafsvg" d="M459.115 266.598C444.784 269.251 441.069 255.456 435.23 249.089C418.246 231.049 424.084 223.09 423.554 215.132C422.492 207.173 428.331 197.622 432.577 207.173C435.761 214.07 444.784 210.356 442.13 228.927C441.069 239.008 451.684 239.008 451.684 239.008C447.969 252.803 473.446 263.415 459.115 266.598Z" fill="#147866"/>
    <path id="entrp_leafsvg" d="M426.208 223.621H426.739C426.739 223.621 428.331 227.865 432.046 228.927C427.8 221.498 427.8 209.826 427.8 209.295H428.331C428.331 209.826 428.331 215.662 429.923 222.029C432.046 219.376 431.516 216.193 431.516 216.193H432.046C432.046 216.193 432.577 219.907 429.923 222.56C430.985 225.212 432.046 228.396 433.639 230.518C434.346 231.58 435.231 232.818 436.293 234.232C438.946 237.416 442.131 241.661 444.785 245.905C445.846 244.844 444.785 241.661 444.254 240.599H444.785C444.785 241.13 446.377 244.844 445.316 246.436C449.031 252.272 452.215 258.639 451.685 263.415H451.154C451.685 257.578 446.908 249.619 442.131 242.722C435.762 242.191 433.108 239.008 433.108 239.008L433.639 238.477C433.639 238.477 435.762 241.661 441.6 242.191C439.477 239.008 437.354 236.355 435.762 234.232C434.7 232.818 433.816 231.756 433.108 231.049C432.754 230.341 432.4 229.811 432.046 229.457C427.8 228.927 426.208 224.151 426.208 223.621Z" fill="#21C1A5"/>
    <path id="entrp_leafsvg" d="M464.953 249.621C463.892 248.029 466.015 244.845 466.015 244.315L466.545 244.845C466.015 245.907 464.953 248.029 464.953 249.09C468.669 245.907 471.853 242.723 475.038 240.601C476.099 239.54 476.984 238.655 477.692 237.948C479.284 236.356 480.876 234.234 481.938 231.581C480.345 228.928 481.407 225.744 481.407 225.744L481.938 226.275C481.938 226.275 480.876 228.928 482.468 231.05C484.591 225.744 485.653 220.969 485.653 220.438L486.184 220.969C486.184 220.969 484.061 231.05 479.284 236.887C482.999 236.356 484.591 233.173 484.591 233.173H485.122C485.122 233.703 482.999 237.417 478.753 237.417C478.753 237.771 478.576 238.125 478.222 238.478C477.161 239.186 476.099 240.07 475.038 241.131C473.268 242.546 471.322 244.315 469.199 246.437C473.976 246.968 476.099 244.845 476.63 244.845V245.376C476.63 245.376 474.507 246.968 470.792 246.968C470.084 246.968 469.376 246.968 468.669 246.968C462.83 252.274 457.522 258.11 456.992 263.416L456.461 262.885C456.992 259.171 460.707 254.396 464.953 249.621Z" fill="#21C1A5"/>
    <path id="entrp_leafsvg" d="M454.338 267.13C433.638 263.946 452.746 240.07 449.03 227.867C445.315 216.194 474.507 184.889 472.384 194.97C470.792 203.99 477.692 206.113 472.384 213.541C467.076 220.438 466.546 222.03 471.853 236.886C476.63 251.212 467.607 263.946 459.646 267.13C459.115 267.13 455.4 267.66 454.338 267.13Z" fill="#21C1A5"/>
    <path id="entrp_leafsvg" d="M456.461 249.09H456.992C456.992 249.621 455.399 255.988 461.238 258.11C463.361 250.682 462.299 242.723 460.707 235.295C455.399 233.703 455.399 226.275 455.399 226.275H455.93C455.93 226.275 455.93 232.642 460.707 234.764C460.176 229.989 459.115 226.275 459.115 222.561C459.115 220.438 459.292 218.67 459.646 217.255C456.461 213.541 458.053 207.704 458.053 207.704H458.584C458.584 207.704 456.992 213.541 459.646 216.724C461.238 206.643 467.607 200.807 467.607 200.807L468.138 201.337C468.138 201.337 460.176 208.765 459.646 222.03C465.484 219.377 464.953 214.071 464.953 213.541H465.484C465.484 213.541 466.015 219.377 459.646 222.561C460.176 226.275 460.707 229.989 461.238 234.234C461.769 238.478 462.83 242.192 462.83 246.437C466.015 244.845 464.953 235.825 464.953 235.825H465.484C465.484 236.356 467.076 245.376 462.83 246.968C463.361 253.865 461.769 260.763 456.992 267.66L456.461 267.13C458.584 264.3 460.176 261.47 461.238 258.64C454.338 255.988 456.461 249.09 456.461 249.09Z" fill="#147866"/>
    <defs>
    <linearGradient id="paint0_linear_2189_2490" x1="0" y1="181.523" x2="599.999" y2="181.523" gradientUnits="userSpaceOnUse">
    <stop stopColor="#CCCCCC"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint1_linear_2189_2490" x1="0" y1="101.357" x2="599.999" y2="101.357" gradientUnits="userSpaceOnUse">
    <stop stopColor="#F3F5F8"/>
    <stop offset="1" stopColor="#A1BAD2"/>
    </linearGradient>
    </defs>
    </svg>
    </>
  )
}
