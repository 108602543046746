import React from 'react'
import './Graphics.css'

export default function BigDataGraphics() {
  return (
    <>
    <svg id="bigdata_graphic" width="600" height="540" viewBox="0 0 600 540" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.1">
    <path d="M599.999 364.975L300.966 540L0 364.975L299.034 190.166L599.999 364.975Z" fill="url(#paint0_linear_2249_152)"/>
    </g>
    <path d="M599.999 285.024V317.004L300.966 492.029V459.833L599.999 285.024Z" fill="#A1BAD2"/>
    <path d="M300.966 459.833V492.029L0 317.004V285.024L300.966 459.833Z" fill="#D0DCE8"/>
    <path d="M599.999 285.025L300.966 459.833L0 285.025L299.034 110L599.999 285.025Z" fill="url(#paint1_linear_2249_152)"/>
    <path d="M138.501 225.296V214.623C138.501 218.805 141.278 223.018 146.833 226.201V236.873C141.247 233.659 138.501 229.477 138.501 225.296Z" fill="#FCCF3A"/>
    <path d="M146.832 226.201V236.873C141.246 233.659 138.5 229.477 138.5 225.296V214.623C138.5 218.805 141.277 223.018 146.832 226.201Z" fill="#FCCF3A"/>
    <path d="M594.991 263.058V273.73C594.959 277.881 592.244 282 586.814 285.152V274.479C592.276 271.327 594.991 267.177 594.991 263.058Z" fill="#88B5EA"/>
    <path d="M594.99 263.059V273.731C594.99 273.731 594.959 273.887 594.959 273.981V263.308C594.959 263.308 594.99 263.152 594.99 263.059Z" fill="#88B5EA"/>
    <path d="M594.991 263.308V273.98C594.96 274.511 594.897 275.072 594.772 275.603V264.93C594.897 264.4 594.96 263.869 594.991 263.308Z" fill="#88B5EA"/>
    <path d="M594.804 264.931V275.603C594.679 276.134 594.523 276.664 594.305 277.195V266.522C594.523 265.992 594.679 265.461 594.804 264.931Z" fill="#88B5EA"/>
    <path d="M594.368 266.522V277.195C594.15 277.726 593.9 278.256 593.588 278.787V268.114C593.9 267.583 594.15 267.053 594.368 266.522Z" fill="#88B5EA"/>
    <path d="M593.618 268.114V278.787C593.274 279.349 592.9 279.91 592.463 280.472V269.799C592.931 269.238 593.305 268.676 593.618 268.114Z" fill="#88B5EA"/>
    <path d="M592.464 269.799V280.471C591.934 281.127 591.341 281.751 590.686 282.344V271.671C591.372 271.078 591.965 270.454 592.464 269.799Z" fill="#88B5EA"/>
    <path d="M590.747 271.671V282.344C589.717 283.28 588.531 284.154 587.158 284.965V274.292C588.562 273.481 589.748 272.576 590.747 271.671Z" fill="#88B5EA"/>
    <path d="M587.189 274.293V284.966C587.189 284.966 586.939 285.09 586.814 285.153V274.48C586.814 274.48 587.064 274.355 587.189 274.293Z" fill="#88B5EA"/>
    <path d="M586.844 274.509L586.812 285.181L429.032 376.866L429.063 366.194L586.844 274.509Z" fill="#88B5EA"/>
    <path d="M389.308 366.193L389.276 376.865L146.833 236.873V226.2L389.308 366.193Z" fill="#88B5EA"/>
    <path d="M429.065 366.194V376.867C418.08 383.233 400.292 383.233 389.308 376.867V366.194C400.324 372.56 418.111 372.56 429.065 366.194Z" fill="#88B5EA"/>
    <path d="M429.066 366.194V376.867C427.162 377.959 425.071 378.864 422.887 379.582V368.909C425.134 368.192 427.193 367.287 429.066 366.194Z" fill="#88B5EA"/>
    <path d="M422.887 368.91V379.583C421.14 380.144 419.361 380.581 417.551 380.893V370.221C419.423 369.878 421.202 369.441 422.887 368.91Z" fill="#88B5EA"/>
    <path d="M417.582 370.22V380.892C416.365 381.111 415.179 381.267 413.962 381.392V370.719C415.21 370.594 416.396 370.438 417.582 370.22Z" fill="#88B5EA"/>
    <path d="M413.993 370.718V381.39C412.932 381.484 411.902 381.578 410.841 381.609V370.936C411.902 370.905 412.963 370.843 413.993 370.718Z" fill="#88B5EA"/>
    <path d="M410.873 370.938V381.611C409.874 381.642 408.938 381.642 407.971 381.611V370.938C408.969 370.938 409.905 370.938 410.873 370.938Z" fill="#88B5EA"/>
    <path d="M408.002 370.938V381.611C407.035 381.611 406.13 381.549 405.193 381.455V370.782C406.13 370.845 407.066 370.907 408.002 370.938Z" fill="#88B5EA"/>
    <path d="M405.224 370.814V381.487C404.288 381.424 403.351 381.299 402.478 381.175V370.502C403.414 370.627 404.319 370.72 405.224 370.814Z" fill="#88B5EA"/>
    <path d="M402.51 370.501V381.174C401.542 381.049 400.606 380.893 399.701 380.675V370.002C400.637 370.189 401.574 370.345 402.51 370.501Z" fill="#88B5EA"/>
    <path d="M399.732 370.001V380.674C398.702 380.455 397.735 380.237 396.768 379.956V369.283C397.766 369.564 398.734 369.782 399.732 370.001Z" fill="#88B5EA"/>
    <path d="M396.798 369.285V379.957C395.581 379.614 394.457 379.239 393.365 378.803V368.13C394.489 368.567 395.643 368.941 396.798 369.285Z" fill="#88B5EA"/>
    <path d="M393.365 368.129V378.802C391.899 378.24 390.557 377.585 389.277 376.867V366.194C390.588 366.912 391.93 367.567 393.365 368.129Z" fill="#88B5EA"/>
    <path d="M586.72 251.478C597.705 257.844 597.767 268.142 586.845 274.477L429.065 366.162C418.112 372.528 400.324 372.528 389.339 366.162L146.865 226.169C135.849 219.803 135.786 209.505 146.74 203.17L304.52 111.486C315.442 105.119 333.23 105.119 344.246 111.486L586.72 251.478Z" fill="#DFEDF7"/>
    <path d="M223.55 212.648L340.107 152.076C343.227 150.266 347.097 150.266 350.218 152.076L537.457 260.176C540.765 262.079 540.765 266.854 537.457 268.757L423.74 335.04C417.874 338.41 410.665 338.41 404.798 335.04L224.112 230.717C218.651 227.44 216.279 218.14 223.582 212.648H223.55Z" fill="#A3A3A3"/>
    <path d="M539.923 257.492V264.763L535.616 268.009L539.923 257.492Z" fill="#A3A3A3"/>
    <path d="M224.081 212.273L340.107 145.304C343.228 143.494 347.097 143.494 350.218 145.304L537.457 253.403C540.765 255.307 540.765 260.081 537.457 261.985L423.741 328.268C417.874 331.638 410.665 331.638 404.798 328.268L224.112 223.944C219.619 221.354 219.619 214.863 224.112 212.273H224.081Z" fill="#F7F5F7"/>
    <path d="M276.788 188.619L462.061 295.595C463.777 296.593 465.899 296.593 467.647 295.595L519.387 265.73C521.26 264.669 521.26 261.985 519.387 260.893L334.114 153.917C332.398 152.918 330.276 152.918 328.528 153.917L276.788 183.782C274.916 184.843 274.916 187.526 276.788 188.619Z" fill="#A3A3A3"/>
    <path d="M501.287 260.798L509.838 265.729C510.119 265.885 510.618 265.885 510.93 265.697L513.489 264.231C513.801 264.043 513.926 263.482 513.645 263.326L505.001 258.676C504.72 258.52 504.221 258.52 503.909 258.707L501.35 259.893C501.038 260.08 501.006 260.642 501.287 260.798Z" fill="#3F3F3F"/>
    <path d="M501.318 260.456L509.868 265.387C510.149 265.543 510.648 265.543 510.961 265.356L513.52 263.889C513.832 263.702 513.863 263.421 513.582 263.265L505.031 258.334C504.75 258.178 504.251 258.178 503.939 258.365L501.38 259.832C501.068 260.019 501.037 260.3 501.287 260.456H501.318Z" fill="#E8E8E8"/>
    <path d="M492.8 267.101L500.102 271.314C500.445 271.501 501.038 271.501 501.444 271.252L506.218 268.474C506.624 268.256 506.718 267.538 506.374 267.351L498.978 263.481C498.635 263.294 498.042 263.294 497.637 263.544L492.737 266.009C492.331 266.227 492.425 266.883 492.737 267.07L492.8 267.101Z" fill="#3F3F3F"/>
    <path d="M492.799 266.665L500.102 270.877C500.445 271.065 501.038 271.065 501.444 270.815L506.218 268.038C506.624 267.819 506.655 267.476 506.312 267.257L499.01 263.045C498.666 262.857 498.073 262.857 497.668 263.107L492.893 265.884C492.487 266.103 492.456 266.446 492.799 266.665Z" fill="#E8E8E8"/>
    <path d="M457.223 287.667L464.526 291.879C464.869 292.067 465.462 292.067 465.868 291.817L470.642 289.04C471.048 288.821 471.173 288.072 470.829 287.885L463.433 284.047C463.09 283.859 462.497 283.859 462.092 284.109L457.192 286.512C456.786 286.73 456.88 287.448 457.192 287.635L457.223 287.667Z" fill="#3F3F3F"/>
    <path d="M457.256 287.2L464.558 291.413C464.901 291.6 465.494 291.6 465.9 291.35L470.674 288.573C471.08 288.354 471.111 288.011 470.768 287.793L463.466 283.58C463.122 283.393 462.529 283.393 462.124 283.642L457.349 286.42C456.943 286.638 456.912 286.981 457.256 287.2Z" fill="#E8E8E8"/>
    <path d="M445.115 280.739L452.418 284.952C452.761 285.139 453.354 285.139 453.76 284.889L458.534 282.112C458.94 281.893 459.127 281.113 458.784 280.926L451.326 277.119C450.982 276.932 450.389 276.932 449.984 277.181L445.178 279.491C444.772 279.709 444.772 280.52 445.115 280.739Z" fill="#3F3F3F"/>
    <path d="M445.179 280.209L452.482 284.422C452.825 284.61 453.418 284.61 453.824 284.36L458.598 281.583C459.004 281.364 459.035 281.021 458.692 280.802L451.389 276.59C451.046 276.402 450.453 276.402 450.048 276.652L445.273 279.429C444.867 279.648 444.836 279.991 445.179 280.209Z" fill="#E8E8E8"/>
    <path d="M433.133 273.781L440.436 277.994C440.779 278.181 441.372 278.181 441.778 277.931L446.552 275.154C446.958 274.935 447.051 274.155 446.708 273.937L439.312 270.161C438.969 269.974 438.376 269.974 437.97 270.223L432.977 272.595C432.572 272.813 432.759 273.562 433.102 273.75L433.133 273.781Z" fill="#3F3F3F"/>
    <path d="M433.101 273.251L440.404 277.464C440.747 277.652 441.34 277.652 441.745 277.402L446.52 274.625C446.926 274.406 446.957 274.063 446.614 273.844L439.311 269.632C438.968 269.444 438.375 269.444 437.969 269.694L433.195 272.471C432.789 272.69 432.758 273.033 433.101 273.251Z" fill="#E8E8E8"/>
    <path d="M418.31 265.137L428.203 270.848C428.608 271.066 429.295 271.035 429.732 270.786L434.288 268.133C434.756 267.883 434.912 267.134 434.506 266.916L424.489 261.548C424.083 261.33 423.397 261.361 422.96 261.611L418.31 263.951C417.842 264.201 417.873 264.919 418.279 265.137H418.31Z" fill="#3F3F3F"/>
    <path d="M418.341 264.73L428.234 270.441C428.639 270.659 429.326 270.628 429.763 270.378L434.319 267.726C434.787 267.476 434.818 267.07 434.444 266.852L424.551 261.141C424.146 260.923 423.459 260.954 423.022 261.204L418.466 263.856C417.998 264.106 417.967 264.512 418.341 264.73Z" fill="#E8E8E8"/>
    <path d="M347.471 224.38L413.192 262.327C413.691 262.608 414.596 262.577 415.158 262.233L419.246 259.862C419.839 259.518 420.058 258.582 419.558 258.301L353.681 220.791C353.182 220.51 352.308 220.541 351.715 220.885L347.502 222.788C346.909 223.132 346.972 224.099 347.471 224.411V224.38Z" fill="#3F3F3F"/>
    <path d="M347.47 223.819L413.191 261.766C413.691 262.047 414.564 262.016 415.157 261.673L419.245 259.301C419.838 258.958 419.901 258.459 419.401 258.178L353.681 220.231C353.181 219.95 352.307 219.981 351.714 220.324L347.626 222.696C347.034 223.039 346.971 223.538 347.47 223.819Z" fill="#E8E8E8"/>
    <path d="M465.713 272.063L482.034 281.488C482.533 281.769 483.407 281.738 483.969 281.394L488.088 279.023C488.65 278.679 488.93 277.868 488.431 277.556L471.923 268.475C471.424 268.194 470.55 268.225 469.988 268.568L465.713 270.597C465.151 270.94 465.213 271.783 465.713 272.095V272.063Z" fill="#3F3F3F"/>
    <path id="bd_red" d="M465.743 271.565L482.064 280.99C482.563 281.271 483.437 281.239 483.999 280.896L488.118 278.524C488.68 278.181 488.742 277.682 488.243 277.401L471.922 267.977C471.422 267.696 470.549 267.727 469.987 268.07L465.868 270.442C465.306 270.785 465.244 271.285 465.743 271.565Z" fill="#E8E8E8"/>
    <path d="M453.168 275.092L473.015 286.545C473.577 286.857 474.513 286.826 475.137 286.452L479.069 284.174C479.693 283.799 479.943 282.957 479.381 282.613L459.347 271.473C458.785 271.16 457.849 271.192 457.224 271.566L453.23 273.501C452.606 273.875 452.575 274.749 453.136 275.061L453.168 275.092Z" fill="#3F3F3F"/>
    <path d="M453.23 274.594L473.077 286.046C473.639 286.358 474.575 286.327 475.199 285.953L479.131 283.675C479.756 283.3 479.818 282.77 479.287 282.458L459.44 271.005C458.878 270.693 457.942 270.724 457.318 271.098L453.386 273.376C452.762 273.751 452.699 274.281 453.23 274.594Z" fill="#E8E8E8"/>
    <path d="M476.852 268.259L490.958 276.404C491.426 276.685 492.237 276.653 492.768 276.341L497.043 273.876C497.574 273.564 497.667 272.565 497.199 272.316L483.062 264.701C482.594 264.42 481.783 264.452 481.253 264.764L476.852 266.886C476.322 267.198 476.384 268.009 476.852 268.29V268.259Z" fill="#3F3F3F"/>
    <path d="M476.916 267.728L491.021 275.872C491.489 276.153 492.301 276.122 492.831 275.81L497.107 273.345C497.637 273.033 497.699 272.565 497.231 272.284L483.126 264.139C482.658 263.858 481.847 263.889 481.316 264.201L477.041 266.667C476.51 266.979 476.448 267.447 476.916 267.728Z" fill="#E8E8E8"/>
    <path d="M480.723 260.082L488.025 264.294C488.368 264.482 488.961 264.482 489.367 264.232L494.142 261.455C494.547 261.236 494.703 260.581 494.36 260.394L486.933 256.462C486.59 256.274 485.997 256.274 485.591 256.524L480.754 258.989C480.348 259.208 480.348 259.863 480.692 260.05L480.723 260.082Z" fill="#3F3F3F"/>
    <path d="M480.723 259.707L488.026 263.919C488.369 264.107 488.962 264.107 489.368 263.857L494.142 261.08C494.548 260.861 494.579 260.518 494.236 260.299L486.933 256.087C486.59 255.899 485.997 255.899 485.592 256.149L480.817 258.926C480.411 259.145 480.38 259.488 480.723 259.707Z" fill="#E8E8E8"/>
    <path d="M468.646 253.246L475.948 257.458C476.292 257.646 476.885 257.646 477.29 257.396L482.065 254.619C482.471 254.4 482.596 253.589 482.252 253.402L474.856 249.626C474.513 249.438 473.92 249.438 473.514 249.688L468.615 252.06C468.209 252.278 468.303 253.027 468.615 253.214L468.646 253.246Z" fill="#3F3F3F"/>
    <path d="M468.645 252.717L475.947 256.93C476.291 257.117 476.884 257.117 477.289 256.868L482.064 254.09C482.47 253.872 482.501 253.529 482.158 253.31L474.855 249.097C474.512 248.91 473.919 248.91 473.513 249.16L468.739 251.937C468.333 252.156 468.302 252.499 468.645 252.717Z" fill="#E8E8E8"/>
    <path d="M464.589 261.142L471.891 265.355C472.235 265.542 472.827 265.542 473.233 265.293L478.008 262.515C478.413 262.297 478.569 261.548 478.226 261.361L470.768 257.522C470.425 257.335 469.832 257.335 469.426 257.585L464.558 260.019C464.152 260.237 464.214 260.924 464.558 261.111L464.589 261.142Z" fill="#3F3F3F"/>
    <path d="M464.621 260.674L471.923 264.887C472.266 265.074 472.859 265.074 473.265 264.825L478.04 262.047C478.445 261.829 478.476 261.486 478.133 261.267L470.831 257.054C470.488 256.867 469.895 256.867 469.489 257.117L464.714 259.894C464.309 260.113 464.277 260.456 464.621 260.674Z" fill="#E8E8E8"/>
    <path d="M453.416 264.98L460.718 269.193C461.061 269.38 461.654 269.38 462.06 269.13L466.835 266.353C467.24 266.135 467.396 265.417 467.053 265.198L459.626 261.36C459.283 261.173 458.69 261.173 458.284 261.422L453.385 263.857C452.979 264.075 453.072 264.762 453.385 264.949L453.416 264.98Z" fill="#3F3F3F"/>
    <path d="M453.418 264.513L460.72 268.726C461.063 268.913 461.656 268.913 462.062 268.664L466.836 265.886C467.242 265.668 467.273 265.325 466.93 265.106L459.628 260.893C459.285 260.706 458.692 260.706 458.286 260.956L453.511 263.733C453.106 263.951 453.074 264.295 453.418 264.513Z" fill="#E8E8E8"/>
    <path d="M440.778 267.914L448.081 272.126C448.424 272.314 449.017 272.314 449.422 272.064L454.197 269.287C454.603 269.068 454.728 268.35 454.384 268.163L446.988 264.294C446.645 264.106 446.052 264.106 445.646 264.356L440.716 266.728C440.31 266.946 440.435 267.695 440.778 267.882V267.914Z" fill="#3F3F3F"/>
    <path d="M440.779 267.446L448.081 271.659C448.425 271.846 449.017 271.846 449.423 271.596L454.198 268.819C454.603 268.6 454.635 268.257 454.291 268.039L446.989 263.826C446.646 263.639 446.053 263.639 445.647 263.888L440.873 266.666C440.467 266.884 440.436 267.227 440.779 267.446Z" fill="#E8E8E8"/>
    <path d="M428.733 260.862L436.036 265.075C436.379 265.262 436.972 265.262 437.378 265.012L442.152 262.235C442.558 262.016 442.62 261.361 442.308 261.143L434.944 257.242C434.6 257.055 434.007 257.055 433.602 257.304L428.671 259.77C428.265 259.988 428.39 260.643 428.733 260.831V260.862Z" fill="#3F3F3F"/>
    <path d="M428.671 260.487L435.973 264.7C436.316 264.887 436.909 264.887 437.315 264.637L442.089 261.86C442.495 261.641 442.526 261.298 442.183 261.08L434.881 256.867C434.537 256.68 433.944 256.68 433.539 256.929L428.764 259.707C428.359 259.925 428.327 260.268 428.671 260.487Z" fill="#E8E8E8"/>
    <path d="M416.626 253.934L423.928 258.147C424.272 258.334 424.864 258.334 425.27 258.085L430.045 255.307C430.45 255.089 430.544 254.402 430.201 254.215L422.836 250.314C422.493 250.127 421.9 250.127 421.494 250.377L416.532 252.779C416.127 252.998 416.251 253.716 416.595 253.903L416.626 253.934Z" fill="#3F3F3F"/>
    <path d="M416.593 253.497L423.896 257.709C424.239 257.897 424.832 257.897 425.238 257.647L430.012 254.87C430.418 254.651 430.449 254.308 430.106 254.09L422.803 249.877C422.46 249.689 421.867 249.689 421.462 249.939L416.687 252.716C416.281 252.935 416.25 253.278 416.593 253.497Z" fill="#E8E8E8"/>
    <path d="M404.517 246.975L411.819 251.188C412.162 251.375 412.755 251.375 413.161 251.126L417.935 248.348C418.341 248.13 418.466 247.381 418.123 247.194L410.696 243.355C410.352 243.168 409.759 243.168 409.354 243.418L404.485 245.82C404.08 246.039 404.142 246.757 404.485 246.944L404.517 246.975Z" fill="#3F3F3F"/>
    <path d="M404.516 246.537L411.819 250.749C412.162 250.937 412.755 250.937 413.16 250.687L417.935 247.91C418.341 247.691 418.372 247.348 418.029 247.13L410.726 242.917C410.383 242.729 409.79 242.729 409.384 242.979L404.61 245.756C404.204 245.975 404.173 246.318 404.516 246.537Z" fill="#E8E8E8"/>
    <path d="M392.438 240.015L399.74 244.228C400.084 244.415 400.677 244.415 401.082 244.166L405.857 241.388C406.263 241.17 406.356 240.421 406.013 240.234L398.617 236.395C398.274 236.208 397.681 236.208 397.275 236.458L392.407 238.829C392.001 239.048 392.032 239.797 392.407 239.984L392.438 240.015Z" fill="#3F3F3F"/>
    <path d="M392.439 239.547L399.741 243.76C400.085 243.947 400.678 243.947 401.083 243.698L405.858 240.92C406.264 240.702 406.295 240.359 405.952 240.14L398.649 235.927C398.306 235.74 397.713 235.74 397.307 235.99L392.533 238.767C392.127 238.986 392.096 239.329 392.439 239.547Z" fill="#E8E8E8"/>
    <path d="M380.362 233.056L387.665 237.269C388.008 237.456 388.601 237.456 389.006 237.207L393.781 234.429C394.187 234.211 394.312 233.431 393.968 233.243L386.572 229.436C386.229 229.249 385.636 229.249 385.231 229.499L380.269 231.933C379.863 232.151 379.988 232.838 380.331 233.025L380.362 233.056Z" fill="#3F3F3F"/>
    <path id="bd_red1" d="M380.363 232.587L387.665 236.8C388.009 236.988 388.601 236.988 389.007 236.738L393.782 233.96C394.187 233.742 394.219 233.399 393.875 233.18L386.573 228.967C386.23 228.78 385.637 228.78 385.231 229.03L380.457 231.807C380.051 232.026 380.02 232.369 380.363 232.587Z" fill="#E8E8E8"/>
    <path d="M368.286 226.036L375.588 230.249C375.932 230.436 376.525 230.436 376.93 230.186L381.705 227.409C382.111 227.19 382.235 226.441 381.892 226.254L374.496 222.416C374.153 222.228 373.56 222.228 373.154 222.478L368.224 224.943C367.818 225.162 367.943 225.817 368.286 226.004V226.036Z" fill="#3F3F3F"/>
    <path d="M368.285 225.599L375.587 229.812C375.93 229.999 376.523 229.999 376.929 229.75L381.704 226.972C382.109 226.754 382.141 226.41 381.797 226.192L374.495 221.979C374.152 221.792 373.559 221.792 373.153 222.042L368.378 224.819C367.973 225.037 367.942 225.381 368.285 225.599Z" fill="#E8E8E8"/>
    <path d="M356.177 219.108L363.48 223.321C363.823 223.508 364.416 223.508 364.822 223.258L369.596 220.481C370.002 220.263 370.127 219.451 369.783 219.264L362.356 215.488C362.013 215.301 361.42 215.301 361.014 215.55L356.146 217.922C355.741 218.141 355.772 218.889 356.146 219.077L356.177 219.108Z" fill="#3F3F3F"/>
    <path d="M356.21 218.64L363.512 222.853C363.855 223.04 364.448 223.04 364.854 222.791L369.628 220.013C370.034 219.795 370.065 219.451 369.722 219.233L362.42 215.02C362.076 214.833 361.484 214.833 361.078 215.083L356.303 217.86C355.898 218.078 355.866 218.422 356.21 218.64Z" fill="#E8E8E8"/>
    <path d="M344.132 212.149L351.434 216.362C351.777 216.549 352.37 216.549 352.776 216.299L357.55 213.522C357.956 213.304 358.112 212.555 357.769 212.367L350.342 208.529C349.998 208.342 349.405 208.342 349 208.591L344.1 210.963C343.695 211.182 343.788 211.93 344.1 212.149H344.132Z" fill="#3F3F3F"/>
    <path id="bd_red" d="M344.132 211.649L351.434 215.862C351.777 216.049 352.37 216.049 352.776 215.799L357.55 213.022C357.956 212.804 357.987 212.46 357.644 212.242L350.342 208.029C349.998 207.842 349.405 207.842 349 208.091L344.225 210.869C343.819 211.087 343.788 211.43 344.132 211.649Z" fill="#E8E8E8"/>
    <path d="M332.179 215.518L342.384 221.416C342.789 221.635 343.476 221.603 343.944 221.354L348.5 218.732C348.968 218.451 349.124 217.64 348.719 217.39L338.389 211.929C337.984 211.711 337.297 211.742 336.829 211.992L332.148 214.207C331.68 214.488 331.774 215.3 332.148 215.518H332.179Z" fill="#3F3F3F"/>
    <path d="M332.179 214.989L342.384 220.887C342.789 221.105 343.476 221.074 343.944 220.824L348.5 218.203C348.968 217.922 348.999 217.548 348.625 217.298L338.42 211.4C338.015 211.182 337.328 211.213 336.86 211.462L332.304 214.084C331.836 214.365 331.805 214.739 332.179 214.989Z" fill="#E8E8E8"/>
    <path d="M332.056 205.221L339.358 209.434C339.701 209.621 340.294 209.621 340.7 209.372L345.475 206.594C345.88 206.376 346.005 205.596 345.662 205.408L338.235 201.601C337.891 201.414 337.298 201.414 336.893 201.664L332.025 203.973C331.619 204.191 331.681 205.003 332.025 205.19L332.056 205.221Z" fill="#3F3F3F"/>
    <path d="M332.055 204.69L339.358 208.903C339.701 209.09 340.294 209.09 340.7 208.84L345.474 206.063C345.88 205.845 345.911 205.501 345.568 205.283L338.265 201.07C337.922 200.883 337.329 200.883 336.924 201.132L332.149 203.91C331.743 204.128 331.712 204.472 332.055 204.69Z" fill="#E8E8E8"/>
    <path d="M320.041 208.56L327.343 212.773C327.687 212.96 328.279 212.96 328.685 212.711L333.46 209.933C333.865 209.715 333.959 208.903 333.616 208.685L326.22 204.94C325.877 204.753 325.284 204.753 324.878 205.003L319.947 207.312C319.542 207.53 319.666 208.342 320.01 208.529L320.041 208.56Z" fill="#3F3F3F"/>
    <path d="M320.009 207.998L327.312 212.21C327.655 212.398 328.248 212.398 328.654 212.148L333.428 209.371C333.834 209.152 333.865 208.809 333.522 208.59L326.22 204.378C325.876 204.19 325.283 204.19 324.878 204.44L320.103 207.217C319.697 207.436 319.666 207.779 320.009 207.998Z" fill="#E8E8E8"/>
    <path d="M307.87 201.506L315.173 205.719C315.516 205.906 316.109 205.906 316.514 205.657L321.289 202.879C321.695 202.661 321.913 201.912 321.57 201.725L314.08 197.886C313.737 197.699 313.144 197.699 312.738 197.949L307.839 200.352C307.433 200.57 307.496 201.288 307.839 201.475L307.87 201.506Z" fill="#3F3F3F"/>
    <path d="M307.931 201.007L315.234 205.22C315.577 205.407 316.17 205.407 316.576 205.158L321.35 202.38C321.756 202.162 321.787 201.819 321.444 201.6L314.141 197.387C313.798 197.2 313.205 197.2 312.8 197.45L308.025 200.227C307.619 200.446 307.588 200.789 307.931 201.007Z" fill="#E8E8E8"/>
    <path d="M295.855 194.516L303.157 198.729C303.501 198.916 304.094 198.916 304.499 198.667L309.274 195.889C309.68 195.671 309.804 194.922 309.461 194.735L302.034 190.896C301.691 190.709 301.098 190.709 300.692 190.959L295.793 193.33C295.387 193.549 295.481 194.266 295.793 194.485L295.855 194.516Z" fill="#3F3F3F"/>
    <path d="M295.856 194.047L303.158 198.26C303.502 198.447 304.095 198.447 304.5 198.198L309.275 195.42C309.681 195.202 309.712 194.859 309.368 194.64L302.066 190.427C301.723 190.24 301.13 190.24 300.724 190.49L295.95 193.267C295.544 193.486 295.513 193.829 295.856 194.047Z" fill="#E8E8E8"/>
    <path d="M282.344 186.715L290.988 191.708C291.362 191.927 292.017 191.896 292.423 191.646L297.104 188.962C297.541 188.713 297.697 187.964 297.323 187.745L288.554 183.127C288.179 182.908 287.524 182.939 287.118 183.189L282.375 185.561C281.938 185.81 281.969 186.497 282.375 186.715H282.344Z" fill="#3F3F3F"/>
    <path d="M282.374 186.277L291.019 191.27C291.393 191.488 292.049 191.457 292.454 191.207L297.135 188.524C297.572 188.274 297.603 187.9 297.229 187.681L288.585 182.688C288.21 182.47 287.555 182.501 287.149 182.75L282.468 185.434C282.031 185.684 282 186.058 282.374 186.277Z" fill="#E8E8E8"/>
    <path d="M319.915 198.199L327.217 202.412C327.561 202.599 328.154 202.599 328.559 202.349L333.334 199.572C333.74 199.353 333.927 198.604 333.583 198.417L326.094 194.579C325.751 194.392 325.158 194.392 324.752 194.641L319.853 197.044C319.447 197.263 319.509 197.98 319.853 198.199H319.915Z" fill="#3F3F3F"/>
    <path d="M319.977 197.699L327.28 201.912C327.623 202.099 328.216 202.099 328.621 201.849L333.396 199.072C333.802 198.853 333.833 198.51 333.49 198.292L326.187 194.079C325.844 193.892 325.251 193.892 324.845 194.141L320.071 196.919C319.665 197.137 319.634 197.48 319.977 197.699Z" fill="#E8E8E8"/>
    <path d="M291.643 181.971L314.736 195.296C315.329 195.64 316.358 195.608 317.014 195.203L320.759 193.018C321.445 192.613 321.664 191.552 321.071 191.208L297.822 178.382C297.229 178.039 296.199 178.07 295.544 178.476L291.643 180.224C290.956 180.629 291.019 181.628 291.612 181.971H291.643Z" fill="#3F3F3F"/>
    <path d="M291.673 181.378L314.766 194.704C315.359 195.047 316.388 195.016 317.044 194.61L320.789 192.426C321.475 192.02 321.538 191.427 320.945 191.115L297.852 177.79C297.259 177.446 296.229 177.478 295.574 177.883L291.829 180.068C291.142 180.473 291.08 181.066 291.673 181.378Z" fill="#E8E8E8"/>
    <path d="M441.34 258.084L448.643 262.297C448.986 262.485 449.579 262.485 449.985 262.235L454.759 259.458C455.165 259.239 455.259 258.397 454.915 258.209L447.519 254.465C447.176 254.277 446.583 254.277 446.178 254.527L441.278 256.867C440.872 257.086 440.966 257.866 441.278 258.084H441.34Z" fill="#3F3F3F"/>
    <path d="M441.34 257.554L448.642 261.767C448.985 261.954 449.578 261.954 449.984 261.705L454.758 258.927C455.164 258.709 455.195 258.366 454.852 258.147L447.55 253.934C447.206 253.747 446.613 253.747 446.208 253.997L441.433 256.774C441.027 256.992 440.996 257.336 441.34 257.554Z" fill="#E8E8E8"/>
    <path d="M429.325 251.031L436.628 255.244C436.971 255.431 437.564 255.431 437.969 255.181L442.744 252.404C443.15 252.185 443.275 251.468 442.931 251.28L435.535 247.411C435.192 247.224 434.599 247.224 434.193 247.473L429.263 249.845C428.857 250.063 428.982 250.781 429.325 251V251.031Z" fill="#3F3F3F"/>
    <path d="M429.263 250.563L436.566 254.776C436.909 254.963 437.502 254.963 437.908 254.713L442.682 251.936C443.088 251.718 443.119 251.374 442.776 251.156L435.473 246.943C435.13 246.756 434.537 246.756 434.132 247.005L429.357 249.783C428.951 250.001 428.92 250.345 429.263 250.563Z" fill="#E8E8E8"/>
    <path d="M417.155 244.072L424.457 248.285C424.801 248.472 425.394 248.472 425.799 248.222L430.574 245.445C430.98 245.226 431.104 244.446 430.761 244.228L423.334 240.421C422.991 240.233 422.398 240.233 421.992 240.483L417.155 242.792C416.749 243.011 416.781 243.822 417.155 244.009V244.072Z" fill="#3F3F3F"/>
    <path id="bd_red" d="M417.185 243.604L424.488 247.817C424.831 248.004 425.424 248.004 425.829 247.754L430.604 244.977C431.01 244.759 431.041 244.415 430.698 244.197L423.395 239.984C423.052 239.797 422.459 239.797 422.053 240.046L417.279 242.824C416.873 243.042 416.842 243.386 417.185 243.604Z" fill="#E8E8E8"/>
    <path d="M405.079 237.083L412.381 241.295C412.724 241.483 413.317 241.483 413.723 241.233L418.497 238.456C418.903 238.237 419.059 237.519 418.716 237.332L411.258 233.463C410.914 233.275 410.321 233.275 409.916 233.525L405.079 235.928C404.673 236.146 404.704 236.864 405.047 237.051L405.079 237.083Z" fill="#3F3F3F"/>
    <path d="M405.11 236.614L412.412 240.827C412.756 241.014 413.349 241.014 413.754 240.764L418.529 237.987C418.934 237.768 418.966 237.425 418.622 237.207L411.32 232.994C410.977 232.807 410.384 232.807 409.978 233.056L405.204 235.834C404.798 236.052 404.767 236.395 405.11 236.614Z" fill="#E8E8E8"/>
    <path d="M393.033 230.06L400.335 234.273C400.678 234.46 401.271 234.46 401.677 234.211L406.452 231.433C406.857 231.215 406.982 230.528 406.639 230.31L399.243 226.44C398.9 226.253 398.307 226.253 397.901 226.503L392.97 228.937C392.565 229.155 392.658 229.842 393.002 230.029L393.033 230.06Z" fill="#3F3F3F"/>
    <path d="M393.031 229.656L400.333 233.869C400.677 234.056 401.269 234.056 401.675 233.806L406.45 231.029C406.855 230.81 406.887 230.467 406.543 230.249L399.241 226.036C398.898 225.849 398.305 225.849 397.899 226.098L393.125 228.876C392.719 229.094 392.688 229.437 393.031 229.656Z" fill="#E8E8E8"/>
    <path d="M380.986 223.195L388.288 227.408C388.631 227.595 389.224 227.595 389.63 227.345L394.404 224.568C394.81 224.349 394.904 223.538 394.561 223.351L387.196 219.575C386.852 219.388 386.26 219.388 385.854 219.637L380.892 222.04C380.486 222.259 380.642 222.976 380.986 223.164V223.195Z" fill="#3F3F3F"/>
    <path d="M380.957 222.697L388.259 226.91C388.602 227.097 389.195 227.097 389.601 226.847L394.376 224.07C394.781 223.851 394.812 223.508 394.469 223.29L387.167 219.077C386.824 218.89 386.231 218.89 385.825 219.139L381.05 221.917C380.645 222.135 380.613 222.478 380.957 222.697Z" fill="#E8E8E8"/>
    <path d="M368.847 216.142L376.15 220.355C376.493 220.542 377.086 220.542 377.492 220.293L382.266 217.515C382.672 217.297 382.797 216.548 382.453 216.361L375.057 212.522C374.714 212.335 374.121 212.335 373.716 212.585L368.847 214.987C368.442 215.206 368.473 215.924 368.847 216.111V216.142Z" fill="#3F3F3F"/>
    <path d="M368.879 215.705L376.181 219.917C376.524 220.105 377.117 220.105 377.523 219.855L382.297 217.078C382.703 216.859 382.734 216.516 382.391 216.298L375.089 212.085C374.745 211.897 374.152 211.897 373.747 212.147L368.972 214.924C368.567 215.143 368.535 215.486 368.879 215.705Z" fill="#E8E8E8"/>
    <path d="M356.771 209.152L364.073 213.365C364.416 213.552 365.009 213.552 365.415 213.302L370.189 210.525C370.595 210.306 370.751 209.62 370.408 209.433L362.981 205.532C362.637 205.345 362.044 205.345 361.639 205.594L356.771 207.997C356.365 208.216 356.427 208.933 356.771 209.121V209.152Z" fill="#3F3F3F"/>
    <path d="M356.802 208.747L364.105 212.959C364.448 213.147 365.041 213.147 365.447 212.897L370.221 210.12C370.627 209.901 370.658 209.558 370.315 209.34L363.012 205.127C362.669 204.939 362.076 204.939 361.671 205.189L356.896 207.966C356.49 208.185 356.459 208.528 356.802 208.747Z" fill="#E8E8E8"/>
    <path d="M344.662 202.318L351.965 206.531C352.308 206.718 352.901 206.718 353.306 206.468L358.081 203.691C358.487 203.473 358.705 202.692 358.362 202.505L350.872 198.698C350.529 198.511 349.936 198.511 349.53 198.76L344.662 201.101C344.257 201.319 344.319 202.099 344.662 202.287V202.318Z" fill="#3F3F3F"/>
    <path d="M344.724 201.755L352.027 205.968C352.37 206.155 352.963 206.155 353.368 205.906L358.143 203.128C358.549 202.91 358.58 202.567 358.237 202.348L350.934 198.135C350.591 197.948 349.998 197.948 349.592 198.198L344.818 200.975C344.412 201.194 344.381 201.537 344.724 201.755Z" fill="#E8E8E8"/>
    <path d="M332.585 195.295L339.887 199.508C340.23 199.696 340.823 199.696 341.229 199.446L346.003 196.668C346.409 196.45 346.565 195.67 346.222 195.451L338.763 191.675C338.42 191.488 337.827 191.488 337.422 191.738L332.585 194.047C332.179 194.266 332.21 195.077 332.553 195.264L332.585 195.295Z" fill="#3F3F3F"/>
    <path d="M332.648 194.798L339.95 199.011C340.294 199.198 340.887 199.198 341.292 198.949L346.067 196.171C346.473 195.953 346.504 195.61 346.16 195.391L338.858 191.178C338.515 190.991 337.922 190.991 337.516 191.241L332.742 194.018C332.336 194.237 332.305 194.58 332.648 194.798Z" fill="#E8E8E8"/>
    <path d="M320.507 188.431L327.81 192.644C328.153 192.831 328.746 192.831 329.152 192.582L333.926 189.804C334.332 189.586 334.519 188.743 334.176 188.525L326.686 184.811C326.343 184.624 325.75 184.624 325.344 184.874L320.414 187.152C320.008 187.37 320.102 188.182 320.445 188.4L320.507 188.431Z" fill="#3F3F3F"/>
    <path id="bd_red" d="M320.57 187.807L327.872 192.02C328.216 192.207 328.808 192.207 329.214 191.958L333.989 189.18C334.394 188.962 334.426 188.619 334.082 188.4L326.78 184.187C326.437 184 325.844 184 325.438 184.25L320.664 187.027C320.258 187.245 320.227 187.589 320.57 187.807Z" fill="#E8E8E8"/>
    <path d="M300.286 176.729L315.484 185.498C315.952 185.778 316.794 185.747 317.356 185.435L321.569 183.001C322.13 182.689 322.349 181.722 321.881 181.441L306.496 173.14C306.028 172.859 305.185 172.89 304.624 173.233L300.223 175.293C299.662 175.605 299.818 176.479 300.286 176.729Z" fill="#3F3F3F"/>
    <path d="M300.349 176.136L315.547 184.905C316.015 185.186 316.858 185.154 317.419 184.811L321.632 182.377C322.194 182.065 322.256 181.566 321.757 181.285L306.559 172.516C306.091 172.235 305.249 172.266 304.687 172.578L300.474 175.012C299.912 175.324 299.85 175.824 300.349 176.105V176.136Z" fill="#E8E8E8"/>
    <path d="M452.48 254.152L459.782 258.365C460.126 258.552 460.719 258.552 461.124 258.302L465.899 255.525C466.305 255.306 466.492 254.589 466.149 254.37L458.659 250.501C458.316 250.313 457.723 250.313 457.317 250.563L452.48 252.966C452.074 253.184 452.106 253.902 452.449 254.089L452.48 254.152Z" fill="#3F3F3F"/>
    <path d="M452.544 253.715L459.846 257.928C460.189 258.115 460.782 258.115 461.188 257.866L465.962 255.088C466.368 254.87 466.399 254.527 466.056 254.308L458.754 250.095C458.411 249.908 457.818 249.908 457.412 250.158L452.637 252.935C452.232 253.154 452.2 253.497 452.544 253.715Z" fill="#E8E8E8"/>
    <path d="M440.497 247.13L447.8 251.343C448.143 251.53 448.736 251.531 449.142 251.281L453.916 248.503C454.322 248.285 454.415 247.598 454.072 247.411L446.707 243.51C446.364 243.323 445.771 243.323 445.366 243.573L440.404 246.038C439.998 246.257 440.154 246.912 440.497 247.13Z" fill="#3F3F3F"/>
    <path id="bd_red1" d="M440.466 246.725L447.769 250.938C448.112 251.125 448.705 251.125 449.111 250.876L453.885 248.098C454.291 247.88 454.322 247.536 453.979 247.318L446.677 243.105C446.333 242.918 445.74 242.918 445.335 243.168L440.56 245.945C440.154 246.163 440.123 246.507 440.466 246.725Z" fill="#E8E8E8"/>
    <path d="M428.389 240.266L435.691 244.479C436.035 244.666 436.627 244.666 437.033 244.417L441.808 241.639C442.213 241.421 442.307 240.641 441.964 240.422L434.599 236.646C434.256 236.459 433.663 236.459 433.257 236.709L428.295 239.111C427.89 239.33 428.046 240.048 428.389 240.235V240.266Z" fill="#3F3F3F"/>
    <path d="M428.389 239.766L435.692 243.979C436.035 244.166 436.628 244.166 437.033 243.917L441.808 241.139C442.214 240.921 442.245 240.577 441.902 240.359L434.599 236.146C434.256 235.959 433.663 235.959 433.258 236.209L428.483 238.986C428.077 239.204 428.046 239.548 428.389 239.766Z" fill="#E8E8E8"/>
    <path d="M416.312 233.243L423.615 237.456C423.958 237.643 424.551 237.643 424.956 237.393L429.731 234.616C430.137 234.397 430.262 233.68 429.918 233.461L422.522 229.623C422.179 229.435 421.586 229.435 421.181 229.685L416.25 232.088C415.844 232.306 415.969 233.024 416.281 233.243H416.312Z" fill="#3F3F3F"/>
    <path d="M416.312 232.776L423.614 236.989C423.958 237.176 424.551 237.176 424.956 236.926L429.731 234.149C430.137 233.931 430.168 233.587 429.825 233.369L422.522 229.156C422.179 228.969 421.586 228.969 421.18 229.218L416.406 231.996C416 232.214 415.969 232.557 416.312 232.776Z" fill="#E8E8E8"/>
    <path d="M404.235 226.315L411.537 230.528C411.88 230.715 412.473 230.715 412.879 230.465L417.654 227.688C418.059 227.47 418.153 226.689 417.81 226.471L410.414 222.695C410.07 222.508 409.478 222.508 409.072 222.757L404.141 225.067C403.736 225.285 403.829 226.096 404.172 226.284L404.235 226.315Z" fill="#3F3F3F"/>
    <path d="M404.234 225.817L411.536 230.03C411.88 230.217 412.473 230.217 412.878 229.967L417.653 227.19C418.058 226.972 418.09 226.628 417.746 226.41L410.444 222.197C410.101 222.01 409.508 222.01 409.102 222.259L404.328 225.037C403.922 225.255 403.891 225.598 404.234 225.817Z" fill="#E8E8E8"/>
    <path d="M392.096 219.263L399.399 223.476C399.742 223.663 400.335 223.663 400.741 223.414L405.515 220.636C405.921 220.418 406.046 219.731 405.702 219.513L398.306 215.612C397.963 215.425 397.37 215.425 396.965 215.674L392.159 218.077C391.753 218.296 391.722 219.014 392.065 219.201L392.096 219.263Z" fill="#3F3F3F"/>
    <path id="bd_red" d="M392.159 218.825L399.461 223.038C399.804 223.225 400.397 223.225 400.803 222.975L405.578 220.198C405.983 219.979 406.015 219.636 405.671 219.418L398.369 215.205C398.026 215.018 397.433 215.018 397.027 215.267L392.252 218.045C391.847 218.263 391.816 218.606 392.159 218.825Z" fill="#E8E8E8"/>
    <path d="M380.019 212.305L387.322 216.518C387.665 216.705 388.258 216.705 388.663 216.456L393.438 213.678C393.844 213.46 393.968 212.711 393.625 212.492L386.198 208.685C385.855 208.498 385.262 208.498 384.856 208.748L379.957 211.182C379.551 211.4 379.645 212.087 379.957 212.274L380.019 212.305Z" fill="#3F3F3F"/>
    <path d="M380.081 211.867L387.383 216.08C387.726 216.267 388.319 216.267 388.725 216.017L393.5 213.24C393.905 213.021 393.936 212.678 393.593 212.46L386.291 208.247C385.948 208.06 385.355 208.06 384.949 208.309L380.174 211.087C379.769 211.305 379.737 211.648 380.081 211.867Z" fill="#E8E8E8"/>
    <path d="M367.942 205.375L375.244 209.588C375.587 209.776 376.18 209.776 376.586 209.526L381.36 206.749C381.766 206.53 381.953 205.812 381.61 205.594L374.152 201.756C373.808 201.568 373.215 201.568 372.81 201.818L367.973 204.127C367.567 204.346 367.567 205.157 367.91 205.344L367.942 205.375Z" fill="#3F3F3F"/>
    <path d="M367.974 204.876L375.277 209.089C375.62 209.277 376.213 209.277 376.618 209.027L381.393 206.25C381.799 206.031 381.83 205.688 381.487 205.469L374.184 201.257C373.841 201.069 373.248 201.069 372.842 201.319L368.068 204.096C367.662 204.315 367.631 204.658 367.974 204.876Z" fill="#E8E8E8"/>
    <path d="M355.896 198.417L363.199 202.63C363.542 202.818 364.135 202.818 364.541 202.568L369.315 199.791C369.721 199.572 369.877 198.823 369.534 198.605L362.075 194.798C361.732 194.61 361.139 194.61 360.733 194.86L355.803 197.263C355.397 197.481 355.522 198.199 355.865 198.386L355.896 198.417Z" fill="#3F3F3F"/>
    <path id="bd_red1" d="M355.896 197.918L363.198 202.131C363.542 202.319 364.135 202.319 364.54 202.069L369.315 199.292C369.721 199.073 369.752 198.73 369.409 198.511L362.106 194.299C361.763 194.111 361.17 194.111 360.764 194.361L355.99 197.138C355.584 197.357 355.553 197.7 355.896 197.918Z" fill="#E8E8E8"/>
    <path d="M343.788 191.49L351.091 195.703C351.434 195.89 352.027 195.89 352.432 195.64L357.207 192.863C357.613 192.644 357.769 191.864 357.425 191.646L349.967 187.87C349.624 187.683 349.031 187.683 348.625 187.932L343.819 190.21C343.414 190.429 343.414 191.271 343.757 191.459L343.788 191.49Z" fill="#3F3F3F"/>
    <path d="M343.82 190.927L351.122 195.14C351.466 195.327 352.059 195.327 352.464 195.078L357.239 192.3C357.645 192.082 357.676 191.739 357.332 191.52L350.03 187.307C349.687 187.12 349.094 187.12 348.688 187.37L343.914 190.147C343.508 190.366 343.477 190.709 343.82 190.927Z" fill="#E8E8E8"/>
    <path d="M331.649 184.437L338.951 188.65C339.294 188.837 339.887 188.837 340.293 188.587L345.067 185.81C345.473 185.592 345.754 184.874 345.411 184.687L337.859 180.817C337.515 180.63 336.922 180.63 336.517 180.879L331.711 183.282C331.305 183.501 331.305 184.219 331.649 184.437Z" fill="#3F3F3F"/>
    <path d="M331.742 183.968L339.044 188.181C339.387 188.368 339.98 188.368 340.386 188.119L345.161 185.341C345.566 185.123 345.598 184.78 345.254 184.561L337.952 180.348C337.609 180.161 337.016 180.161 336.61 180.411L331.835 183.188C331.43 183.407 331.399 183.75 331.742 183.968Z" fill="#E8E8E8"/>
    <path d="M309.274 171.643L326.656 181.691C327.156 181.972 328.061 181.941 328.653 181.598L332.71 179.257C333.303 178.914 333.459 177.822 332.96 177.541L315.484 168.085C314.985 167.804 314.08 167.835 313.487 168.179L309.274 170.02C308.681 170.363 308.744 171.393 309.274 171.674V171.643Z" fill="#3F3F3F"/>
    <path d="M309.305 171.017L326.687 181.065C327.187 181.346 328.092 181.315 328.685 180.972L332.741 178.631C333.334 178.288 333.397 177.757 332.897 177.476L315.515 167.428C315.016 167.147 314.111 167.178 313.518 167.521L309.461 169.862C308.869 170.205 308.806 170.736 309.305 171.017Z" fill="#E8E8E8"/>
    <path d="M456.569 246.257L463.871 250.47C464.214 250.657 464.807 250.657 465.213 250.408L469.988 247.63C470.393 247.412 470.549 246.632 470.206 246.445L462.779 242.637C462.436 242.45 461.843 242.45 461.437 242.7L456.506 245.009C456.101 245.228 456.194 246.039 456.538 246.226L456.569 246.257Z" fill="#3F3F3F"/>
    <path d="M456.569 245.758L463.871 249.971C464.215 250.158 464.807 250.158 465.213 249.909L469.988 247.131C470.393 246.913 470.425 246.57 470.081 246.351L462.779 242.138C462.436 241.951 461.843 241.951 461.437 242.201L456.663 244.978C456.257 245.197 456.226 245.54 456.569 245.758Z" fill="#E8E8E8"/>
    <path d="M444.523 239.267L451.826 243.48C452.169 243.667 452.762 243.667 453.167 243.418L457.942 240.64C458.348 240.422 458.41 239.642 458.067 239.454L450.733 235.647C450.39 235.46 449.797 235.46 449.391 235.71L444.492 238.081C444.086 238.3 444.18 239.049 444.492 239.236L444.523 239.267Z" fill="#3F3F3F"/>
    <path d="M444.491 238.767L451.793 242.98C452.136 243.167 452.729 243.167 453.135 242.918L457.91 240.14C458.315 239.922 458.347 239.578 458.003 239.36L450.701 235.147C450.358 234.96 449.765 234.96 449.359 235.21L444.584 237.987C444.179 238.205 444.148 238.549 444.491 238.767Z" fill="#E8E8E8"/>
    <path d="M432.446 232.244L439.748 236.457C440.092 236.644 440.685 236.644 441.09 236.394L445.865 233.617C446.27 233.398 446.302 232.649 445.958 232.462L438.625 228.624C438.282 228.436 437.689 228.436 437.283 228.686L432.384 231.089C431.978 231.307 432.072 232.025 432.384 232.212L432.446 232.244Z" fill="#3F3F3F"/>
    <path d="M432.416 231.807L439.718 236.02C440.061 236.207 440.654 236.207 441.06 235.958L445.834 233.18C446.24 232.962 446.271 232.619 445.928 232.4L438.626 228.187C438.282 228 437.69 228 437.284 228.25L432.509 231.027C432.104 231.245 432.072 231.589 432.416 231.807Z" fill="#E8E8E8"/>
    <path d="M420.369 225.286L427.672 229.499C428.015 229.686 428.608 229.686 429.014 229.436L433.788 226.659C434.194 226.44 434.256 225.691 433.913 225.504L426.58 221.666C426.236 221.478 425.643 221.478 425.238 221.728L420.276 224.193C419.87 224.412 420.026 225.067 420.369 225.254V225.286Z" fill="#3F3F3F"/>
    <path d="M420.338 224.818L427.64 229.031C427.983 229.218 428.576 229.218 428.982 228.968L433.756 226.191C434.162 225.973 434.193 225.629 433.85 225.411L426.548 221.198C426.204 221.011 425.611 221.011 425.206 221.26L420.431 224.038C420.025 224.256 419.994 224.599 420.338 224.818Z" fill="#E8E8E8"/>
    <path d="M408.167 218.295L415.469 222.508C415.813 222.696 416.406 222.696 416.811 222.446L421.586 219.668C421.992 219.45 422.148 218.701 421.804 218.514L414.377 214.675C414.034 214.488 413.441 214.488 413.035 214.738L408.292 217.11C407.886 217.328 407.824 218.077 408.167 218.264V218.295Z" fill="#3F3F3F"/>
    <path d="M408.261 217.86L415.564 222.073C415.907 222.26 416.5 222.26 416.906 222.01L421.68 219.233C422.086 219.014 422.117 218.671 421.774 218.453L414.472 214.24C414.128 214.053 413.535 214.053 413.13 214.302L408.355 217.08C407.949 217.298 407.918 217.641 408.261 217.86Z" fill="#E8E8E8"/>
    <path d="M396.091 211.274L403.393 215.487C403.737 215.674 404.33 215.674 404.735 215.424L409.51 212.647C409.915 212.429 410.04 211.773 409.697 211.586L402.301 207.654C401.958 207.467 401.365 207.467 400.959 207.716L396.153 210.213C395.748 210.431 395.748 211.055 396.091 211.243V211.274Z" fill="#3F3F3F"/>
    <path d="M396.183 210.87L403.486 215.083C403.829 215.27 404.422 215.27 404.828 215.02L409.602 212.243C410.008 212.024 410.039 211.681 409.696 211.463L402.393 207.25C402.05 207.062 401.457 207.062 401.051 207.312L396.277 210.089C395.871 210.308 395.84 210.651 396.183 210.87Z" fill="#E8E8E8"/>
    <path d="M384.044 204.283L391.346 208.496C391.689 208.683 392.282 208.683 392.688 208.433L397.462 205.656C397.868 205.437 398.055 204.813 397.712 204.626L390.254 200.663C389.91 200.476 389.318 200.476 388.912 200.725L384.044 203.19C383.638 203.409 383.669 204.064 384.044 204.252V204.283Z" fill="#3F3F3F"/>
    <path d="M384.107 203.91L391.409 208.123C391.753 208.31 392.346 208.31 392.751 208.06L397.526 205.283C397.932 205.064 397.963 204.721 397.62 204.503L390.317 200.29C389.974 200.102 389.381 200.102 388.975 200.352L384.201 203.13C383.795 203.348 383.764 203.691 384.107 203.91Z" fill="#E8E8E8"/>
    <path d="M372 197.355L379.302 201.568C379.645 201.755 380.238 201.755 380.644 201.505L385.419 198.728C385.824 198.51 385.887 197.854 385.544 197.636L378.21 193.735C377.867 193.548 377.274 193.548 376.868 193.797L371.906 196.325C371.501 196.544 371.625 197.137 371.969 197.355H372Z" fill="#3F3F3F"/>
    <path d="M372.029 196.918L379.331 201.131C379.675 201.319 380.267 201.319 380.673 201.069L385.448 198.292C385.853 198.073 385.885 197.73 385.541 197.511L378.239 193.299C377.896 193.111 377.303 193.111 376.897 193.361L372.123 196.138C371.717 196.357 371.686 196.7 372.029 196.918Z" fill="#E8E8E8"/>
    <path d="M359.954 190.397L367.257 194.61C367.6 194.797 368.193 194.797 368.598 194.547L373.373 191.77C373.779 191.552 373.903 190.896 373.56 190.678L366.164 186.777C365.821 186.59 365.228 186.59 364.822 186.839L359.954 189.242C359.549 189.461 359.611 190.179 359.954 190.397Z" fill="#3F3F3F"/>
    <path d="M359.955 189.96L367.257 194.173C367.6 194.361 368.193 194.361 368.599 194.111L373.374 191.334C373.779 191.115 373.81 190.772 373.467 190.553L366.165 186.34C365.822 186.153 365.229 186.153 364.823 186.403L360.048 189.18C359.643 189.399 359.611 189.742 359.955 189.96Z" fill="#E8E8E8"/>
    <path d="M347.815 183.469L355.117 187.682C355.46 187.869 356.053 187.869 356.459 187.62L361.233 184.842C361.639 184.624 361.795 183.813 361.452 183.625L353.993 179.849C353.65 179.662 353.057 179.662 352.652 179.912L347.908 182.159C347.502 182.377 347.44 183.251 347.783 183.438L347.815 183.469Z" fill="#3F3F3F"/>
    <path d="M347.876 182.97L355.178 187.183C355.521 187.37 356.114 187.37 356.52 187.121L361.294 184.343C361.7 184.125 361.731 183.782 361.388 183.563L354.086 179.35C353.742 179.163 353.15 179.163 352.744 179.413L347.969 182.19C347.564 182.409 347.532 182.752 347.876 182.97Z" fill="#E8E8E8"/>
    <path d="M318.322 166.46L342.351 180.316C342.944 180.659 344.005 180.628 344.692 180.223L348.405 178.069C349.092 177.664 349.31 176.634 348.717 176.291L324.532 162.84C323.939 162.497 322.878 162.528 322.192 162.934L318.447 164.65C317.76 165.056 317.698 166.086 318.322 166.429V166.46Z" fill="#3F3F3F"/>
    <path d="M318.354 165.962L342.383 179.818C342.976 180.161 344.037 180.13 344.724 179.725L348.438 177.571C349.124 177.166 349.218 176.573 348.594 176.229L324.565 162.374C323.972 162.03 322.911 162.062 322.224 162.467L318.511 164.621C317.824 165.026 317.73 165.619 318.354 165.962Z" fill="#E8E8E8"/>
    <path d="M486.808 252.436L495.359 257.366C495.639 257.522 496.139 257.522 496.451 257.335L499.01 255.868C499.322 255.681 499.415 255.119 499.135 254.932L490.522 250.282C490.241 250.126 489.741 250.126 489.429 250.314L486.777 251.531C486.465 251.718 486.527 252.248 486.777 252.404L486.808 252.436Z" fill="#3F3F3F"/>
    <path d="M486.807 252.092L495.358 257.023C495.638 257.179 496.138 257.179 496.45 256.991L499.009 255.525C499.321 255.337 499.352 255.056 499.071 254.9L490.521 249.97C490.24 249.814 489.74 249.814 489.428 250.001L486.869 251.468C486.557 251.655 486.526 251.936 486.776 252.092H486.807Z" fill="#E8E8E8"/>
    <path d="M472.297 244.041L480.847 248.972C481.128 249.128 481.627 249.128 481.939 248.941L484.498 247.474C484.81 247.287 484.935 246.756 484.654 246.6L476.041 241.919C475.761 241.763 475.261 241.763 474.949 241.95L472.359 243.167C472.047 243.355 472.047 243.885 472.359 244.041H472.297Z" fill="#3F3F3F"/>
    <path d="M472.33 243.729L480.88 248.659C481.161 248.815 481.66 248.815 481.972 248.628L484.531 247.161C484.843 246.974 484.875 246.693 484.625 246.537L476.074 241.607C475.793 241.451 475.294 241.451 474.982 241.638L472.423 243.104C472.111 243.292 472.08 243.573 472.33 243.729Z" fill="#E8E8E8"/>
    <path d="M457.818 235.709L466.369 240.64C466.65 240.796 467.149 240.796 467.461 240.608L470.02 239.142C470.332 238.955 470.488 238.393 470.207 238.237L461.532 233.587C461.251 233.431 460.751 233.431 460.439 233.618L457.849 234.804C457.537 234.991 457.537 235.553 457.849 235.709H457.818Z" fill="#3F3F3F"/>
    <path d="M457.816 235.365L466.366 240.296C466.647 240.452 467.147 240.452 467.459 240.265L470.018 238.798C470.33 238.611 470.361 238.33 470.111 238.174L461.561 233.243C461.28 233.087 460.78 233.087 460.468 233.274L457.909 234.741C457.597 234.928 457.566 235.209 457.816 235.365Z" fill="#E8E8E8"/>
    <path d="M443.306 227.377L451.856 232.308C452.137 232.464 452.636 232.464 452.948 232.276L455.507 230.81C455.819 230.623 455.944 230.03 455.663 229.874L447.05 225.255C446.77 225.099 446.27 225.099 445.958 225.286L443.368 226.472C443.056 226.659 443.056 227.221 443.337 227.377H443.306Z" fill="#3F3F3F"/>
    <path d="M443.337 227.002L451.888 231.933C452.169 232.089 452.668 232.089 452.98 231.901L455.539 230.435C455.851 230.248 455.882 229.967 455.633 229.811L447.082 224.88C446.801 224.724 446.302 224.724 445.99 224.911L443.431 226.378C443.119 226.565 443.088 226.846 443.337 227.002Z" fill="#E8E8E8"/>
    <path d="M428.857 219.075L437.407 224.006C437.688 224.162 438.187 224.162 438.499 223.975L441.058 222.508C441.371 222.321 441.464 221.665 441.183 221.509L432.57 216.953C432.289 216.797 431.79 216.797 431.478 216.984L428.826 218.108C428.513 218.295 428.576 218.919 428.826 219.075H428.857Z" fill="#3F3F3F"/>
    <path d="M428.825 218.64L437.376 223.57C437.657 223.726 438.156 223.726 438.468 223.539L441.027 222.072C441.339 221.885 441.37 221.604 441.121 221.448L432.57 216.518C432.289 216.362 431.79 216.362 431.478 216.549L428.919 218.016C428.607 218.203 428.576 218.484 428.857 218.64H428.825Z" fill="#E8E8E8"/>
    <path d="M414.347 210.713L422.897 215.644C423.178 215.8 423.677 215.8 423.989 215.612L426.548 214.146C426.86 213.958 426.954 213.272 426.673 213.085L418.06 208.56C417.779 208.404 417.28 208.404 416.968 208.591L414.347 209.683C414.034 209.87 414.066 210.557 414.347 210.713Z" fill="#3F3F3F"/>
    <path d="M414.347 210.244L422.897 215.175C423.178 215.331 423.678 215.331 423.99 215.144L426.549 213.677C426.861 213.49 426.892 213.209 426.642 213.053L418.092 208.122C417.811 207.966 417.311 207.966 416.999 208.153L414.44 209.62C414.128 209.807 414.097 210.088 414.378 210.244H414.347Z" fill="#E8E8E8"/>
    <path d="M399.867 202.411L408.417 207.342C408.698 207.498 409.197 207.498 409.51 207.311L412.068 205.844C412.38 205.657 412.443 204.908 412.162 204.752L403.58 200.289C403.299 200.133 402.8 200.133 402.488 200.32L399.773 201.381C399.461 201.569 399.555 202.255 399.836 202.411H399.867Z" fill="#3F3F3F"/>
    <path d="M399.834 201.881L408.385 206.812C408.666 206.968 409.165 206.968 409.477 206.78L412.036 205.314C412.348 205.126 412.379 204.846 412.099 204.69L403.548 199.759C403.267 199.603 402.768 199.603 402.456 199.79L399.897 201.257C399.585 201.444 399.554 201.725 399.803 201.881H399.834Z" fill="#E8E8E8"/>
    <path d="M385.325 193.893L393.876 198.823C394.156 198.979 394.656 198.979 394.968 198.792L397.527 197.325C397.839 197.138 397.964 196.545 397.683 196.389L389.039 191.771C388.758 191.615 388.258 191.615 387.946 191.802L385.325 192.988C385.013 193.175 385.044 193.737 385.325 193.924V193.893Z" fill="#3F3F3F"/>
    <path d="M385.357 193.518L393.907 198.448C394.188 198.604 394.687 198.604 394.999 198.417L397.558 196.95C397.87 196.763 397.902 196.482 397.621 196.326L389.07 191.396C388.789 191.24 388.29 191.24 387.978 191.427L385.419 192.894C385.107 193.081 385.076 193.362 385.357 193.518Z" fill="#E8E8E8"/>
    <path d="M370.844 185.528L379.395 190.459C379.676 190.615 380.175 190.615 380.487 190.428L383.046 188.961C383.358 188.774 383.452 188.181 383.171 188.025L374.558 183.375C374.277 183.219 373.778 183.219 373.466 183.406L370.876 184.561C370.564 184.748 370.564 185.341 370.876 185.497L370.844 185.528Z" fill="#3F3F3F"/>
    <path d="M370.845 185.153L379.396 190.084C379.677 190.24 380.176 190.24 380.488 190.053L383.047 188.586C383.359 188.399 383.39 188.118 383.109 187.962L374.559 183.031C374.278 182.875 373.779 182.875 373.466 183.063L370.908 184.529C370.595 184.717 370.564 184.997 370.814 185.153H370.845Z" fill="#E8E8E8"/>
    <path d="M356.334 177.134L364.885 182.065C365.166 182.221 365.665 182.221 365.977 182.033L368.536 180.567C368.848 180.379 368.942 179.786 368.661 179.63L360.048 175.012C359.767 174.856 359.268 174.856 358.956 175.043L356.303 176.229C355.991 176.416 356.053 176.978 356.303 177.134H356.334Z" fill="#3F3F3F"/>
    <path d="M356.364 176.79L364.914 181.721C365.195 181.877 365.694 181.877 366.006 181.69L368.565 180.223C368.877 180.036 368.909 179.755 368.628 179.599L360.077 174.668C359.796 174.512 359.297 174.512 358.985 174.699L356.426 176.166C356.114 176.353 356.083 176.634 356.332 176.79H356.364Z" fill="#E8E8E8"/>
    <path d="M341.792 168.865L350.342 173.796C350.623 173.952 351.123 173.952 351.435 173.765L353.994 172.298C354.306 172.111 354.524 171.518 354.243 171.362L345.537 166.743C345.256 166.587 344.756 166.587 344.444 166.774L341.854 167.929C341.542 168.116 341.542 168.709 341.854 168.865H341.792Z" fill="#3F3F3F"/>
    <path d="M341.853 168.427L350.404 173.357C350.684 173.514 351.184 173.513 351.496 173.326L354.055 171.86C354.367 171.672 354.398 171.391 354.148 171.235L345.598 166.305C345.317 166.149 344.818 166.149 344.506 166.336L341.947 167.803C341.635 167.99 341.603 168.271 341.853 168.427Z" fill="#E8E8E8"/>
    <path d="M327.374 160.502L335.925 165.433C336.206 165.589 336.705 165.589 337.017 165.401L339.576 163.935C339.888 163.748 340.013 163.092 339.732 162.936L331.119 158.38C330.838 158.224 330.339 158.224 330.027 158.411L327.374 159.566C327.062 159.753 327.125 160.346 327.374 160.533V160.502Z" fill="#3F3F3F"/>
    <path d="M327.374 160.034L335.925 164.965C336.206 165.121 336.705 165.121 337.017 164.934L339.576 163.467C339.888 163.28 339.919 162.999 339.67 162.843L331.119 157.912C330.838 157.756 330.339 157.756 330.027 157.943L327.468 159.41C327.156 159.597 327.125 159.878 327.374 160.034Z" fill="#E8E8E8"/>
    <path d="M385.76 259.426C388.881 261.236 388.912 264.169 385.792 265.979L354.991 283.892C351.87 285.702 346.815 285.67 343.694 283.892L297.415 257.179C294.294 255.369 294.232 252.435 297.353 250.626L328.153 232.713C331.274 230.903 336.361 230.903 339.481 232.713L385.76 259.426Z" fill="#A3A3A3"/>
    <path d="M351.402 132.105L345.504 11.3353C345.785 1.2244 358.517 -2.05228 364.228 1.2244C408.697 26.8762 542.043 103.894 542.043 103.894C547.66 107.139 551.092 113.1 551.092 119.591V242.576C551.092 254.59 541.73 258.21 535.271 253.841L357.612 142.871C353.774 140.655 351.402 136.536 351.402 132.105Z" fill="#A3A3A3"/>
    <path d="M345.504 135.569V12.0844C345.504 5.49981 352.619 1.38056 358.33 4.68845L536.144 107.358C541.762 110.603 545.194 116.564 545.194 123.055V246.04C545.194 251.376 539.421 254.715 534.803 252.031L351.714 146.335C347.876 144.119 345.504 140 345.504 135.569Z" fill="#F7F5F7"/>
    <path d="M350.808 135.194V18.263C350.808 12.0217 357.548 8.15212 362.947 11.2416L531.337 108.45C536.642 111.508 539.919 117.188 539.919 123.304V181.535V239.767C539.919 244.822 534.458 247.974 530.089 245.446L356.706 145.336C353.054 143.245 350.808 139.344 350.808 135.131V135.194Z" fill="#CCDEEC"/>
    <path id="bd_dot1" d="M445.365 62.2028C446.988 63.139 448.298 65.4171 448.298 67.2895C448.298 69.1619 446.957 69.9109 445.334 68.9747C443.711 68.0385 442.4 65.7604 442.4 63.888C442.4 62.0156 443.742 61.2667 445.365 62.2028Z" fill="url(#paint2_linear_2249_152)"/>
    <g opacity="0.61">
    <path d="M515.235 109.292C519.979 112.038 523.817 118.685 523.817 124.146L523.474 246.913C523.474 252.374 519.573 254.589 514.83 251.843L341.634 151.857C336.89 149.111 333.052 142.464 333.052 137.003L333.395 14.2368C333.395 8.77562 337.296 6.55997 342.039 9.30614L515.235 109.292Z" fill="url(#paint3_linear_2249_152)"/>
    </g>
    <path id="bd_tricolor" d="M367.688 79.2713L373.961 75.6201L382.574 113.505L376.302 117.156L367.688 79.2713Z" fill="#E58B0A"/>
    <path id="bd_tricolor" d="M355.582 80.7386L361.854 77.0874C365.194 75.1526 369.344 74.5909 373.963 75.6207L367.69 79.2719C363.072 78.242 358.921 78.8038 355.582 80.7386Z" fill="url(#paint4_linear_2249_152)"/>
    <path id="bd_tricolor" d="M355.579 80.7394L361.852 77.0883C362.632 76.6514 363.443 76.2769 364.286 75.9648L358.013 79.616C357.171 79.9281 356.359 80.3025 355.579 80.7394Z" fill="url(#paint5_linear_2249_152)"/>
    <path id="bd_tricolor" d="M358.014 79.6153L364.286 75.9641C364.941 75.7457 365.597 75.5584 366.283 75.4336L360.011 79.0848C359.324 79.2096 358.669 79.3968 358.014 79.6153Z" fill="url(#paint6_linear_2249_152)"/>
    <path id="bd_tricolor" d="M360.01 79.0844L366.282 75.4332C366.844 75.3396 367.375 75.246 367.967 75.1836L361.695 78.8347C361.133 78.8972 360.571 78.9596 360.01 79.0844Z" fill="url(#paint7_linear_2249_152)"/>
    <path id="bd_tricolor" d="M361.695 78.8347L367.968 75.1835C368.467 75.1523 368.966 75.1211 369.466 75.1211L363.193 78.7722C362.694 78.7722 362.195 78.7723 361.695 78.8347Z" fill="url(#paint8_linear_2249_152)"/>
    <path id="bd_tricolor" d="M363.193 78.7722L369.466 75.1211C369.934 75.1211 370.402 75.1211 370.87 75.1523L364.598 78.8035C364.13 78.7723 363.661 78.741 363.193 78.7722Z" fill="url(#paint9_linear_2249_152)"/>
    <path id="bd_tricolor" d="M364.567 78.8035L370.84 75.1523C371.277 75.1523 371.714 75.2148 372.151 75.2772L365.878 78.9283C365.441 78.8659 365.004 78.8347 364.567 78.8035Z" fill="url(#paint10_linear_2249_152)"/>
    <path id="bd_tricolor" d="M365.878 78.9285L372.15 75.2773C372.556 75.3398 372.962 75.4022 373.399 75.4958L367.126 79.1469C366.72 79.0533 366.284 78.9909 365.878 78.9285Z" fill="url(#paint11_linear_2249_152)"/>
    <path id="bd_tricolor" d="M367.126 79.1472L373.399 75.4961C373.586 75.5273 373.773 75.5585 373.929 75.6209L367.657 79.2721C367.469 79.2409 367.282 79.2097 367.126 79.1472Z" fill="url(#paint12_linear_2249_152)"/>
    <path id="bd_tricolor" d="M403.297 148.425L397.024 152.076C402.267 149.017 405.544 142.526 405.544 133.321C405.544 132.509 405.544 131.698 405.481 130.855L411.754 127.204C411.816 128.047 411.816 128.858 411.816 129.669C411.816 138.907 408.54 145.398 403.297 148.425Z" fill="#E58B0A"/>
    <path id="bd_tricolor" d="M405.509 130.823L411.781 127.172C411.781 127.515 411.812 127.858 411.812 128.233L405.54 131.884C405.54 131.541 405.54 131.198 405.509 130.823Z" fill="#E58B0A"/>
    <path id="bd_tricolor" d="M405.541 131.884L411.814 128.232C411.814 128.701 411.814 129.169 411.814 129.637V129.73L405.541 133.382C405.541 133.382 405.541 133.319 405.541 133.288C405.541 132.82 405.541 132.352 405.541 131.884Z" fill="#E58B0A"/>
    <path id="bd_tricolor" d="M405.572 133.382L411.845 129.73C411.845 130.199 411.845 130.667 411.814 131.135L405.541 134.786C405.541 134.318 405.541 133.85 405.572 133.382Z" fill="#E58B0A"/>
    <path id="bd_tricolor" d="M405.541 134.787L411.813 131.136C411.813 131.604 411.751 132.072 411.72 132.509L405.447 136.16C405.478 135.723 405.51 135.255 405.541 134.787Z" fill="#E58B0A"/>
    <path id="bd_tricolor" d="M405.447 136.191L411.72 132.54C411.688 133.008 411.626 133.476 411.563 133.944L405.291 137.596C405.353 137.127 405.385 136.659 405.447 136.191Z" fill="#E58B0A"/>
    <path id="bd_tricolor" d="M405.291 137.595L411.563 133.943C411.501 134.411 411.438 134.88 411.345 135.348L405.072 138.999C405.166 138.531 405.228 138.063 405.291 137.595Z" fill="#E58B0A"/>
    <path id="bd_tricolor" d="M405.073 138.999L411.345 135.348C411.252 135.847 411.158 136.315 411.065 136.783L404.792 140.434C404.886 139.966 404.979 139.498 405.073 138.999Z" fill="#E58B0A"/>
    <path id="bd_tricolor" d="M404.791 140.434L411.064 136.783C410.939 137.283 410.814 137.782 410.689 138.281L404.417 141.932C404.573 141.433 404.698 140.965 404.791 140.434Z" fill="#E58B0A"/>
    <path id="bd_tricolor" d="M404.418 141.902L410.691 138.251C410.535 138.781 410.379 139.281 410.191 139.78L403.919 143.431C404.106 142.932 404.262 142.433 404.418 141.902Z" fill="#E58B0A"/>
    <path id="bd_tricolor" d="M403.887 143.462L410.159 139.811C409.941 140.372 409.722 140.934 409.473 141.464L403.2 145.116C403.45 144.585 403.7 144.023 403.887 143.462Z" fill="#E58B0A"/>
    <path id="bd_tricolor" d="M403.201 145.115L409.473 141.464C409.13 142.182 408.755 142.837 408.381 143.461L402.108 147.112C402.514 146.488 402.889 145.833 403.201 145.115Z" fill="#E58B0A"/>
    <path id="bd_tricolor" d="M402.108 147.113L408.381 143.462C407.039 145.584 405.322 147.238 403.325 148.424L397.053 152.075C399.05 150.92 400.766 149.235 402.108 147.113Z" fill="#E58B0A"/>
    <path id="bd_tricolor" d="M376.301 117.156L382.573 113.505L411.783 127.173L405.51 130.825L376.301 117.156Z" fill="#E58B0A"/>
    <path id="bd_tricolor" d="M367.689 79.2708L376.302 117.187L405.511 130.855C405.574 131.698 405.574 132.509 405.574 133.32C405.511 151.888 392.373 159.378 376.208 150.047C360.043 140.716 346.999 118.092 347.03 99.5238C347.062 84.4199 355.737 76.6495 367.689 79.2708Z" fill="#FFB420"/>
    <g opacity="0.8">
    <path d="M400.019 68.7552V71.751L342.162 38.36L342.193 35.333L400.019 68.7552Z" fill="#F7F5F7"/>
    </g>
    <g opacity="0.8">
    <path d="M378.674 65.79V68.8482L342.162 47.7838L342.193 44.7256L378.674 65.79Z" fill="#F7F5F7"/>
    </g>
    <g opacity="0.7">
    <path d="M448.794 176.135C453.631 178.943 452.851 196.793 456.595 199.259C457.781 199.945 458.593 198.572 459.529 197.293C460.465 195.982 461.526 194.765 463.055 195.67C463.398 195.857 463.773 196.169 464.179 196.606C467.674 200.413 467.736 212.147 470.919 213.988C471.325 214.207 471.762 214.3 472.261 214.175C476.786 213.177 475.569 191.832 480 194.359C484.9 197.386 482.622 218.139 486.897 220.635C487.147 220.791 487.428 220.854 487.708 220.885C493.107 221.134 492.545 216.235 494.667 216.453C494.823 216.453 494.979 216.516 495.104 216.61C497.039 217.733 497.382 224.38 501.782 228.374C505.621 231.838 508.523 235.302 509.085 239.421C496.54 231.869 435.094 196.076 431.849 194.203C432.005 189.054 434.252 185.434 436.374 186.651C436.436 186.682 436.498 186.714 436.561 186.776C439.182 188.648 440.087 196.918 441.46 197.698C441.616 197.792 441.741 197.792 441.897 197.698C443.551 196.7 443.801 174.481 448.232 175.947C448.419 176.01 448.575 176.072 448.731 176.166L448.794 176.135Z" fill="#223A7A"/>
    </g>
    <path id="bd_graph" d="M435.97 167.646L435.877 196.013L435.065 195.544L435.159 167.178L435.97 167.646Z" fill="#223A7A"/>
    <path id="bd_graph1" d="M448.952 170.33L448.89 198.697L448.078 198.229L448.141 169.862L448.952 170.33Z" fill="#223A7A"/>
    <path id="bd_graph" d="M462.839 181.472L462.745 209.808L461.934 209.371L462.027 181.004L462.839 181.472Z" fill="#223A7A"/>
    <path id="bd_graph1" d="M479.877 187.621L479.814 215.988L479.003 215.52L479.097 187.185L479.877 187.621Z" fill="#223A7A"/>
    <path id="bd_graph" d="M495.511 200.445L495.418 228.811L494.606 228.343L494.7 199.977L495.511 200.445Z" fill="#223A7A"/>
    <g opacity="0.7">
    <path d="M474.26 148.079C478.629 150.607 476.975 167.989 482.093 170.954C482.249 171.047 482.436 171.141 482.592 171.203C483.31 171.453 483.965 171.765 484.558 172.108C487.429 173.762 488.646 176.196 489.676 178.662C489.801 178.942 489.926 179.098 490.051 179.192C491.767 180.191 495.512 168.956 498.789 170.766C502.721 173.044 506.403 193.766 506.621 194.858L433.941 152.885C434.129 152.604 437.936 147.455 445.207 151.325C454.038 156.006 452.977 141.869 456.441 142.368C456.597 142.368 456.753 142.431 456.909 142.524C458.439 143.398 459.624 147.549 460.935 151.793C462.246 156.037 463.712 160.343 465.866 161.56C465.959 161.623 466.053 161.654 466.147 161.716C470.859 163.776 470.453 145.926 474.26 148.11V148.079Z" fill="#44B5EF"/>
    </g>
    <path id="bd_graph1" d="M439.589 130.853L439.526 156.037L438.809 155.631L438.902 130.447L439.589 130.853Z" fill="#44B5EF"/>
    <path id="bd_graph" d="M457.129 137.033L457.035 162.216L456.349 161.811L456.411 136.627L457.129 137.033Z" fill="#44B5EF"/>
    <path id="bd_graph1" d="M474.478 144.305L474.416 169.489L473.698 169.083L473.761 143.899L474.478 144.305Z" fill="#44B5EF"/>
    <path id="bd_graph" d="M485.775 154.789L485.713 179.973L484.995 179.567L485.089 154.384L485.775 154.789Z" fill="#44B5EF"/>
    <path id="bd_graph1" d="M498.725 157.1L498.663 182.315L497.945 181.878L498.008 156.694L498.725 157.1Z" fill="#44B5EF"/>
    <g opacity="0.7">
    <path d="M464.961 101.457C468.768 103.673 470.672 121.055 473.137 124.113C473.199 124.206 473.293 124.269 473.355 124.3C473.948 124.643 474.885 124.113 476.133 123.801C477.381 123.458 478.973 123.333 480.845 124.425C481.781 124.987 482.811 125.829 483.903 127.14C488.522 132.695 487.898 138.499 491.736 140.715C493.952 141.994 495.855 142.556 497.697 143.617C499.07 144.397 500.38 145.458 501.722 147.237C504.905 151.45 507.308 154.633 507.433 154.789L431.32 111.974C431.695 111.318 438.56 98.8668 444.396 102.206C449.639 105.451 452.166 116.592 454.912 118.152C455.287 118.371 455.661 118.402 456.036 118.215C459.281 116.717 460.904 99.1476 464.93 101.457H464.961Z" fill="#EBA722"/>
    </g>
    <path id="bd_graph1" d="M443.334 90.0677L443.24 118.653L442.429 118.185L442.522 89.5996L443.334 90.0677Z" fill="#EBA722"/>
    <path id="bd_graph" d="M465.053 90.0677L464.96 118.653L464.148 118.185L464.242 89.5996L465.053 90.0677Z" fill="#EBA722"/>
    <path id="bd_graph1" d="M481.594 111.569L481.532 140.154L480.721 139.686L480.783 111.101L481.594 111.569Z" fill="#EBA722"/>
    <path id="bd_graph" d="M500.723 122.493L500.63 151.078L499.818 150.61L499.912 122.056L500.723 122.493Z" fill="#EBA722"/>
    <path d="M376.732 394.321V399.267C376.732 401.181 375.456 403.095 372.936 404.563V399.618C375.456 398.15 376.732 396.236 376.732 394.321Z" fill="#88B5EA"/>
    <path d="M376.731 394.419V399.364C376.731 399.62 376.668 399.875 376.636 400.098V395.153C376.7 394.898 376.731 394.642 376.731 394.419Z" fill="#88B5EA"/>
    <path d="M376.667 395.187V400.132C376.603 400.387 376.539 400.611 376.443 400.866V395.92C376.539 395.665 376.603 395.442 376.667 395.187Z" fill="#88B5EA"/>
    <path d="M376.446 395.919V400.864C376.35 401.12 376.222 401.375 376.095 401.598V396.653C376.254 396.398 376.35 396.174 376.446 395.919Z" fill="#88B5EA"/>
    <path d="M376.093 396.654V401.6C375.934 401.855 375.742 402.11 375.551 402.365V397.42C375.774 397.165 375.934 396.91 376.093 396.654Z" fill="#88B5EA"/>
    <path d="M375.583 397.452V402.398C375.327 402.685 375.072 402.972 374.753 403.259V398.314C375.072 398.026 375.327 397.739 375.583 397.452Z" fill="#88B5EA"/>
    <path d="M374.753 398.312V403.257C374.274 403.704 373.732 404.087 373.094 404.469V399.524C373.732 399.141 374.306 398.726 374.753 398.312Z" fill="#88B5EA"/>
    <path d="M373.126 399.524V404.47C373.126 404.47 372.999 404.534 372.967 404.566V399.62C372.967 399.62 373.094 399.556 373.126 399.524Z" fill="#88B5EA"/>
    <path d="M217.998 399.202V394.257C217.998 396.203 219.274 398.149 221.859 399.617V404.562C219.274 403.095 217.998 401.149 217.998 399.202Z" fill="#88B5EA"/>
    <path d="M221.859 399.619V404.564C219.274 403.097 217.998 401.15 217.998 399.204V394.259C217.998 396.205 219.274 398.151 221.859 399.619Z" fill="#88B5EA"/>
    <path d="M372.965 399.616L372.933 404.594L306.824 443.008L306.856 438.031L372.965 399.616Z" fill="#88B5EA"/>
    <path d="M288.383 438.031V443.008L221.859 404.594V399.616L288.383 438.031Z" fill="#88B5EA"/>
    <path d="M306.857 438.031V442.977C301.752 445.912 293.489 445.912 288.384 442.977V438.031C293.521 440.967 301.784 440.967 306.857 438.031Z" fill="#88B5EA"/>
    <path d="M306.858 438.03V442.976C305.964 443.486 305.007 443.901 303.986 444.22V439.275C305.039 438.956 305.996 438.509 306.858 438.03Z" fill="#88B5EA"/>
    <path d="M303.987 439.307V444.252C303.189 444.507 302.36 444.699 301.498 444.858V439.913C302.36 439.753 303.189 439.562 303.987 439.307Z" fill="#88B5EA"/>
    <path d="M301.53 439.913V444.858C300.956 444.954 300.413 445.05 299.839 445.082V440.136C300.413 440.073 300.956 440.009 301.53 439.913Z" fill="#88B5EA"/>
    <path d="M299.871 440.137V445.082C299.392 445.146 298.882 445.178 298.403 445.178V440.232C298.914 440.232 299.392 440.169 299.871 440.137Z" fill="#88B5EA"/>
    <path d="M298.403 440.231V445.177C297.956 445.177 297.509 445.177 297.062 445.177V440.231C297.509 440.231 297.956 440.231 298.403 440.231Z" fill="#88B5EA"/>
    <path d="M297.062 440.232V445.177C296.615 445.177 296.201 445.145 295.754 445.113V440.168C296.201 440.2 296.615 440.232 297.062 440.232Z" fill="#88B5EA"/>
    <path d="M295.787 440.169V445.114C295.34 445.082 294.926 445.018 294.511 444.986V440.041C294.957 440.105 295.372 440.137 295.787 440.169Z" fill="#88B5EA"/>
    <path d="M294.51 440.04V444.985C294.064 444.921 293.617 444.858 293.202 444.762V439.816C293.649 439.912 294.064 439.976 294.51 440.04Z" fill="#88B5EA"/>
    <path d="M293.233 439.817V444.763C292.755 444.667 292.308 444.571 291.861 444.443V439.498C292.308 439.626 292.787 439.721 293.233 439.817Z" fill="#88B5EA"/>
    <path d="M291.862 439.466V444.412C291.319 444.252 290.777 444.093 290.267 443.869V438.924C290.777 439.115 291.319 439.307 291.862 439.466Z" fill="#88B5EA"/>
    <path d="M290.267 438.924V443.869C289.597 443.614 288.959 443.295 288.385 442.976V438.03C288.991 438.381 289.629 438.668 290.267 438.924Z" fill="#88B5EA"/>
    <path d="M372.903 388.928C378.008 391.863 378.04 396.681 372.967 399.616L306.858 438.031C301.785 440.966 293.521 440.966 288.416 438.031L221.893 399.616C216.788 396.681 216.756 391.895 221.829 388.928L287.938 350.513C293.011 347.578 301.275 347.546 306.38 350.513L372.903 388.928Z" fill="#DFEDF7"/>
    <path id="bd_tricolor1" opacity="0.4" d="M325.14 367.582L302.263 392.564L336.977 411.74C318.344 422.556 288.033 422.556 269.305 411.74C250.544 400.924 250.448 383.375 269.081 372.527C284.205 363.721 307.049 362.094 325.172 367.582H325.14Z" fill="#15154F"/>
    <path id="bd_tricolor1" d="M325.139 345.186L325.107 353.098L302.23 378.081V370.168L325.139 345.186Z" fill="#E58B0A"/>
    <path id="bd_tricolor1" d="M255.107 377.507V369.626C255.107 376.773 259.829 383.92 269.273 389.376C288.033 400.192 318.312 400.192 336.945 389.376V397.256C318.28 408.073 287.97 408.073 269.241 397.256C259.797 391.801 255.075 384.654 255.107 377.507Z" fill="#E58B0A"/>
    <path id="bd_tricolor1" d="M336.946 389.347V397.227C333.724 399.078 330.182 400.609 326.449 401.854V393.973C330.246 392.761 333.756 391.229 336.946 389.347Z" fill="#E58B0A"/>
    <path id="bd_tricolor1" d="M326.48 393.974V401.854C323.544 402.812 320.513 403.545 317.387 404.12V396.239C320.513 395.665 323.576 394.931 326.48 393.974Z" fill="#E58B0A"/>
    <path id="bd_tricolor1" d="M317.419 396.206V404.087C315.377 404.438 313.335 404.725 311.293 404.948V397.068C313.367 396.876 315.409 396.589 317.419 396.206Z" fill="#E58B0A"/>
    <path id="bd_tricolor1" d="M311.293 397.068V404.949C309.506 405.109 307.752 405.236 305.965 405.3V397.419C307.752 397.356 309.538 397.228 311.293 397.068Z" fill="#E58B0A"/>
    <path id="bd_tricolor1" d="M305.997 397.42V405.301C304.337 405.364 302.71 405.364 301.083 405.301V397.42C302.742 397.452 304.369 397.452 305.997 397.42Z" fill="#E58B0A"/>
    <path id="bd_tricolor1" d="M301.082 397.451V405.332C299.487 405.3 297.924 405.205 296.36 405.077V397.196C297.956 397.324 299.519 397.42 301.082 397.451Z" fill="#E58B0A"/>
    <path id="bd_tricolor1" d="M296.393 397.195V405.076C294.798 404.948 293.267 404.789 291.703 404.565V396.685C293.267 396.908 294.83 397.067 296.393 397.195Z" fill="#E58B0A"/>
    <path id="bd_tricolor1" d="M291.735 396.684V404.565C290.108 404.341 288.513 404.054 286.981 403.735V395.854C288.577 396.174 290.14 396.461 291.735 396.684Z" fill="#E58B0A"/>
    <path id="bd_tricolor1" d="M286.98 395.856V403.737C285.257 403.386 283.598 402.971 281.971 402.524V394.644C283.63 395.122 285.289 395.505 286.98 395.856Z" fill="#E58B0A"/>
    <path id="bd_tricolor1" d="M282.003 394.61V402.491C279.993 401.917 278.047 401.279 276.164 400.545V392.664C278.047 393.398 279.993 394.068 282.003 394.642V394.61Z" fill="#E58B0A"/>
    <path id="bd_tricolor1" d="M276.165 392.665V400.545C273.708 399.588 271.411 398.472 269.241 397.227C259.797 391.771 255.075 384.624 255.107 377.477V369.597C255.107 376.744 259.829 383.891 269.273 389.346C271.442 390.591 273.74 391.707 276.165 392.665Z" fill="#E58B0A"/>
    <path id="bd_tricolor1" d="M325.14 345.185L302.263 370.167L336.977 389.343C318.344 400.159 288.033 400.159 269.305 389.343C250.544 378.527 250.448 360.978 269.081 350.13C284.205 341.324 307.049 339.665 325.172 345.185H325.14Z" fill="#FFB420"/>
    <path id="bd_tricolor1" d="M243.998 302.963V308.945C243.998 311.262 242.445 313.578 239.416 315.36V309.378C242.471 307.596 243.998 305.279 243.998 302.963Z" fill="#88B5EA"/>
    <path id="bd_tricolor1" d="M243.998 303.092V309.074C243.973 309.379 243.947 309.685 243.871 309.99V304.008C243.947 303.703 243.973 303.397 243.998 303.092Z" fill="#88B5EA"/>
    <path id="bd_tricolor1" d="M243.896 304.008V309.99C243.82 310.295 243.743 310.575 243.616 310.881V304.899C243.743 304.593 243.82 304.313 243.896 304.008Z" fill="#88B5EA"/>
    <path id="bd_tricolor1" d="M243.644 304.898V310.881C243.516 311.186 243.364 311.492 243.211 311.772V305.789C243.389 305.484 243.542 305.204 243.644 304.898Z" fill="#88B5EA"/>
    <path id="bd_tricolor1" d="M243.211 305.79V311.772C243.007 312.103 242.803 312.409 242.549 312.714V306.732C242.803 306.426 243.032 306.121 243.211 305.79Z" fill="#88B5EA"/>
    <path id="bd_tricolor1" d="M242.574 306.731V312.714C242.268 313.07 241.937 313.426 241.581 313.757V307.775C241.963 307.444 242.294 307.088 242.574 306.731Z" fill="#88B5EA"/>
    <path id="bd_tricolor1" d="M241.607 307.775V313.758C241.021 314.267 240.359 314.776 239.596 315.234V309.252C240.385 308.794 241.047 308.285 241.607 307.775Z" fill="#88B5EA"/>
    <path id="bd_tricolor1" d="M239.621 309.252V315.234C239.621 315.234 239.468 315.31 239.417 315.336V309.354C239.417 309.354 239.57 309.277 239.621 309.252Z" fill="#88B5EA"/>
    <path id="bd_tricolor1" d="M52.2881 308.868V302.886C52.2881 305.228 53.8409 307.595 56.9465 309.377V315.359C53.8155 313.577 52.2881 311.21 52.2881 308.868Z" fill="#88B5EA"/>
    <path d="M56.9456 309.379V315.361C53.8145 313.579 52.2871 311.212 52.2871 308.87V302.888C52.2871 305.23 53.8399 307.597 56.9456 309.379Z" fill="#88B5EA"/>
    <path d="M239.415 309.352L239.39 315.359L159.56 361.74L159.585 355.758L239.415 309.352Z" fill="#88B5EA"/>
    <path d="M137.286 355.759V361.741L56.9209 315.36L56.9463 309.378L137.286 355.759Z" fill="#88B5EA"/>
    <path d="M159.586 355.759V361.741C153.451 365.305 143.446 365.305 137.286 361.741V355.759C143.472 359.323 153.451 359.323 159.586 355.759Z" fill="#88B5EA"/>
    <path d="M159.586 355.76V361.742C158.517 362.353 157.346 362.862 156.124 363.269V357.287C157.371 356.88 158.542 356.371 159.586 355.76Z" fill="#88B5EA"/>
    <path d="M156.124 357.287V363.269C155.157 363.575 154.138 363.829 153.12 364.008V358.025C154.164 357.847 155.157 357.593 156.124 357.287Z" fill="#88B5EA"/>
    <path d="M153.146 358.024V364.007C152.459 364.134 151.797 364.21 151.109 364.287V358.304C151.797 358.228 152.484 358.152 153.146 358.024Z" fill="#88B5EA"/>
    <path d="M151.135 358.306V364.288C150.55 364.339 149.964 364.39 149.379 364.415V358.433C149.99 358.433 150.575 358.382 151.135 358.306Z" fill="#88B5EA"/>
    <path d="M149.378 358.406V364.388C148.818 364.388 148.284 364.388 147.749 364.388V358.406C148.309 358.406 148.844 358.406 149.378 358.406Z" fill="#88B5EA"/>
    <path d="M147.775 358.433V364.415C147.24 364.415 146.731 364.364 146.196 364.339V358.356C146.731 358.407 147.24 358.433 147.775 358.433Z" fill="#88B5EA"/>
    <path d="M146.222 358.331V364.313C145.687 364.262 145.178 364.211 144.669 364.135V358.152C145.204 358.229 145.713 358.28 146.222 358.331Z" fill="#88B5EA"/>
    <path d="M144.695 358.177V364.16C144.16 364.083 143.625 364.007 143.116 363.88V357.897C143.651 357.999 144.16 358.101 144.695 358.177Z" fill="#88B5EA"/>
    <path d="M143.117 357.899V363.881C142.531 363.753 141.996 363.626 141.462 363.473V357.491C142.022 357.644 142.557 357.771 143.117 357.899Z" fill="#88B5EA"/>
    <path d="M141.486 357.49V363.472C140.825 363.294 140.163 363.065 139.552 362.836V356.854C140.188 357.108 140.825 357.312 141.486 357.49Z" fill="#88B5EA"/>
    <path d="M139.578 356.854V362.837C138.764 362.531 138 362.149 137.287 361.742V355.76C138.025 356.167 138.764 356.523 139.578 356.854Z" fill="#88B5EA"/>
    <path d="M239.34 296.445C245.501 300.009 245.552 305.788 239.417 309.352L159.586 355.758C153.452 359.322 143.473 359.322 137.312 355.758L56.9477 309.352C50.7874 305.788 50.7619 300.009 56.8969 296.445L136.727 250.039C142.862 246.475 152.841 246.475 159.001 250.039L239.366 296.445H239.34Z" fill="#DFEDF7"/>
    <path id="bd_cubebox1" d="M139.271 214.224L119.976 202.794L100.527 213.995L119.976 225.221L139.271 214.224Z" fill="#DE791E"/>
    <path id="bd_cubebox2" d="M165.746 257.549L146.527 245.967L127.027 257.346L146.527 268.419L165.746 257.549Z" fill="#3A88D6"/>
    <path id="db_cubebox3" d="M174.555 300.697L193.774 289.802L174.555 278.22L155.056 289.599L174.555 300.697Z" fill="#15154F"/>
    <g id="bd_cubebox1">
    <path opacity="0.17" d="M139.349 197.932L120.053 186.502L100.604 197.703L120.053 208.929L139.349 197.932Z" fill="#DE791E"/>
    <path opacity="0.33" d="M139.349 215.625L120.053 204.195L100.604 215.396L120.053 226.622L139.349 215.625Z" fill="#DE791E"/>
    <path opacity="0.5" d="M139.349 233.316L120.053 221.887L100.604 233.087L120.053 244.313L139.349 233.316Z" fill="#DE791E"/>
    <path opacity="0.67" d="M139.349 251.008L120.053 239.578L100.604 250.779L120.053 262.005L139.349 251.008Z" fill="#DE791E"/>
    <path opacity="0.83" d="M139.349 268.7L120.053 257.271L100.604 268.471L120.053 279.697L139.349 268.7Z" fill="#DE791E"/>
    <path d="M139.349 286.392L120.053 274.962L100.604 286.188L120.053 297.389L139.349 286.392Z" fill="#DE791E"/>
    </g>
    <path id="bd_cubebox1" d="M139.247 288.656L139.272 214.223L119.977 225.22V299.78L139.247 288.656Z" fill="#EBA722"/>
    <path id="bd_cubebox1" d="M119.976 299.78V225.219L100.527 213.993V288.579L119.976 299.78Z" fill="#FFB420"/>
    <path id="bd_cubebox1" d="M139.271 214.223L119.976 202.793L100.527 213.994L119.976 225.22L139.271 214.223Z" fill="#E58B0A"/>
    <g id="bd_cubebox2">
    <path opacity="0.17" d="M165.822 241.513L146.603 229.905L127.104 241.284L146.603 252.358L165.822 241.513Z" fill="#3A88D6"/>
    <path opacity="0.33" d="M165.822 253.732L146.603 242.149L127.104 253.528L146.603 264.602L165.822 253.732Z" fill="#3A88D6"/>
    <path opacity="0.5" d="M165.822 265.977L146.603 254.395L127.104 265.748L146.603 276.847L165.822 265.977Z" fill="#3A88D6"/>
    <path opacity="0.67" d="M165.822 278.221L146.603 266.639L127.104 277.992L146.603 289.065L165.822 278.221Z" fill="#3A88D6"/>
    <path opacity="0.83" d="M165.822 290.464L146.603 278.882L127.104 290.235L146.603 301.309L165.822 290.464Z" fill="#3A88D6"/>
    <path d="M165.822 302.709L146.603 291.127L127.104 302.48L146.603 313.554L165.822 302.709Z" fill="#3A88D6"/>
    </g>
    <path id="bd_cubebox2" d="M146.45 268.217L165.746 257.551V304.212L146.45 315.361V268.217Z" fill="#43B5EF"/>
    <path id="bd_cubebox2" d="M146.45 315.361V268.216L127.027 257.347V304.135L146.45 315.361Z" fill="#3BA7DE"/>
    <path id="bd_cubebox2" d="M165.746 257.55L146.527 245.968L127.027 257.347L146.527 268.42L165.746 257.55Z" fill="#279EDB"/>
    <g id="db_cubebox3">
    <path opacity="0.17" d="M174.784 270.303L194.003 259.408L174.758 247.825L155.259 259.179L174.784 270.303Z" fill="#15154F"/>
    <path opacity="0.33" d="M174.784 282.14L194.003 271.245L174.758 259.662L155.259 271.016L174.784 282.14Z" fill="#15154F"/>
    <path opacity="0.5" d="M174.784 293.977L194.003 283.082L174.758 271.499L155.259 282.878L174.784 293.977Z" fill="#15154F"/>
    <path opacity="0.67" d="M174.784 305.84L194.003 294.945L174.758 283.362L155.259 294.716L174.784 305.84Z" fill="#15154F"/>
    <path opacity="0.83" d="M174.784 317.677L194.003 306.782L174.758 295.199L155.259 306.553L174.784 317.677Z" fill="#15154F"/>
    <path d="M174.784 329.514L194.003 318.619L174.758 307.036L155.259 318.415L174.784 329.514Z" fill="#15154F"/>
    </g>
    <path id="db_cubebox3" d="M174.707 331.474L194.003 320.324L193.926 289.955L174.707 300.85V331.474Z" fill="#214199"/>
    <path id="db_cubebox3" d="M174.707 331.472V300.849L155.183 289.725L155.284 320.246L174.707 331.472Z" fill="#223A7A"/>
    <path id="db_cubebox3" d="M174.707 300.85L193.927 289.955L174.682 278.372L155.183 289.725L174.707 300.85Z" fill="#223A7A"/>
    <g opacity="0.1">
    <path d="M254.522 337.199C269.43 345.782 269.43 359.636 254.673 368.219C239.916 376.802 215.973 376.803 201.216 368.219C186.308 359.636 186.308 345.782 200.915 337.199C215.671 328.767 239.765 328.616 254.522 337.199Z" fill="black"/>
    </g>
    <path d="M239.31 351.807C239.461 352.711 239.31 354.066 238.859 354.515C236.901 356.924 233.588 354.515 232.986 354.063C232.23 353.762 229.522 354.515 228.017 354.515C227.027 354.533 226.052 354.275 225.2 353.77C224.349 353.264 223.656 352.531 223.198 351.654C220.94 347.889 230.881 348.341 231.028 348.19C232.083 348.043 239.009 350.904 239.31 351.807Z" fill="#5BB0FF"/>
    <path d="M223.801 349.396C223.698 349.817 223.699 350.256 223.804 350.676C223.909 351.096 224.115 351.484 224.404 351.806C225.91 353.161 228.62 353.462 229.976 353.161C230.632 353.049 231.304 353.071 231.951 353.227C232.598 353.382 233.207 353.667 233.74 354.065C234.644 354.667 237.96 355.119 239.312 353.161C239.306 353.649 239.148 354.123 238.86 354.516C236.902 356.926 233.59 354.516 232.987 354.065C232.232 353.764 229.524 354.516 228.018 354.516C227.028 354.535 226.053 354.276 225.202 353.771C224.351 353.266 223.657 352.533 223.199 351.655C222.597 350.601 222.897 349.849 223.801 349.396Z" fill="#3D93FF"/>
    <path d="M235.849 268.985C235.849 268.985 244.281 281.333 238.258 299.102C236.903 302.867 234.343 311.149 235.095 315.215C236.15 320.033 237.655 318.678 238.86 334.79C239.764 348.945 239.616 345.783 239.914 351.505C240.065 353.161 234.042 354.968 233.138 352.408C229.072 341.262 223.651 317.925 224.706 305.125C225.91 292.326 226.512 280.279 226.512 280.279C226.512 280.279 231.933 272.6 235.849 268.985Z" fill="#223A7A"/>
    <path d="M220.338 360.239C220.387 361.223 220.065 362.189 219.435 362.947C217.026 365.055 214.014 361.893 213.411 361.291C212.659 360.838 209.798 361.14 208.292 360.838C207.238 360.654 206.252 360.195 205.432 359.508C204.613 358.821 203.989 357.929 203.624 356.924C201.967 352.556 212.207 354.966 212.358 354.966C213.562 354.818 220.189 359.185 220.338 360.239Z" fill="#5BB0FF"/>
    <path d="M204.678 354.667C204.475 355.077 204.395 355.537 204.449 355.991C204.502 356.446 204.686 356.875 204.979 357.226C206.184 358.883 209.045 359.786 210.4 359.786C211.869 359.817 213.27 360.41 214.315 361.443C215.071 362.199 218.381 363.401 220.188 361.594C220.002 362.079 219.748 362.536 219.432 362.949C217.023 365.057 214.011 361.895 213.408 361.292C212.656 360.841 209.795 361.142 208.289 360.841C207.235 360.656 206.249 360.197 205.429 359.51C204.61 358.822 203.986 357.931 203.621 356.925C203.172 355.571 203.774 354.969 204.678 354.667Z" fill="#3D93FF"/>
    <path d="M233.137 280.732C233.137 280.732 239.914 287.961 227.716 306.181C227.716 306.181 221.844 319.131 222.145 323.347C222.295 328.317 224.404 328.768 222.447 345.031C220.639 359.035 221.392 359.337 220.338 360.541C219.133 361.746 213.411 360.541 213.261 358.132C212.207 344.278 209.948 323.95 211.303 311.152C212.658 296.395 213.712 284.349 213.712 284.349C213.712 284.349 228.62 283.592 233.137 280.732Z" fill="#2C4481"/>
    <path d="M213.712 284.345C213.712 284.345 217.176 284.194 221.242 283.589C218.832 290.516 214.164 305.122 214.466 318.072C214.767 330.42 215.369 349.996 215.821 360.537C214.466 359.935 213.261 359.181 213.261 358.128C212.207 344.273 209.948 323.945 211.303 311.147C212.658 296.391 213.712 284.345 213.712 284.345Z" fill="#223A7A"/>
    <path d="M230.88 247.45C230.88 247.45 230.124 241.878 235.548 241.728C241.27 241.577 240.065 253.474 240.065 253.474C240.065 253.474 239.914 263.865 238.86 267.026C237.505 271.245 230.127 282.386 230.127 282.386C230.127 282.386 226.663 281.63 225.76 279.223C225.76 279.223 231.632 268.832 232.084 264.767C232.687 260.551 230.729 248.504 230.88 247.45Z" fill="#ECB00E"/>
    <path d="M225.76 279.221L226.061 278.465C227.115 279.368 228.47 280.422 229.072 279.519C229.976 278.164 236 272.591 236.301 266.87C236.752 261.148 235.849 259.19 236.15 252.565C236.451 246.393 236.15 243.529 231.93 243.379C232.533 242.476 232.984 241.422 235.544 241.572C242.02 242.024 240.062 253.468 240.062 253.468C240.062 253.468 239.911 263.859 238.857 267.02C237.502 271.237 230.123 282.38 230.123 282.38C230.123 282.38 226.663 281.633 225.76 279.221Z" fill="#ECB00E"/>
    <path d="M213.261 286.904C213.261 286.904 216.122 270.942 213.261 253.625C213.261 253.625 211.755 247.753 221.694 243.837C231.782 239.772 238.107 239.618 238.408 245.193C238.86 250.614 236.3 257.992 236.3 260.552C235.999 269.738 238.559 273.651 240.366 279.526C242.023 285.699 212.81 292.776 213.261 286.904Z" fill="#FEBA02"/>
    <path d="M234.044 232.996C231.336 236.008 229.825 239.321 230.58 243.086C231.785 247.753 220.491 245.344 221.696 243.688C223.804 240.827 222.298 236.46 222.298 236.46L234.044 232.996Z" fill="#FFAA83"/>
    <path d="M222.747 239.621C224.261 239.307 225.729 238.801 227.114 238.115H227.265L228.319 238.417L228.77 238.567C228.77 238.567 228.015 243.687 221.994 243.085C222.461 241.988 222.716 240.813 222.747 239.621Z" fill="#F79F7C"/>
    <path id="bd_boyhair" d="M233.138 230.737C228.921 241.73 219.134 240.373 217.327 239.019C214.315 236.61 215.971 231.942 217.929 226.521C219.736 221.25 220.941 218.69 220.941 218.69C229.824 214.775 235.848 223.81 233.138 230.737Z" fill="#FFAA83"/>
    <path id="bd_boyhair" d="M234.945 221.401C239.462 226.822 234.643 234.652 231.632 237.664C229.825 239.32 228.319 239.923 226.813 238.869C225.759 238.266 226.512 234.649 226.964 232.544C227.566 230.134 225.759 229.836 225.006 230.436C222.898 231.791 222.898 232.996 222.145 232.393C220.79 231.189 224.705 224.563 220.489 223.359C215.068 221.852 217.628 218.69 221.244 217.636C224.253 216.733 230.276 215.98 234.945 221.401Z" fill="#3A2528"/>
    <path id="bd_boyhand" d="M190.975 249.41C190.027 248.891 189.205 248.172 188.565 247.301C187.361 245.645 187.512 244.892 187.21 243.537C186.908 242.182 186.909 241.429 187.361 241.128C188.116 240.676 189.62 241.278 190.373 243.836C191.125 246.544 193.685 246.847 193.986 247.299C194.438 247.904 191.577 249.711 190.975 249.41Z" fill="#FFAA83"/>
    <path id="bd_boyhand" d="M191.275 246.852C190.974 246.701 191.576 245.647 190.673 242.937C189.917 240.829 191.877 243.088 192.028 243.692C192.178 244.144 193.986 247.457 193.986 247.457C193.049 247.452 192.125 247.246 191.275 246.852Z" fill="#FFAA83"/>
    <path id="bd_boyhand" d="M201.213 263.263C197.449 263.865 190.371 249.56 190.371 249.56C194.136 248.805 193.985 246.247 193.985 246.247C193.985 246.247 201.515 255.734 202.267 255.883C204.074 256.033 214.615 245.192 219.284 245.794C223.048 246.245 223.198 250.612 222.897 251.817C221.542 257.541 207.688 262.36 201.213 263.263Z" fill="#ECB00E"/>
    <defs>
    <linearGradient id="paint0_linear_2249_152" x1="0" y1="181.523" x2="599.999" y2="181.523" gradientUnits="userSpaceOnUse">
    <stop stopColor="#CCCCCC"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint1_linear_2249_152" x1="0" y1="101.357" x2="599.999" y2="101.357" gradientUnits="userSpaceOnUse">
    <stop stopColor="#F3F5F8"/>
    <stop offset="1" stopColor="#A1BAD2"/>
    </linearGradient>
    <linearGradient id="paint2_linear_2249_152" x1="427.047" y1="58.146" x2="449.391" y2="67.2272" gradientUnits="userSpaceOnUse">
    <stop stopColor="#8BC4FC"/>
    <stop offset="1" stopColor="#3A88D6"/>
    </linearGradient>
    <linearGradient id="paint3_linear_2249_152" x1="320.569" y1="-15.2222" x2="491.394" y2="215.706" gradientUnits="userSpaceOnUse">
    <stop stopColor="#8BC4FC"/>
    <stop offset="1" stopColor="#3A88D6"/>
    </linearGradient>
    <linearGradient id="paint4_linear_2249_152" x1="350.558" y1="78.3357" x2="381.078" y2="77.2747" gradientUnits="userSpaceOnUse">
    <stop stopColor="#F76820"/>
    <stop offset="1" stopColor="#FCCF3A"/>
    </linearGradient>
    <linearGradient id="paint5_linear_2249_152" x1="350.555" y1="78.6798" x2="381.075" y2="77.6188" gradientUnits="userSpaceOnUse">
    <stop stopColor="#F76820"/>
    <stop offset="1" stopColor="#FCCF3A"/>
    </linearGradient>
    <linearGradient id="paint6_linear_2249_152" x1="350.555" y1="77.9301" x2="381.044" y2="76.8691" gradientUnits="userSpaceOnUse">
    <stop stopColor="#F76820"/>
    <stop offset="1" stopColor="#FCCF3A"/>
    </linearGradient>
    <linearGradient id="paint7_linear_2249_152" x1="350.523" y1="77.5865" x2="381.043" y2="76.5567" gradientUnits="userSpaceOnUse">
    <stop stopColor="#F76820"/>
    <stop offset="1" stopColor="#FCCF3A"/>
    </linearGradient>
    <linearGradient id="paint8_linear_2249_152" x1="350.523" y1="77.4928" x2="381.043" y2="76.4318" gradientUnits="userSpaceOnUse">
    <stop stopColor="#F76820"/>
    <stop offset="1" stopColor="#FCCF3A"/>
    </linearGradient>
    <linearGradient id="paint9_linear_2249_152" x1="350.523" y1="77.524" x2="381.044" y2="76.463" gradientUnits="userSpaceOnUse">
    <stop stopColor="#F76820"/>
    <stop offset="1" stopColor="#FCCF3A"/>
    </linearGradient>
    <linearGradient id="paint10_linear_2249_152" x1="350.524" y1="77.6489" x2="381.045" y2="76.619" gradientUnits="userSpaceOnUse">
    <stop stopColor="#F76820"/>
    <stop offset="1" stopColor="#FCCF3A"/>
    </linearGradient>
    <linearGradient id="paint11_linear_2249_152" x1="350.556" y1="77.8675" x2="381.044" y2="76.8377" gradientUnits="userSpaceOnUse">
    <stop stopColor="#F76820"/>
    <stop offset="1" stopColor="#FCCF3A"/>
    </linearGradient>
    <linearGradient id="paint12_linear_2249_152" x1="350.555" y1="78.055" x2="381.075" y2="77.0252" gradientUnits="userSpaceOnUse">
    <stop stopColor="#F76820"/>
    <stop offset="1" stopColor="#FCCF3A"/>
    </linearGradient>
    </defs>
    </svg>
    </>
  )
}
