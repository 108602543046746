import React from 'react';
import styles from "./UI.module.css"

export default function Title(props) {
  return (
    <div className={styles.title}>
        <h2>{props.mainTitle}</h2>
        <p>{props.subTitle}</p>
    </div>
  )
}
