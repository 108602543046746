import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import PageTopHead from '../../components/UI/PageTopHead'
import FlipCard from '../../components/FlipCard/FlipCard'
import './ServiceLayout.css'
import OwlCarousel from 'react-owl-carousel'; 
import '../../../node_modules/owl.carousel/dist/assets/owl.carousel.css';
import '../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css';
import CImg1 from '../../assets/images/services/cloud/alcyone_cloud_img1.jpg'
import CImg2 from '../../assets/images/services/cloud/alcyone_cloud_img2.jpg'
import CImg3 from '../../assets/images/services/cloud/alcyone_cloud_img3.jpg'
import CImg4 from '../../assets/images/services/cloud/alcyone_cloud_img4.jpg'
import CImg5 from '../../assets/images/services/cloud/alcyone_cloud_img5.jpg'
import WhyCloud from "../../data/why_cloud.json";
import faqs from "../../data/faqs.json";
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

export default function CloudServices() {
    const options = {
        
        responsive: {
            0: {
                items: 1,
                // stagePadding: 50,
            },
            450: {
                items: 1,
                stagePadding: 50,
            },
            600: {
                items: 2,
                stagePadding: 50,
            },
            1000: {
                items: 4,
            },
            1600: {
                items: 5,
    
            }
        },
    };
    // const dataFaq = faqs.ai_faq;
  return (
    <>
    <Helmet>
    <title>Cloud Service Provider Company India & USA | Cloud IT Solutions</title>
    <meta name="description" content="Skyrocket your business with our Cloud magic! We're your go-to Cloud Service Provider in India and the USA. Unleash innovation and explore our offerings now. " />
  </Helmet>
    <div className='inner_page pb-5'>
        <PageTopHead pageTitle="Cloud Service Provider" description="Partner with us for smooth IT transitions: migrate, optimize, modernize, and manage."/>
        <Container>
            <Row>
                <Col>
                <div className="big_title my-5 py-5 scroll-reveal">
                    <p><span>Welcome to a digital realm where innovation meets reliability. As the best Cloud Service Provider Company operating across the world, we redefine the possibilities of Cloud IT Solutions. Join us on a journey that transcends boundaries, propelling your business to new heights in the ever-evolving landscape of technology.</span></p>
                </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2 className='new_title text-center'>Our Cloud Service Offerings </h2>
                </Col>
            </Row>
            <Row xs={1} md={2} lg={3} className='ser_cards_grid mt-4'>
                <Col>
                    <FlipCard title="Infrastructure as a Service (IaaS)" desc="Lay a robust digital foundation with our Infrastructure as a Service (IaaS). From servers to networking, we provide the backbone for your digital infrastructure, ensuring scalability and efficiency. " imageurl={CImg1} />
                </Col>
                <Col>
                    <FlipCard title="Platform as a Service (PaaS)" desc="Streamline your application development process with our Platform as a Service. We provide a comprehensive platform, allowing your development team to focus on creating innovative solutions without the hassle of managing the underlying infrastructure. " imageurl={CImg2} />
                </Col>
                <Col>
                    <FlipCard title="Software as a Service (SaaS)" desc="Experience software solutions without the complexities. Our Software as Service offerings are designed to provide you with access to software applications over the internet, eliminating the need for installations and maintenance. " imageurl={CImg3} />
                </Col>
                <Col>
                    <FlipCard title="Cloud Storage Solutions" desc="Transform your data storage with our Cloud Storage Solutions. Enjoy the benefits of secure and accessible data storage, ensuring that your critical information is available whenever and wherever you need it. " imageurl={CImg4} />
                </Col>
                <Col>
                    <FlipCard title="Managed Cloud Services" desc="Optimize your operations with our Managed Cloud Services. Our experts become an extension of your IT team, ensuring the health, security, and performance of your cloud infrastructure. " imageurl={CImg5} />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div className="big_title mt-5 pt-5 mb-5">
                        <h2 className='new_title text-center'>The Ideal Cloud Platform</h2>
                        <p>To decide the best fit for your company, assess specific needs, considering factors like flexibility, integration, technology stack, and future growth plans. </p>
                    </div>
                </Col>
            </Row>
            <Row xs={1} md={2} lg={3}>
                <Col>
                    <div className="white_card aws_card">
                        <h3>AWS (Amazon Web Services)</h3>
                        <ul className='ps-1'>
                            <li>Ideal for flexibility and control. </li>
                            <li>Extensive range of services. </li>
                            <li>Widely used for various applications and industries. </li>
                        </ul>
                    </div>
                </Col>
                <Col>
                    <div className="white_card azure_card">
                        <h3>Microsoft Azure</h3>
                        <ul className='ps-1'>
                            <li>Emphasizes simplicity and integration.</li>
                            <li>Strong support for Microsoft technologies. </li>
                            <li>Well-suited for businesses using Microsoft products. </li>
                        </ul>
                    </div>
                </Col>
                <Col>
                    <div className="white_card gcp_card">
                        <h3>Google Cloud Platform (GCP)</h3>
                        <ul className='ps-1'>
                            <li>Excellent for data analytics and machine learning. </li>
                            <li>Offers innovative solutions for emerging technologies.</li> 
                            <li>Known for its robust infrastructure and global network.</li>
                        </ul>
                    </div>
                </Col>
                <Col  xs={12} md={8} lg={7} className='mx-auto mt-5'><p className='py-4 text-center'>At Alcyone Technologies, we specialize in more than just the big cloud names! Sure, AWS, Azure, and GCP are stars, but we bring expertise to the table that goes beyond. </p></Col>
            </Row>
            <Row>
                <Col md={6} className='p-5'>
                    <div className="big_title mt-5 pt-5 mb-2">
                        <h2 className='fs-2'>Embark on a Cloud Journey with Us</h2>
                    </div>
                    <Row xs={1} md={1} lg={1}>
                        <Col>
                            <div className="white_card">
                                <h3>Personalized Consultation</h3>
                                <p>Begin your cloud journey with a personalized consultation. Our experts assess your business needs, goals, and challenges to tailor Cloud IT Solutions that align perfectly with your unique requirements. </p>
                            </div>
                        </Col>
                        <Col>
                            <div className="white_card">
                                <h3>Efficient Implementation</h3>
                                <p>Experience an efficient implementation process that prioritizes minimal disruption and quick wins. Our team ensures that your transition to the cloud is smooth, with maximum benefits right from the start.</p>
                            </div>
                        </Col>
                        <Col>
                            <div className="white_card">
                                <h3>Continuous Support and Optimization</h3>
                                <p>Our commitment doesn't end with implementation. We provide continuous support and optimization services, ensuring that your cloud environment evolves in tandem with your business growth. </p>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col md={6} className='mt-5 p-5'>
                    <div className="big_title mt-5 pt-5 mb-2">
                        <h2 className='fs-2 '>Future-Ready Cloud Solutions for Your Business Success </h2>
                    </div>
                    <Row xs={1} md={1} lg={1}>
                        <Col>
                            <div className="white_card">
                                <h3>Adapting to Emerging Technologies</h3>
                                <p>The digital landscape is ever-changing, and so are we. Our Cloud Service Provider offerings are designed to adapt seamlessly to emerging technologies, keeping your business ahead of the curve. </p>
                            </div>
                        </Col>
                        <Col>
                            <div className="white_card">
                                <h3>Cost-Effective Solutions for ROI</h3>
                                <p>We understand the importance of cost-effectiveness. Our Cloud IT Solutions are not just about innovation; they are about maximizing your return on investment (ROI) through smart and scalable solutions.</p>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            
            <Row>
                <Col md={12}>
                    <div className="big_title mt-5 pt-5 mb-5">
                        <h2 className='new_title text-center'>Why Choose Us as Your Cloud Service Provider?</h2>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <OwlCarousel id='serCaro' className='owl-theme es_item_list industries_carousel' margin={8} nav dots={false}  {...options}>
                        {WhyCloud.toolbox.map((item, key) => (
                            <div className="item" key={key}>
                                <div className="fea_ser_home_card">
                                    <Image src={`${item.url}`} alt={item.title} />
                                    <div className='fea_ser_home_card_details'>
                                        <div className="fsh_title">
                                            <h3>{item.title}</h3>
                                        </div>
                                        <p>{item.description}</p>
                                    </div>
                                    
                                </div>
                            </div>
                        ))}
                        
                    </OwlCarousel>
                </Col>
            </Row>
        </Container>
        <div className="percentage_sec bg_img01 py-5 mt-5">
            <Container>
                <Row  className='my-5 py-5'>
                    <Col xs={12} md={6} lg={5}>
                    
                    <div className="text-white info_block">
                        <h2>Effortlessly migrate your business data to the cloud with enhanced security.</h2>
                        <Link className='btn btn-primary' to="/contact">Talk to our cloud experts</Link>
                    </div>
                    </Col>
                    <Col xs={12} md={6} lg={7}>
                    
                    <div className="text-white">
                        <ul>
                            <li><b>37</b><small>Cloud apps perform better.</small></li>
                            <li><b>45</b><small>Savings on cloud consumption</small></li>
                            <li><b>70</b><small>Productivity surge for IT professionals.</small></li>
                            <li><b>99.9</b><small>Uninterrupted business with max uptime. </small></li>
                            <li><b>100</b><small>Guaranteed data integrity and security</small></li>
                        </ul>
                    </div>
                    </Col>
                </Row>
            </Container>
        </div>
        <Container>
            <Row xs={1} md={2} lg={2} className='my-5 py-5 big_text_sticky'>
                <Col>
                
                <div className="big_title scroll-reveal sticky-top pt-5">
                    <h2 className='new_title mt-5 mx-auto'><span>Explore Cloud Excellence with the Best Service Provider</span> </h2>
                </div>
                </Col>
                <Col>
                
                <div className="big_title scroll-reveal">
                    <p><span>Are you ready to elevate your business with Cloud IT Solutions? Explore a new era of reliability, scalability, and innovation with us at Alcyone Technologies as your Cloud Service Provider.</span> </p>
                    <p><span> Get in touch with us now to kickstart your path to digital excellence. Welcome to a future-ready, innovative, and secure cloud experience. </span> </p>
                </div>
                </Col>
            </Row>
        </Container>
    </div>
    </>
  )
}
