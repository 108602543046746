import React from 'react'
import { Container, Row, Col} from 'react-bootstrap'
import Title from '../components/UI/Title';
import HomeMMS from '../components/MMS/HomeMMS';
import Technologies from '../components/Techs/Technologies';
import ActionBox from '../components/UI/ActionBox';
import redesignIcon from '../assets/images/icons/redesign.svg'
import ideaIcon from '../assets/images/icons/idea.svg'
import Hero from '../components/Hero/Hero';
// import ServiceCarousel from '../components/Slider/ServiceCarousel';
// import ClientLogo from '../components/Clients/ClientLogo';
// import ClientReview from '../components/Clients/ClientReview';
// import MileStones from '../components/UI/MileStones';
// import WorkPlace from '../components/WorkPlace/WorkPlace';
import FeaServices from '../components/FeaturedServices/FeaServices';
import ExpandedServices from '../components/ExpandedServices/ExpandedServices';
import { Helmet } from 'react-helmet';

export default function Home() {
  return (
    <>
    <Helmet>
      <title>IT support & custom software development services company | India, USA</title>
      <meta name="description" content="Empower your business with our leading IT support and custom software development services in India & the USA. Let's innovate and succeed together!" />
      <meta name='keywords' content='IT support services, IT support company, Custom software development services, Custom software development company, IT support and services company, IT support services in India, IT support services in USA, IT support company in India, IT support company in USA, Custom software development services in India, Custom software development services in USA, Custom development services, Software development company, Software development company in India, Software development company in USA, Software development services in India, Software development services in USA, Software development services, Managed IT solutions, Managed IT services, IT services in India, IT services in USA, IT company in India, IT company in USA, Best IT services '/>
    </Helmet>
    <Hero/>
    {/* <MileStones/> */}
    <Container className='py-5'>
        <Row>
            <Col lg={12}>
              <div className="big_title my-5 py-5">
                <h1>IT Support Services & Custom Software Development Company</h1>
                {/* <p><span>Welcome to the hub of innovation and technological excellence!</span> As a leading IT services company, Alcyone Technologies specializes in delivering cutting-edge <span>Digital Engineering Services</span> tailored to meet the dynamic needs of businesses across the globe. <span>Join us on a transformative journey as we redefine the boundaries of technology.</span></p> */}
                <p>Step into the future of technology. We are an innovation catalyst rather than merely a provider of IT services.  At <span>Alcyone Technologies</span>, we are experts in offering innovative IT support and customized software development services that easily adapt to the ever-changing demands of companies worldwide. Join us on a transformative journey as we redefine the boundaries of technology. </p>
              </div>
            </Col>
        </Row>
        {/* <Row>
            <Col lg={12}>
              <Title mainTitle="We Do IT Consulting and Software Services" subTitle={["Alcyone is a trusted IT consulting company in Indore that" ,<b> provides high-quality, personalized software services to meet the changing demands of businesses around the world.</b>, " We have a team of experts who offers the finest IT consulting services and creates compatible software by following new trends in the software industry."]}/>
            </Col>
        </Row> */}
        <Row>
            <Col lg={12}>
              <FeaServices/>
            </Col>
        </Row>
        {/* <Row>
          <Col>
            
          </Col>
        </Row> */}
    </Container>
    <ExpandedServices/>
    {/* <Container className='pt-5 mt-5'>
      <Row>
        <Col>
          <h2 class="new_title mb-5">Our Other Services</h2>
        </Col>
      </Row>
    </Container>
    <Container fluid className='pb-5 mb-5' >
      <Row>
        <Col>
          <ServiceCarousel/>
        </Col>
      </Row>
    </Container> */}
    
    <HomeMMS/>
    
    <Container className='py-5 mt-5'>
        <Row>
            <Col lg={12}>
              <Title mainTitle="Our Next Level Technologies" subTitle="Our experts offers integrated services and our awesome product help you harness the power of technology. streamline and automate IT. enhance customer experiences, and drive exponential business growth."/>
            </Col>
          <Col lg={12} className='mt-3'>
            <Technologies/>
          </Col>
        </Row>
    </Container>
    {/*<WorkPlace/>
     <ClientReview/> */}
    
    <Container className='py-5'>
        {/* <Row>
            <Col lg={12}>
              <Title mainTitle="Trusted by many brands in the world" subTitle="Since 2014, our company has delivered over 1000+ projects
to more than 150+ clients worldwide."/>
            </Col>
        </Row>
        <Row className='mb-5'>
          <Col>
            <ClientLogo/>
          </Col>
        </Row> */}
        <Row className='pt-5 g-3'>
          <Col>
            <ActionBox title="Reorganizing" subTitle="Existing website or app" btnTxt="Consult Now" icon={redesignIcon} redirect="/rebrand"/>
          </Col>
          <Col>
            <ActionBox title="New !dea" subTitle="Let's connect and discuss" btnTxt="Schedule a Call" icon={ideaIcon} redirect="/contact"/>
          </Col>
        </Row>
    </Container>
    
    </>
  )
}
